import React, { useState } from 'react';
import { Button } from "reactstrap";
import apiCall from '../../../../config/apicalls';
import fetchMethodRequest from '../../../../config/service';
import Loader from '../../../App/Loader';
import CodeEditor from './CodeEditor';
import { Sidebar } from 'primereact/sidebar';
import CodeEditorOptions from './CodeEditorOptions';
import { Checkbox } from 'primereact/checkbox';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import config from '../../../../config/config';

const CodeEditorButton = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [displayEditor, setDisplayEditor] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [displayOptions, setDisplayOptions] = useState(false);
    const [fileType, setFileType] = useState('backend');
    const [fileCode, setFileCode] = useState();
    const [showingOptions, setShowingOptions] = useState('fileTypes');
    const [files, setFiles] = useState([]);
    const [currentSelectedFile, setCurrentSelectedFile] = useState('');
    const [isCodeModified, setIsCodeModified] = useState(false);
    const [codeModifyObj, setCodeModifyObj] = useState({});
    const [useDefaultFile, setUseDefaultFile] = useState({});
    const [sideMenuItems, setSideMenuItems] = useState({});
    const [screenName, setScreenName] = useState('');
    const [initCode, setInitCode] = useState({});

    let frontendFiles = [];
    let backendFiles = [];
    let types = ['frontend', 'backend'];
    let modifiedCode = {};
    let filesToSkip = {
        skipControllerFile: false,
        skipModelFile: false,
        skipRouteFile: false,
        skipServiceFile: false,
        skipSchemaFile: false,
        skipUIFile: false
    };
    let fileNames = {
        controllerFile: "",
        modelFile: "",
        routeFile: "",
        serviceFile: "",
        schemaFile: "",
        UIFile: ""
    }
    let skipFiles = true;

    //on click source code button
    const onClickSourceCode = async () => {
        setScreenName((props.controller && props.controller.name) ? props.controller.name : 'Sample')
        //Checking if files API hit & generated files       
        if (props.controller && props.controller.isBackendGenerated && props.controller.isFrontEndGenerated) {
            //Checking user already access a file
            frontendFiles = (props.controller.frontendFiles ? props.controller.frontendFiles : []);
            backendFiles = (props.controller.backendFiles ? props.controller.backendFiles : []);
            setCurrentSelectedFile(backendFiles[0])
            if (currentSelectedFile && modifiedCode[currentSelectedFile]) {
                setSideMenuItems({ frontend: frontendFiles, backend: backendFiles });
                getPrevAccessedFileCode();
            } else {
                setIsLoading(false);
                setCurrentSelectedFile(backendFiles[0]);
                setFileType('backend');
                setMenuItem(true);
            }
        } else {
            setIsLoading(true);
            types.forEach((type) => {
                setDataForGeneratingFilesApi(type);
            });
        }
    }

    //Minmize maximize code editor container
    const onClickMaxMin = (type) => {
        if (type === 'max') {
            setShowFullScreen(true);
        } else {
            setShowFullScreen(false);
        }
    }

    const onSelectType = async (type) => {
        if (type) {
            setFileType(type);
            setDisplayOptions(false);
            setIsLoading(true);
            if ((type === 'frontend' && !props.contreoller.isFrontEndGenerated) || (type === 'backend' && !props.contreoller.isBackendGenerated)) {
                setDataForGeneratingFilesApi();
            } else {
                setIsLoading(false);
                setShowingOptions('files');
                setDisplayOptions(true);
                setFiles(type === 'frontend' ? frontendFiles : backendFiles)
            }
        }
    }

    const toggleOptions = () => {
        setDisplayOptions(displayOptions ? false : true)
    }

    // Setting data for Generate api
    const setDataForGeneratingFilesApi = (type) => {
        const data = {
            "projectName": props.projectName,
            "displayProjectLogoOrName": props.displayProjectLogoOrName,
            "projectType": props.updateType,
            "logo": props.projectLogo,
            "selectedLoginScreen": props.selectedLoginTemplate,
            "fav": props.projectFav,
            "bg": null,
            "bgColor": props.bgColor,
            "selectedBackground": props.selectedBackground,
            "loginCtrlName": props.loginCtrlName,
            "randomString": props.s_g_project_Id,
            "sourceType": type,
            "service": props.service,
            "controllers": [props.controller],
            "controllerId": (props.controller && props.controller.controllerId) ? props.controller.controllerId : '',
            "jsonObject": {
                "projectName": props.projectName,
                "projectType": props.updateType,
                "logo": props.projectLogo,
                "fav": props.projectFav,
                "selectedLoginScreen": props.selectedLoginTemplate,
                "bg": null,
                "selectedBackground": props.selectedBackground,
                "bgColor": props.bgColor,
                "loginSideImage": props.loginSideImage,
                "displayProjectLogoOrName": props.displayProjectLogoOrName,
                "emailService": props.emailService,
                "isGoogleLoginRequired": props.isGoogleLoginRequired,
                "googleClientID": props.googleClientID,
                "isGoogleRecaptchaRequired": props.isGoogleRecaptchaRequired,
                "googleRecaptchaSiteKey": props.googleRecaptchaSiteKey,
                "api": props.api,
                "controllers": [props.controller],
                "controllerId": (props.controller && props.controller.controllerId) ? props.controller.controllerId : ''
            },
            "type": "new"
        }
        generateFiles(data);
    }

    // Generating files & getting file names from API
    const generateFiles = (data) => {
        fetchMethodRequest('POST', apiCall.crudCreate, data).then(async (res) => {
            if (res && res.respCode && res.respMessage) {
                if (data.sourceType === 'frontend') {
                    frontendFiles = res.files ? res.files : [];
                    await props.setGeneratedFiles('isFrontEndGenerated', 'frontendFiles', res.files ? res.files : []);
                } else if (data.sourceType === 'backend') {
                    backendFiles = res.files ? res.files : [];
                    await props.setGeneratedFiles('isBackendGenerated', 'backendFiles', res.files ? res.files : []);
                }
                setFilesForControllers(res.files ? res.files : []);
                if (props.controller.isBackendGenerated && props.controller.isBackendGenerated) {
                    setIsLoading(false);
                    setCurrentSelectedFile(backendFiles[0]);
                    setMenuItem();
                }
            } else if (res && res.errorMessage) {
                showToasterMessage(res.errorMessage, 'error');
            }
        }).catch((err) => {
            setIsLoading(false);
            return err;
        });
    }

    // Setting side menu 
    const setMenuItem = () => {
        setSideMenuItems({ frontend: frontendFiles, backend: backendFiles });
        setDataForGetAndSaveFileCodeApi(backendFiles[0], 'getFile');
    }

    // On click on file name 
    const onSelectFile = async (fileName, type) => {
        setDisplayOptions(false);
        setFileType(type);
        setCurrentSelectedFile(fileName);
        setIsCodeModified(false);
        if (!modifiedCode[fileName]) {
            setDataForGetAndSaveFileCodeApi(fileName, 'getFile', '', type);
        } else {
            getPrevAccessedFileCode();
        }
    }

    // Setting data for get & save API
    const setDataForGetAndSaveFileCodeApi = (fileName, opType, code, type) => {
        let data = {
            "s_g_project_Id": props.s_g_project_Id,
            "type": type ? type : fileType,
            "screenName": screenName,
            "fileName": fileName,
        }
        let typeOfFile = type ? type : fileType
        if (typeOfFile === 'frontend') {
            data['service'] = props.service ? props.service : 'Admin';
            data['screenName'] = `${screenName.charAt(0).toUpperCase() + screenName.slice(1)}s`;
        } else if (typeOfFile === 'backend') {
            let tArray = fileName.split('.');
            data['serverFiletype'] = tArray && tArray.length > 2 && tArray[1] ? `${tArray[1]}s` : '';
        }
        if (opType === 'getFile') {
            getFileCode(data);
        } else if (opType === 'saveFile') {
            data['file'] = code;
            saveFileCode(data);
        }
    }

    // Getting file code from server
    const getFileCode = (data) => {
        fetchMethodRequest('POST', apiCall.getFile, data).then(async (res) => {
            if (res && res.file) {
                setDisplayEditor(true);
                setFileCode(res.file);
                initCode[data.fileName] = JSON.parse(res.file);
                setInitCode(initCode)
            } else if (res && res.errorMessage) {
                showToasterMessage(res.errorMessage, 'error');
            }
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            return err;
        });
    }

    // On click save button
    const onSaveFile = (code) => {
        const sFile = currentSelectedFile;
        const cCode = (modifiedCode[sFile] ? modifiedCode[sFile] : initCode[sFile]);
        setDataForGetAndSaveFileCodeApi(currentSelectedFile, 'saveFile', (code ? code : cCode));
    }

    // Saving file code in server
    const saveFileCode = (data) => {
        fetchMethodRequest('POST', apiCall.saveFile, data).then(async (res) => {
            if (res && res.success) {
                showToasterMessage("File saved", 'success');
                getFileCode(data);
                if (modifiedCode[currentSelectedFile]) {
                    delete modifiedCode[currentSelectedFile];
                }
                setIsCodeModified(false);
                updateModifyStatus(data.fileName, false);

            } else if (res && res.errorMessage) {
                showToasterMessage(res.errorMessage, 'error');
            }
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            showToasterMessage(config.serverErrMessage, 'error');
            return err;
        });
    }

    // On code change in editor
    const onCodeChange = (code) => {
        let tCode = code;
        let initCodeValue = initCode[currentSelectedFile];
        if (initCodeValue.replaceAll('\r\n', '\n') !== tCode.replaceAll('\r\n', '\n')) {
            setIsCodeModified(true);
            updateModifyStatus(currentSelectedFile, true);
        } else {
            setIsCodeModified(false);
            updateModifyStatus(currentSelectedFile, false);
        }
        modifiedCode[currentSelectedFile] = code;
    }

    // getting prev code file if available
    const getPrevAccessedFileCode = () => {
        setDisplayEditor(true);
        setFileCode(JSON.stringify(modifiedCode[currentSelectedFile]));
        setIsCodeModified((modifiedCode[currentSelectedFile].replaceAll('\r\n', '\n') !== initCode[currentSelectedFile].replaceAll('\r\n', '\n') ? true : false))
    }

    const onClickReset = async () => {
        setDisplayEditor(false);
        modifiedCode[currentSelectedFile] = initCode[currentSelectedFile];
        setFileCode(JSON.stringify(initCode[currentSelectedFile]));
        setIsCodeModified(false);
        setTimeout(() => {
            setDisplayEditor(true);
        }, 10);
        updateModifyStatus(currentSelectedFile, false);
    }

    const updateModifyStatus = (fileName, status) => {
        let cMObj = JSON.parse(JSON.stringify(codeModifyObj));
        cMObj[fileName] = status;
        setCodeModifyObj(cMObj);
    }

    const setFilesForControllers = (files) => {
        files.forEach((file) => {
            assignFileToSkipValues(file, true);
        });
        saveFilesToContrller();
    }

    const assignFileToSkipValues = (file, skip) => {
        let tfileName = file.toLowerCase();
        if (tfileName.indexOf('.jsx') > -1) {
            filesToSkip.skipUIFile = skip;
            fileNames.UIFile = file;
        } else if (tfileName.indexOf('controller.js') > -1) {
            filesToSkip.skipControllerFile = skip;
            fileNames.controllerFile = file;
        } else if (tfileName.indexOf('model.js') > -1) {
            filesToSkip.skipModelFile = skip;
            fileNames.modelFile = file;
        } else if (tfileName.indexOf('route.js') > -1) {
            filesToSkip.skipRouteFile = skip;
            fileNames.routeFile = file;
        } else if (tfileName.indexOf('service.js') > -1) {
            filesToSkip.skipServiceFile = skip;
            fileNames.serviceFile = file;
        } else if (tfileName.indexOf('.json') > -1) {
            filesToSkip.skipSchemaFile = skip;
            fileNames.schemaFile = file;
        }
    }

    const saveFilesToContrller = () => {
        skipFiles = false;
        for (const prop in filesToSkip) {
            if (filesToSkip[prop] === true) {
                skipFiles = true;
            }
        }
        props.setSkipFiles(skipFiles, { ...filesToSkip, ...fileNames });
    }

    const onChangeUseDefaultCode = async (value) => {
        assignFileToSkipValues(currentSelectedFile, (value ? false : true));
        let useDefaultFiles = {};
        useDefaultFiles[currentSelectedFile] = value;
        setUseDefaultFile(useDefaultFiles);
        saveFilesToContrller();
    }


    return (
        <>
            {/* Source code button starts */}
            <Button
                style={{ marginBottom: 0 }}
                size={'sm'}
                color='custom'
                onClick={(e) => onClickSourceCode()}
            >
                Source Code
            </Button>

            {/* Source code button ends */}
            {/* Code Editor starts  */}

            <Sidebar visible={displayEditor} fullScreen={showFullScreen} position="bottom" className="ui-sidebar-lg editor-bar" baseZIndex={1000000} onHide={() => setDisplayEditor(false)}>
                {showFullScreen ?
                    <i className="pi pi-window-minimize mx-2 float-right cursor-pointer" onClick={() => onClickMaxMin('min')}></i> :
                    <i className="pi pi-window-maximize mx-2 float-right cursor-pointer" onClick={() => onClickMaxMin('max')}></i>
                }
                <div style={{ fontWeight: 'normal' }} className='text-capitalize d-flex justify-content-between align-items-center'>
                    <span className='h5'>{currentSelectedFile} &nbsp;{isCodeModified && <span>*</span>}</span>
                    <div className='d-flex'>
                        <div className="field-checkbox mr-4">
                            <Checkbox inputId="binary" checked={useDefaultFile[currentSelectedFile]} onChange={e => onChangeUseDefaultCode(e.checked)} />
                            <label htmlFor="binary" className="ms-1 mb-0">Use Default Code</label>
                        </div>
                        <Button
                            style={{ marginBottom: 0 }}
                            size={'sm'}
                            color='custom'
                            className='mx-2'
                            onClick={() => onClickReset()}
                        >
                            Reset
                        </Button>

                        <Button
                            style={{ marginBottom: 0 }}
                            size={'sm'}
                            color='custom'
                            className='mx-1'
                            onClick={() => onSaveFile()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <br />
                <div className='editor-cotainer '>
                    {displayEditor &&
                        <CodeEditor
                            code={fileCode}
                            sideMenu={sideMenuItems}
                            onSelectFile={onSelectFile}
                            initSelectedFile={currentSelectedFile}
                            onCodeChange={onCodeChange}
                            fileType={fileType}
                            codeModifyObj={codeModifyObj}
                        />
                    }
                </div>
            </Sidebar>

            {/* Code Editor ends  */}
            {/* Showing options to select file types & files starts */}

            <CodeEditorOptions
                displayOptions={displayOptions}
                toggleOptions={toggleOptions}
                onSelectType={onSelectType}
                onSelectFile={onSelectFile}
                showingOptions={showingOptions}
                screenName={(props.controller && props.controller.name) ? props.controller.name : ''}
                fileType={fileType}
                files={files}
            />

            <Loader loader={isLoading} />
        </>
    )
}
export default CodeEditorButton;