
import React, { useEffect, useState, useRef } from "react";
import { forwardRef, useImperativeHandle } from 'react';

import fetchMethodRequest from '../../../config/service';
import apiCall from '../../../config/apicalls';
import config from '../../../config/config';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faBars, faBell, faCog, faEdit, faFileExport, faHamburger, faImage, faLaptopHouse, faList, faMinus, faPencilAlt, faPlus, faSearch, faStepBackward, faStepForward, faTimes, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledCollapse, Button, Row, Col, Collapse, Card, Table, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem, NavLink, TabContent, TabPane, } from "reactstrap";
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons';

import DeleteFieldModal from "../../ConfirmModals/DeleteFieldModal";
import DeleteConfirmMod from "../../ConfirmModals/DeleteConfirmModal";
import DeleteUIModal from "../../ConfirmModals/DeleteUIModal";
import InputModal from '../../ConfirmModals/FieldModal';
import LoginApiModal from '../../ConfirmModals/loginApiUrlModal';
import BackgroundColorModal from '../../ConfirmModals/backgroundColorModal';
import AddModalSample from '../../ConfirmModals/fieldsReorderModal';
import NewModal from "../../ConfirmModals/newModel";
import AddCreateUserModal from '../../ConfirmModals/addCreateUserModal'
import AddNewTabModal from '../../ConfirmModals/addNewTabModal';
import SessionExpiryModal from '../../CommonModals/SessionexpiryModal';
import DeleteNewActionModal from "../../ConfirmModals/DeleteNewActionModal";

import logoutTime from "../../../config/logoutTime";
import Settings from "./settings";
import LoginTemplate from "./LoginTemplate";
import RegisterTemplate from "./RegisterTemplate";
import ForgotPasswordTemplate from "./ForgotPasswordTemplate";
import Menu from "./Menu/Menu";
import CodeEditorButton from "./CodeEditor/CodeEditorButton";


import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';

import classnames from 'classnames';

import { ScrollPanel } from 'primereact/scrollpanel';
import './ScrollPanelDemo.css';

import DefaultScreens from './DefaultCrudTemplates.json';

const NewProject = forwardRef((props, ref) => {

  const SettingsRef = useRef(null);
  const MenuRef = useRef(null);

  const dummyList = ["employee", "Activities", "Email Templates", "Roles", "Upload History", "Email Status"];
  const list = [
    { "label": "employee", "value": "employee" },
    { "label": "Activities", "value": "Activities" },
    { "label": "Email Templates", "value": "Email Templates" },
    { "label": "Roles", "value": "Roles" },
    { "label": "Upload History", "value": "Upload History" },
    { "label": "Email Status", "value": "Email Status" }
  ]

  let oldFieldData = {};
  let newFieldData = {};
  let selectedContIndex;

  const [selectedFrontEnd, setSelectedFrontEnd] = useState('React');
  const [selectedBackEnd, setSelectedBackEnd] = useState('Node');
  const [displayNewActionDeleteModal, setDisplayNewActionDeleteModal] = useState(false);
  const [showSettings, onSetShowSettings] = useState(false);
  const [editModal, onSetEditModal] = useState(false);
  const [controllerIndex, onSetControllerIndex] = useState(0);
  const [displayUIModal, setDisplayUIModal] = useState(false);
  const [multipleListDummy, setMultipleListDummy] = useState(dummyList);
  const [multipleList, setMultipleList] = useState(list);
  const [showFilterElement, setShowFilterElement] = useState(false);
  const [selectdFilterIndex, setSelFilterIndex] = useState(0);
  const [showLoginScreen, setShowHideLoginScreen] = useState(false);
  const [showForgotScreen, setShowHideForgotScreen] = useState(false);
  const [showRegisterScreen, setShowHideRegisterScreen] = useState(false);
  const [displayPreviewModal, setDisplayPreModal] = useState(true);
  const [controllerFields, setControllerFields] = useState([]);
  const [selectedControllerIndex, setSelControllerInd] = useState(0);
  const [displayFieldModal, setDisplayFieldModal] = useState(false);
  const [fieldIndex, setFieldIndex] = useState(0);
  const [itemObj, setItemObj] = useState({});
  const [selectedFormIndex, setSelFormInd] = useState(0);
  const [emailService, setEmailService] = useState(false);
  const [emailDetails, setEmailDetails] = useState(false);
  const [emailDetails1, setEmailDetails1] = useState(false);
  const [bgColor, updateBgColor] = useState('#ffffff');
  const [projectId, onSetProjectID] = useState('');
  const selectedControllerCheckBoxIndex = -1;
  const [showApiUrlDropdown, setShowApiUrlDD] = useState(false);
  const [collapsediv] = useState(false);
  const [isOpenInputModel, setIsOpenInputModel] = useState(false);
  const [isOpenTextAreaModel] = useState(false);
  const [isOpenCheckboxModel] = useState(false);
  const [isOpenRadioModel] = useState(false);
  const [isOpenDropdownModel] = useState(false);
  const [isOpenMultiSelectModel] = useState(false);
  const [isOpenDateModel] = useState(false);
  const [isOpenTimeModel] = useState(false);
  const [isOpenImageModel] = useState(false);
  const [displayProjectLogoOrName, setDisplayProjLogoName] = useState(false);
  const [isGoogleLoginRequired, setIsGoogleLoginReq] = useState(false);
  const [googleClientID, setGoogleClientID] = useState(null);
  const [isGoogleRecaptchaRequired, setIsGoogleCaptchaReq] = useState(false);
  const [googleRecaptchaSiteKey, setGoogleCaptchaKey] = useState(null);
  const [tableFields, setTableFields] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});
  const [api, setApi] = useState(0);
  const [color, updateColor] = useState("rgb(60, 97, 119)");
  const [textcolor, updateTextColor] = useState("rgb(255, 255, 255)");
  const [buttonColor, updateBtnColor] = useState("rgb(60, 97, 119)");
  const [buttonColour, onSetBtnColor] = useState(null);
  const [buttonTextColour, updateBtnTxtColor] = useState('#ffffff');
  const [buttonType, onSetBtnType] = useState('regular');
  const [buttonRounded, onSetBtnRounded] = useState(false);
  const [navColour, updateNavColor] = useState('#ffffff');
  const [navTextColour, updateNavTxtColor] = useState('#3c6177');
  const [paginationPosition, onSetPaginationPos] = useState('top');
  const typeOptions = [
    { value: "center", label: "center" },
    { value: "left", label: "left" },
    { value: "right", label: "right" },
  ];
  const fieldTypeOptions = [
    { value: "text", label: "text" },
    { value: "password", label: "password" },
    { value: "email", label: "email" },
    { value: "textarea", label: "textarea" },
    { value: "dropDown", label: "dropDown" },
    { value: "date", label: "date" },
    { value: "checkbox", label: "checkbox" },
    { value: "autoComplete", label: "autoComplete" },
    { value: "multiSelect", label: "multiSelect" },
    { value: "radio", label: "radio" },
  ];
  const fieldProfileTypeOptions = [
    { value: "text", label: "text" },
    { value: "password", label: "password" },
    { value: "email", label: "email" },
    { value: "textarea", label: "textarea" },
    { value: "date", label: "date" },
    { value: "state", label: "state" },
  ];
  const fieldProfileType = [
    { value: "email", label: "email" },
    { value: "fullName", label: "fullName" },
    { value: "lastName", label: "lastName" },
    { value: "dateOfBirth", label: "dateOfBirth" },
    { value: "address", label: "address" },
  ];
  const actionsType = [
    { value: "add", label: "add" },
    { value: "edit", label: "edit" },
    { value: "delete", label: "delete" },
    { value: "view", label: "view" },
  ];
  const actionsShowOrHide = [
    { value: "add", label: "add" },
    { value: "exportToCsv", label: "export To Csv" },
    { value: "BulkUpload", label: "Bulk Upload" },
  ];
  const [selectedProfileIndex, setSelProfileIndex] = useState(0);
  const [showProfileTypeDropdown, setShowProfileDD] = useState(false);
  const [showControllers, setShowControllers] = useState(false);
  const [apiUrlTypes, setApiUrlTypes] = useState([]);
  const [loginCheck, setLoginCheck] = useState(false);
  const [uploadCheck, setUploadCheck] = useState(true);
  const [jsonDownloadLink] = useState('#');
  const [tabOneActive, setTabOneActive] = useState(false);
  const [tabTwoActive, setTabTwoActive] = useState(false);
  const [tabThreeActive, setTabThreeActive] = useState(false);
  const [isOpenFieldModel] = useState(false);
  const [activeOneTab, setActOneTab] = useState(false);
  const [activeTwoTab, setActTwoTab] = useState(false);
  const [activeThreeTab, setActThreeTab] = useState(false);
  const [setMainTab, onSetMainTab] = useState('one');
  const [registerIndex, setRegisterIndex] = useState(-1);
  const [projectFileValue] = useState('');
  const sampleFileJson = 'http://nocode.api.dosystemsinc.com/json/reactJson';
  const [showSampleFile, setShowSampleFile] = useState(false);
  const [favIconState, setFavIconState] = useState(true);
  const [projectNameValue, setProjectNameValue] = useState();
  const [isOpenlogInUrlModal, setIsOpenLoginUrlModal] = useState(false);
  const [isActionModal, setIsActionModal] = useState(false);
  const [apiValue, setApiValue] = useState(true);
  const [dropdownOpen] = useState(false);
  const [editOpenlogInUrlModal, setEditOpenLoginUrlModal] = useState(false);
  const [isdeleteControllerModal, setDeleteControllerModal] = useState(false);
  const [projectLogo, setProjectLogo] = useState("default_project_logo.png");
  const [projectBg, setProjectBg] = useState(null);

  const [projectFav, setProjectFav] = useState("default_fav_icon.png");
  const [loginSideImage, setLoginSideImage] = useState("default_LoginSideImage.webp");
  const [loginurl, setLoginUrl] = useState("employee");
  const [selectedBackground, setSelectedBg] = useState(null);
  const [controllerId, setControllerID] = useState(1006);
  const controllers_copy = [
    {
      "disableDelete": true,
      "disableEdit": false,
      "controllerId": 1001,
      "displayServices": ["Admin"],
      "displayViewOfForm": "screen",
      "controllerFields": [
        {
          "name": "name",
          "type": "text",
          "placeholder": "Name",
          "label": "Name",
          "header": "Name",
          "width": 110,
          "id": "name",
          "displayinaddForm": true,
          "displayineditForm": true,
          "displayinlist": true,
          "profileField": "True",
          "controllerName": null,
          "fieldType": "Link",
          "textAlign": "Center",
          "displayinregisterForm": true,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "globalSearchField": true,
          "isBulkUploadField": true,
          "show": true,
          "field": "name",
          "addFormOrder": 1,
          "editFormOrder": 1
        },
        {
          "name": "email",
          "type": "email",
          "placeholder": "Email",
          "label": "Email",
          "id": "email",
          "width": 150,
          "displayinaddForm": true,
          "displayineditForm": false,
          "displayinlist": true,
          "profileField": "True",
          "controllerName": null,
          "textAlign": "Center",
          "displayinregisterForm": true,
          "isBulkUploadField": true,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "show": true,
          "globalSearchField": true,
          "required": true,
          "isFieldRequired": true,
          "field": "email",
          "header": "Email",
          "addFormOrder": 2,
          "editFormOrder": 2
        },
        {
          "name": "address",
          "type": "textarea",
          "placeholder": "Address",
          "label": "Address",
          "id": "address",
          "width": 180,
          "displayinaddForm": true,
          "displayineditForm": true,
          "displayinlist": true,
          "profileField": "True",
          "controllerName": null,
          "textAlign": "Center",
          "displayinServer": "True",
          "serverFieldType": "String",
          "show": true,
          "disabled": true,
          "globalSearchField": true,
          "isBulkUploadField": true,
          "field": "address",
          "header": "Address",
          "addFormOrder": 3,
          "editFormOrder": 3
        },
        {
          "name": "role",
          "type": "dropDown",
          "placeholder": "Role",
          "label": "Role",
          "header": "Role",
          "derivedValue": "role=undefined",
          "actions": [],
          "width": 110,
          "actionsNumber": [],
          "id": "role",
          "displayinaddForm": true,
          "displayineditForm": true,
          "displayinlist": true,
          "controllerName": null,
          "options": [],
          "textAlign": "Center",
          "show": true,
          "disabled": true,
          "field": "role",
          "mobile": true,
          "globalSearchField": true,
          "isBulkUploadField": true,
          "isFieldRequired": true,
          "required": true,
          "displayInSettings": true,
          "fieldType": "dropDown",
          "addFormOrder": 4,
          "editFormOrder": 4
        },
        {
          "name": "phone",
          "type": "text",
          "placeholder": "Phone",
          "label": "Phone",
          "id": "phone",
          "width": 110,
          "displayinaddForm": true,
          "displayineditForm": true,
          "displayinlist": true,
          "profileField": "True",
          "controllerName": null,
          "textAlign": "Center",
          "displayinregisterForm": true,
          "isBulkUploadField": true,
          "displayinServer": "True",
          "serverFieldType": "String",
          "show": true,
          "disabled": true,
          "field": "phone",
          "header": "Phone",
          "addFormOrder": 5,
          "editFormOrder": 5
        },
        {
          "name": "reportingTo",
          "type": "relateAutoComplete",
          "placeholder": "ReportingTo",
          "label": "ReportingTo",
          "header": "ReportingTo",
          "derivedValue": "reportingTo=undefined",
          "actions": [],
          "actionsNumber": [],
          "id": "reportingTo",
          "displayinaddForm": true,
          "displayineditForm": true,
          "displayinlist": true,
          "controllerName": "employee",
          "searchField": "name",
          "fieldType": "relateAutoComplete",
          "controllerId": 1001,
          "searchApi": "employees",
          "width": 110,
          "textAlign": "Center",
          "displayinServer": "True",
          "serverFieldType": "String",
          "show": true,
          "disabled": true,
          "field": "reportingTo",
          "addFormOrder": 6,
          "editFormOrder": 6
        },
        {
          "name": "password",
          "type": "password",
          "placeholder": "password",
          "label": "password",
          "id": "password",
          "displayinaddForm": true,
          "displayineditForm": false,
          "displayinlist": false,
          "profileField": false,
          "controllerName": null,
          "textAlign": "Center",
          "displayinServer": false,
          "serverFieldType": "String",
          "show": true,
          "displayInSettings": false,
          "disabled": true,
          "isFieldRequired": true,
          "required": true,
          "field": "password",
          "header": "password",
          "addFormOrder": 7,
          "editFormOrder": 7
        },
        {
          "name": "created",
          "type": "date",
          "placeholder": "Created",
          "label": "Created",
          "width": 90,
          "header": "Created",
          "derivedValue": "created=undefined",
          "actions": [],
          "actionsNumber": [],
          "id": "created",
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "globalSearchField": false,
          "isBulkUploadField": false,
          "controllerId": null,
          "fieldType": "Date",
          "dateFormat": config.fullDateFormat,
          "textAlign": "Center",
          "disabled": true,
          "displayinServer": "True",
          "serverFieldType": "Date",
          "show": true,
          "field": "created",
          "addFormOrder": 10,
          "editFormOrder": 10
        },
        {
          "name": "updated",
          "type": "date",
          "placeholder": "Updated",
          "label": "Updated",
          "width": 90,
          "header": "Updated",
          "derivedValue": "updated=undefined",
          "actions": [],
          "actionsNumber": [],
          "id": "updated",
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "globalSearchField": false,
          "isBulkUploadField": false,
          "controllerId": null,
          "fieldType": "Date",
          "dateFormat": config.fullDateFormat,
          "textAlign": "Center",
          "disabled": true,
          "displayinServer": "True",
          "serverFieldType": "Date",
          "show": true,
          "field": "updated",
          "addFormOrder": 11,
          "editFormOrder": 11
        }
      ],
      "actions": ["add", "edit", "delete", "view", "exportToCsv", "BulkUpload"],
      "methods": ["get", "list", "load", "create", "update", "remove"],
      "isAuth": true,
      "name": "employee",
      "actionsShowOrHideValue": ["add", "exportToCsv", "BulkUpload"]
    },
    {
      "disableDelete": true,
      "disableEdit": true,
      "controllerId": 1002,
      "displayServices": ["Admin"],
      "displayViewOfForm": "screen",
      "controllerFields": [
        {
          "textAlign": "center",
          "show": true,
          "mobile": true,
          "width": 80,
          "field": "created",
          "fieldType": "Date",
          "fieldType": "Date",
          "dateFormat": config.fullDateFormat,
          "header": "Created Date",
          "filter": false,
          "sortable": true,
          "displayInSettings": true,
          "name": "created",
          "type": "date",
          "placeholder": "Created",
          "label": "Created",
          "id": "created",
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "isBulkUploadField": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "Date",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 1,
          "editFormOrder": 1
        },
        {
          "textAlign": "left",
          "width": 80,
          "field": "context",
          "mobile": true,
          "header": "Context",
          "filter": false,
          "sortable": true,
          "show": true,
          "textCapitalize": true,
          "displayInSettings": true,
          "name": "context",
          "type": "text",
          "placeholder": "Context",
          "label": "Context",
          "id": "context",
          "globalSearchField": true,
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 2,
          "editFormOrder": 2
        },
        {
          "textAlign": "left",
          "width": 80,
          "field": "email",
          "mobile": true,
          "header": "Email",
          "filter": false,
          "sortable": true,
          "show": true,
          "displayInSettings": true,
          "name": "email",
          "type": "text",
          "placeholder": "Email",
          "label": "Email",
          "id": "email",
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 3,
          "editFormOrder": 3
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "mobile": true,
          'fieldType': "Array",
          "field": "description",
          "header": "Description",
          "filter": false,
          "sortable": true,
          "textCapitalize": true,
          "displayInSettings": true,
          "name": "description",
          "type": "Array",
          "placeholder": "Description",
          "label": "Description",
          "id": "description",
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 4,
          "editFormOrder": 4
        }],
      "actions": ["view"],
      "methods": ["get", "list", "load", "create", "update", "remove"],
      "name": "Activities",
      "actionsShowOrHideValue": []
    },
    {
      "disableDelete": true,
      "disableEdit": true,
      "controllerId": 1003,
      "displayServices": ["Admin"],
      "displayViewOfForm": "screen",
      "controllerFields": [
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "fieldType": "Link",
          "field": "name",
          "header": "Name",
          "filter": true,
          "sortable": true,
          "displayInSettings": true,
          "required": true,
          "value": '',
          "type": 'text',
          "name": 'name',
          "label": 'Name',
          "id": 'name',
          "placeholder": 'Name',
          "displayinaddForm": true,
          "displayineditForm": true,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "globalSearchField": true,
          "defaultField": true,
          "addFormOrder": 1,
          "editFormOrder": 1
        },

        {
          "show": true,
          "textAlign": 'left',
          "width": 100,
          "field": 'subject',
          "header": 'Subject',
          "filter": true,
          "sortable": false,
          "displayInSettings": true,
          'required': true,
          "value": '',
          "type": 'text',
          "name": 'subject',
          "label": 'Subject',
          "id": 'subject',
          "globalSearchField": true,
          "placeholder": 'Subject',
          "displayinaddForm": true,
          "isBulkUploadField": true,
          "displayineditForm": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 2,
          "editFormOrder": 2
        },
        {
          "show": false,
          "field": "templateText",
          "header": "Email Template",
          "required": true,
          "value": '',
          "type": 'ckeditor',
          "name": 'templateText',
          "label": 'Email Template',
          "id": 'emailTemplate',
          "placeholder": "templateText",
          "displayinaddForm": true,
          "displayineditForm": true,
          "displayinlist": "false",
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 3,
          "editFormOrder": 3
        },
        {
          "name": "created",
          "type": "date",
          "placeholder": "Created",
          "label": "Created",
          "width": 90,
          "header": "Created",
          "derivedValue": "created=undefined",
          "actions": [],
          "actionsNumber": [],
          "id": "created",
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "globalSearchField": false,
          "controllerId": null,
          "fieldType": "Date",
          "dateFormat": config.fullDateFormat,
          "textAlign": "Center",
          "disabled": true,
          "isBulkUploadField": false,
          "displayinServer": "True",
          "serverFieldType": "Date",
          "show": true,
          "field": "created",
          "addFormOrder": 6,
          "editFormOrder": 6
        },
        {
          "name": "updated",
          "type": "date",
          "placeholder": "Updated",
          "label": "Updated",
          "width": 90,
          "header": "Updated",
          "derivedValue": "updated=undefined",
          "actions": [],
          "actionsNumber": [],
          "id": "updated",
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "globalSearchField": false,
          "controllerId": null,
          "fieldType": "Date",
          "dateFormat": config.fullDateFormat,
          "textAlign": "Center",
          "isBulkUploadField": false,
          "disabled": true,
          "displayinServer": "True",
          "serverFieldType": "Date",
          "show": true,
          "field": "updated",
          "addFormOrder": 7,
          "editFormOrder": 7
        }
      ],
      "actions": ["add", "edit", "delete", "view", "exportToCsv",],
      "methods": ["get", "list", "load", "create", "update", "remove"],
      "name": "Email Templates",
      "actionsShowOrHideValue": ["add", "exportToCsv",]
    },
    {
      "disableDelete": true,
      "disableEdit": true,
      "controllerId": 1004,
      "displayServices": ["Admin"],
      "displayViewOfForm": "screen",
      "controllerFields": [
        {
          "show": true,
          "textAlign": "center",
          "mobile": true,
          "width": 150,
          "field": "role",
          "fieldType": "Link",
          "header": "Role",
          'filter': false,
          "sortable": true,
          "displayInSettings": true,
          "value": "",
          "type": "text",
          "name": "role",
          "label": "Role",
          'id': "role",
          "placeholder": "role",
          "required": true,
          "displayinaddForm": true,
          "displayineditForm": true,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 1,
          "editFormOrder": 1
        },

        {
          "show": true,
          "textAlign": "center",
          "width": 100,
          "field": "status",
          "mobile": true,
          "fieldType": "Badge",
          "header": "Status",
          "filter": false,
          "displayInSettings": true,
          "sortable": false,
          "options": [
            { "label": "Active", "value": "Active", "color": "success" },
            { "label": "Inactive", "value": "Inactive", "color": "warning" },
            { "label": "Pending", "value": "Pending", "color": "danger" }
          ],
          "filterElement": [
            { "label": "All", "value": null },
            { "label": "Active", "value": "Active" },
            { "label": "Pending", "value": "Pending" },
            { "label": "Inactive", "value": "Inactive" }
          ],
          "required": true,
          "value": "",
          "type": "dropDown",
          "name": "status",
          "label": "Status",
          "id": "status",
          "placeholder": "Status",
          "displayinaddForm": true,
          "displayineditForm": true,
          "displayinlist": true,
          "controllerName": null,
          "isBulkUploadField": true,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 2,
          "editFormOrder": 2
        },
        {
          "name": "roleType",
          "type": "dropDown",
          "placeholder": "RoleType",
          "width": 90,
          "label": "RoleType",
          "header": "RoleType",
          "derivedValue": "roleType=undefined",
          "actions": [],
          "actionsNumber": [],
          "id": "roleType",
          "displayinaddForm": true,
          "displayineditForm": true,
          "displayinlist": true,
          "fieldType": "dropDown",
          "controllerName": null,
          "options": [
            {
              "label": "Employee",
              "value": "Employee",
              "color": "primary"
            },
            {
              "label": "Manager",
              "value": "Manager",
              "color": "primary"
            },
            {
              "label": "Admin",
              "value": "Admin",
              "color": "primary"
            }
          ],
          "textAlign": "Center",
          "displayinServer": "True",
          "serverFieldType": "String",
          "isBulkUploadField": true,
          "show": true,
          "required": true,
          "disabled": true,
          "field": "roleType",
          "addFormOrder": 3,
          "editFormOrder": 3
        },
        {
          "actions": [],
          "actionsNumber": [],
          "controllerName": null,
          "derivedValue": "levels=undefined",
          "displayinForm": undefined,
          "displayinServer": "True",
          "displayinaddForm": true,
          "displayineditForm": true,
          "width": 50,
          "displayinlist": true,
          "field": "levels",
          "header": "Levels",
          "id": "levels",
          "label": "Levels",
          "name": "levels",
          "globalSearchField": false,
          "placeholder": "Levels",
          "profileField": undefined,
          "serverFieldType": "Number",
          "show": true,
          "stringType": undefined,
          "textAlign": "Center",
          "type": "number",
          "isBulkUploadField": true,
          "disabled": true,
          "required": false,
          "value": undefined,
          "addFormOrder": 4,
          "editFormOrder": 4
        },
        {
          "show": false,
          "field": "permission",
          "header": "permission",
          "value": "",
          "type": "permission",
          "name": "permission",
          "label": "Permission",
          "id": "permission",
          "placeholder": "permission",
          "required": true,
          "displayinaddForm": true,
          "displayineditForm": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "Object",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 5,
          "editFormOrder": 5
        },
        {
          "name": "created",
          "type": "date",
          "placeholder": "Created",
          "label": "Created",
          "width": 90,
          "header": "Created",
          "derivedValue": "created=undefined",
          "actions": [],
          "actionsNumber": [],
          "id": "created",
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "globalSearchField": false,
          "controllerId": null,
          "isBulkUploadField": false,
          "fieldType": "Date",
          "dateFormat": config.fullDateFormat,
          "textAlign": "Center",
          "disabled": true,
          "displayinServer": "True",
          "serverFieldType": "Date",
          "show": true,
          "field": "created",
          "addFormOrder": 8,
          "editFormOrder": 8
        },
        {
          "name": "updated",
          "type": "date",
          "placeholder": "Updated",
          "label": "Updated",
          "width": 90,
          "header": "Updated",
          "derivedValue": "updated=undefined",
          "actions": [],
          "actionsNumber": [],
          "id": "updated",
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "globalSearchField": false,
          "controllerId": null,
          "isBulkUploadField": false,
          "fieldType": "Date",
          "dateFormat": config.fullDateFormat,
          "textAlign": "Center",
          "disabled": true,
          "displayinServer": "True",
          "serverFieldType": "Date",
          "show": true,
          "field": "updated",
          "addFormOrder": 9,
          "editFormOrder": 9
        }

      ],
      "actions": ["add", "edit", "delete", "view", "exportToCsv"],
      "methods": ["get", "list", "load", "create", "update", "remove"],
      "name": "Roles",
      "actionsShowOrHideValue": ["add", "exportToCsv"]
    },
    {
      "disableDelete": true,
      "disableEdit": true,
      "controllerId": 1005,
      "displayServices": ["Admin"],
      "displayViewOfForm": "screen",
      "controllerFields": [
        {
          "show": true,
          "textAlign": "left",
          "width": 180,
          "mobile": true,
          "field": "csvFile",
          "header": "Uploaded File",
          "filter": false,
          "sortable": true,
          "displayInSettings": true,
          "name": "csvFile",
          "type": "text",
          "placeholder": "Uploaded File",
          "label": "Uploaded File",
          "id": "csvFile",
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 1,
          "editFormOrder": 1
        },

        {
          "show": true,
          "textAlign": "center",
          "width": 200,
          "mobile": true,
          "field": "duplicateFileDownloadUrl",
          "header": "Failed File",
          "fieldType": 'Download',
          "style": {
            "color": config.templateColor,
            "cursor": "pointer",
            "textTransform": "capitalize",
          },
          "filter": false,
          "sortable": true,
          "displayInSettings": true,
          "name": "duplicateFileDownloadUrl",
          "type": "text",
          "isBulkUploadField": true,
          "placeholder": "Failed File",
          "label": "Failed File",
          "id": "duplicateFileDownloadUrl",
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 2,
          "editFormOrder": 2
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 160,
          "field": "createdByName",
          "header": "Created By Name",
          "filter": false,
          "sortable": true,
          "displayInSettings": true,
          "name": "createdByName",
          "type": "text",
          "placeholder": "Created By Name",
          "label": "Created By Name",
          "id": "createdByName",
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinregisterForm": false,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 3,
          "editFormOrder": 3
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 140,
          "field": "total",
          "fieldType": 'Number',
          "header": "Total Records",
          "filter": false,
          "sortable": true,
          "displayInSettings": true,
          "name": "total",
          "type": "text",
          "placeholder": "Total Records",
          "label": "Total Records",
          "id": "total",
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "Number",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 4,
          "editFormOrder": 4
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 140,
          "field": "failed",
          "fieldType": 'Number',
          "header": "Failed Records",
          "filter": false,
          "sortable": true,
          "displayInSettings": true,
          "name": "failed",
          "type": "text",
          "placeholder": "Failed Records",
          "label": "Failed Records",
          "id": "failed",
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "Number",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 5,
          "editFormOrder": 5
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 140,
          "field": "success",
          "fieldType": 'Number',
          "header": "Success Records",
          "filter": false,
          "sortable": true,
          "displayInSettings": true,
          "name": "success",
          "type": "text",
          "placeholder": "Success Records",
          "label": "Success Records",
          "id": "success",
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "isBulkUploadField": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "Number",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 6,
          "editFormOrder": 6
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "field": "type",
          "header": "Type",
          "fieldType": 'Capitalize',
          "filter": false,
          "sortable": true,
          "displayInSettings": true,
          "textTransform": true,
          "name": "type",
          "type": "text",
          "placeholder": "Type",
          "label": "Type",
          "id": "type",
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 7,
          "editFormOrder": 7
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 120,
          "field": "status",
          "mobile": true,
          "header": "Status",
          "fieldType": "Badge",
          "style": {
            "padding": "4px 6px",
            "fontSize": 12,
            "color": "white",
            "textTransform": "capitalize",
            "fontWeight": "bold",
          },
          "options": [],
          "filter": false,
          "sortable": false,
          "name": "status",
          "type": "text",
          "placeholder": "Status",
          "label": "Status",
          "id": "status",
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 8,
          "editFormOrder": 8
        },

        {
          "show": true,
          "textAlign": "left",
          "width": 180,
          "field": "created",
          "header": "Created",
          "fieldType": 'Date',
          "filter": false,
          "sortable": true,
          "displayInSettings": true,
          "sortable": false,
          "name": "created",
          "type": "Date",
          "placeholder": "Created",
          "label": "Created",
          "id": "created",
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": false,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "Date",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 9,
          "editFormOrder": 9
        },
      ],
      "actions": [
        "view",
      ],
      "methods": [
        "get",
        "list",
        "load",
        "create",
        "update",
        "remove"
      ],
      "name": "Upload History",
      "actionsShowOrHideValue": [
      ]
    },
    {
      "disableDelete": true,
      "disableEdit": true,
      "controllerId": 1006,
      "displayServices": ["Admin"],
      "displayViewOfForm": "screen",
      "controllerFields": [
        {
          "textAlign": "left",
          "width": 80,
          "field": "to",
          "mobile": true,
          "header": "TO",
          "filter": false,
          "sortable": true,
          "show": true,
          "textCapitalize": true,
          "displayInSettings": true,
          "name": "to",
          "type": "text",
          "placeholder": "TO",
          "label": "TO",
          "id": "to",
          "globalSearchField": true,
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 1,
          "editFormOrder": 1
        },
        {
          "textAlign": "left",
          "width": 80,
          "field": "from",
          "mobile": true,
          "header": "From",
          "filter": false,
          "sortable": true,
          "show": true,
          "textCapitalize": true,
          "displayInSettings": true,
          "name": "from",
          "type": "text",
          "placeholder": "From",
          "label": "From",
          "id": "from",
          "globalSearchField": true,
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 2,
          "editFormOrder": 2
        },
        {
          "textAlign": "left",
          "width": 80,
          "field": "bcc",
          "mobile": true,
          "header": "Bcc",
          "filter": false,
          "sortable": true,
          "show": true,
          "textCapitalize": true,
          "displayInSettings": true,
          "name": "bcc",
          "type": "text",
          "placeholder": "Bcc",
          "label": "Bcc",
          "id": "bcc",
          "globalSearchField": true,
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 3,
          "editFormOrder": 3
        },
        {
          "textAlign": "left",
          "width": 80,
          "field": "subject",
          "mobile": true,
          "header": "Subject",
          "filter": false,
          "sortable": true,
          "show": true,
          "textCapitalize": true,
          "displayInSettings": true,
          "name": "subject",
          "type": "text",
          "placeholder": "Subject",
          "label": "Subject",
          "id": "subject",
          "globalSearchField": true,
          "displayinaddForm": false,
          "displayineditForm": false,
          "isBulkUploadField": true,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 4,
          "editFormOrder": 4
        },
        {
          "textAlign": "left",
          "width": 80,
          "field": "html",
          "mobile": true,
          "header": "Html",
          "filter": false,
          "sortable": true,
          "show": true,
          "textCapitalize": true,
          "displayInSettings": true,
          "name": "html",
          "type": "text",
          "placeholder": "Html",
          "label": "Html",
          "id": "html",
          "globalSearchField": true,
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "controllerName": null,
          "isBulkUploadField": true,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 5,
          "editFormOrder": 5
        },
        {
          "textAlign": "left",
          "width": 80,
          "field": "templateName",
          "mobile": true,
          "header": "Template Name",
          "filter": false,
          "sortable": true,
          "show": true,
          "textCapitalize": true,
          "displayInSettings": true,
          "name": "templateName",
          "type": "text",
          "placeholder": "Template Name",
          "label": "Template Name",
          "id": "templateName",
          "globalSearchField": true,
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "isBulkUploadField": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 6,
          "editFormOrder": 6
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 120,
          "field": "status",
          "mobile": true,
          "header": "Status",
          "fieldType": "Badge",
          "style": {
            "padding": "4px 6px",
            "fontSize": 12,
            "color": "white",
            "textTransform": "capitalize",
            "fontWeight": "bold",
          },
          "options": [
            {
              "label": "Sent",
              "value": "Sent",
              "color": "success",
            },
            {
              "label": "Pending",
              "value": "Pending",
              "color": "danger",
            },
            {
              "label": "Failed",
              "value": "Failed",
              "color": "danger",
            },
          ],
          "filter": false,
          "sortable": false,
          "name": "status",
          "type": "text",
          "placeholder": "Status",
          "label": "Status",
          "id": "status",
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "controllerName": null,
          "displayinServer": true,
          "serverFieldType": "String",
          "isBulkUploadField": true,
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 7,
          "editFormOrder": 7
        },
        {
          "textAlign": "left",
          "width": 80,
          "field": "reason",
          "mobile": true,
          "header": "Reason",
          "filter": false,
          "sortable": true,
          "show": true,
          "textCapitalize": true,
          "displayInSettings": true,
          "name": "reason",
          "type": "text",
          "placeholder": "Reason",
          "label": "Reason",
          "id": "reason",
          "globalSearchField": true,
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "isBulkUploadField": true,
          "controllerName": null,
          "displayinServer": "True",
          "serverFieldType": "String",
          "disabled": true,
          "defaultField": true,
          "addFormOrder": 8,
          "editFormOrder": 8
        },
        {
          "name": "created",
          "type": "date",
          "placeholder": "Created",
          "label": "Created",
          "width": 90,
          "header": "Created",
          "derivedValue": "created=undefined",
          "actions": [],
          "actionsNumber": [],
          "id": "created",
          "displayinaddForm": false,
          "displayineditForm": false,
          "displayinlist": true,
          "globalSearchField": false,
          "isBulkUploadField": false,
          "controllerId": null,
          "fieldType": "Date",
          "dateFormat": config.fullDateFormat,
          "textAlign": "Center",
          "disabled": true,
          "displayinServer": "True",
          "serverFieldType": "Date",
          "defaultField": true,
          "show": true,
          "field": "created",
          "addFormOrder": 9,
          "editFormOrder": 9
        },
      ],
      "actions": ["view"],
      "methods": ["get", "list", "load", "create", "update", "remove"],
      "name": "Email Status",
      "actionsShowOrHideValue": []
    },
  ];
  const [controllers, updateControllers] = useState(controllers_copy);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [sessionExpiryModal, setSessionExpiryModal] = useState(false);
  const [isBackgroundColorModal, setIsBackgroundModal] = useState(false);
  const dummyId = [1001, 1002, 1003, 1004, 1005, 1006];
  const [doNotListInDuplicateScreenDropDown, onSetDuplicateScreenDD] = useState(dummyId);
  const doNotListInFieldsDropDown = ["created", "updated", "updatedBy", "createdBy", "password"];
  const googleLogin = false;
  const googleCaptcha = false;
  const [selectedLoginTemplate, setSelLoginTemplate] = useState('1');
  const [multiTabsData, setMultiTabData] = useState([{ "name": "Admin", "tabId": "UI001" }]);
  const newUIDetails_copy = {
    "Admin": {
      "newUIName": "Admin",
      "selectedScreens": [
        "employee",
        "Activities",
        "Email Templates",
        "Roles",
        "Upload History",
        "Email Status"
      ],
      "selectedAuthScreen": 1001,
      "userDetails": {},
      "settings": {
        "logo": "default_project_logo.png",
        "fav": "default_fav_icon.png",
        "selectedBackground": null,
        "projectBg": null,
        "projectFile": "default_project_logo.png",
        "favicon": "default_fav_icon.png",
        "bg": null,
        "color": "rgb(60, 97, 119)",
        "textcolor": "rgb(255, 255, 255)",
        "bgColor": "#ffffff",
        "displayProjectLogoOrName": false,
        "isGoogleLoginRequired": false,
        "googleClientID": null,
        "isGoogleRecaptchaRequired": false,
        "googleRecaptchaSiteKey": null,
        "loginSideImage": "default_LoginSideImage.webp",
        "selectedLoginTemplate": '1',
        "navColour": '#ffffff',
        "navTextColour": '#3c6177',
        "paginationPosition": 'top',
        "fontType": 'inherit',
        "buttonType": 'regular',
        "buttonRounded": false,
        "buttonColour": '#3c6177',
        "buttonTextColour": '#ffffff',
        "selectedFrontEnd": 'React',
        "selectedBackEnd": 'Node',
      },
      "menuList": [
        {
          "name": "Employee",
          "controllerId": 1001,
          "id": 1,
          "displayTitle": "Employee",
          "disableDelete": true,
          "route": "/employees",
          "iconType": "letters",
          "iconLetters": "em",
          "iconName": null
        },
        {
          "name": "Activities",
          "controllerId": 1002,
          "id": 2,
          "displayTitle": "Activities",
          "disableDelete": true,
          "route": "/activities",
          "iconType": "letters",
          "iconLetters": "Ac",
          "iconName": null
        },
        {
          "name": "Email Templates",
          "controllerId": 1003,
          "id": 3,
          "displayTitle": "Email Templates",
          "disableDelete": true,
          "route": "/templates",
          "iconType": "letters",
          "iconLetters": "Em",
          "iconName": null
        },
        {
          "name": "Roles",
          "controllerId": 1004,
          "id": 4,
          "displayTitle": "Roles",
          "disableDelete": true,
          "route": "/roles",
          "iconType": "letters",
          "iconLetters": "Ro",
          "iconName": null
        },
        {
          "name": "Upload History",
          "controllerId": 1005,
          "id": 5,
          "displayTitle": "Upload History",
          "disableDelete": true,
          "route": "/uploads",
          "iconType": "letters",
          "iconLetters": "Up",
          "iconName": null
        },
        {
          "name": "Email Status",
          "controllerId": 1006,
          "id": 6,
          "displayTitle": "Email Status",
          "disableDelete": true,
          "route": "/emailStatus",
          "iconType": "letters",
          "iconLetters": "Em",
          "iconName": null
        }
      ],
      "menuListObj": {
        "1": {
          "name": "Employee",
          "controllerId": 1001,
          "id": 1,
          "displayTitle": "Employee",
          "disableDelete": true,
          "route": "/employees",
          "iconType": "letters",
          "iconLetters": "em",
          "iconName": null
        },
        "2": {
          "name": "Activities",
          "controllerId": 1002,
          "id": 2,
          "displayTitle": "Activities",
          "disableDelete": true,
          "route": "/activities",
          "iconType": "letters",
          "iconLetters": "Ac",
          "iconName": null
        },
        "3": {
          "name": "Email Templates",
          "controllerId": 1003,
          "id": 3,
          "displayTitle": "Email Templates",
          "disableDelete": true,
          "route": "/templates",
          "iconType": "letters",
          "iconLetters": "Em",
          "iconName": null
        },
        "4": {
          "name": "Roles",
          "controllerId": 1004,
          "id": 4,
          "displayTitle": "Roles",
          "disableDelete": true,
          "route": "/roles",
          "iconType": "letters",
          "iconLetters": "Ro",
          "iconName": null
        },
        "5": {
          "name": "Upload History",
          "controllerId": 1005,
          "id": 5,
          "displayTitle": "Upload History",
          "disableDelete": true,
          "route": "/uploads",
          "iconType": "letters",
          "iconLetters": "Up",
          "iconName": null
        },
        "6": {
          "name": "Email Status",
          "controllerId": 1006,
          "id": 6,
          "displayTitle": "Email Status",
          "disableDelete": true,
          "route": "/emailStatus",
          "iconType": "letters",
          "iconLetters": "Em",
          "iconName": null
        }
      }
    }
  };
  const [newUIDetails, setNewUIDet] = useState(newUIDetails_copy);
  const [activeTab, onSetActiveTab] = useState('Admin');
  const [openAddNewTabModal, setOpenAddNewTabModal] = useState(false);
  const [openEditNewTabModal, setOpenEditNewTabModal] = useState(false);
  const [UITabIndex, setUITabIndex] = useState(0);
  const [loginApi, setLoginAPI] = useState({});
  const [services, onSetServices] = useState([]);
  const defaultMenuList = [
    {
      "name": "Activities",
      "controllerId": 1002,
      "id": 2,
      "displayTitle": "Activities",
      "disableDelete": true,
      "route": "/activities",
      "iconType": "letters",
      "iconLetters": "Ac",
      "iconName": null
    },
    {
      "name": "Email Templates",
      "controllerId": 1003,
      "id": 3,
      "displayTitle": "Email Templates",
      "disableDelete": true,
      "route": "/templates",
      "iconType": "letters",
      "iconLetters": "Em",
      "iconName": null
    },
    {
      "name": "Roles",
      "controllerId": 1004,
      "id": 4,
      "displayTitle": "Roles",
      "disableDelete": true,
      "route": "/roles",
      "iconType": "letters",
      "iconLetters": "Ro",
      "iconName": null
    },
    {
      "name": "Upload History",
      "controllerId": 1005,
      "id": 5,
      "displayTitle": "Upload History",
      "disableDelete": true,
      "route": "/uploads",
      "iconType": "letters",
      "iconLetters": "Up",
      "iconName": null
    },
    {
      "name": "Email Status",
      "controllerId": 1006,
      "id": 6,
      "displayTitle": "Email Status",
      "disableDelete": true,
      "route": "/emailStatus",
      "iconType": "letters",
      "iconLetters": "Em",
      "iconName": null
    }
  ];
  const [s_g_project_Id, setProjectID] = useState(null);

  const [menuList, updateMenuList] = useState([]);
  const [updateType, setUpdateType] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [projectFile, onSetProjectFile] = useState(null);
  const [backgroungImage, onSetBgImage] = useState(null);
  const [favicon, onSetFavIcon] = useState(null);

  const [deleteindex, setDeleteIndex] = useState(null);
  const [deleteControllerName, setDelControllerName] = useState(null);
  const [type, setTypeFromFields] = useState(null);
  const [isOpenAddByModal, setisOpenAddByModal] = useState(false);
  const [rowData, setRowDataFromField] = useState(null);
  const [index, setIndexFromField] = useState(null);
  const [body, onSetBody] = useState(null);
  const [apiSendindex, updateApiSendIndex] = useState(null);
  const [editRecord, setEditRecord] = useState(null);
  const [projectNameErrorMessage, setProjNameErrorMsg] = useState(null);
  const [showProjectNameErrorMessage, setShowProjNameErrorMsg] = useState(false);
  const [emailServiceType, setEmailServiceType] = useState(null);
  const [sendgridApiKey, setSendGridApiKey] = useState(null);
  const [sendgridEmail, setSendGridEmail] = useState(null);
  const [nodeMailerEmail, setNodeMailerEmail] = useState(null);
  const [smtphost, setSMTPhost] = useState(null);
  const [smtpuser, setSMTPuser] = useState(null);
  const [smtppassword, setSMTPpassword] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let showSampleRowsCount = 3;
  let fieldSampleData = {
    phone: '99999999999',
    email: 'name@emailclient.com',
    password: '*********',
    role: 'User',
    roletype: 'User',
    reportingto: 'Manager',
    name: 'John Baker',
    firstname: 'John',
    lastname: 'Baker',
    createdby: 'Admin',
    updatedby: 'Admin',
    address: '1-2-3, Kukatpalli, TG, India.',
    description: 'Sample description',
    status: 'Active',
    image: 'http://api.dev.AIcodeless.com/image/default_project_logo.png',
    time: '10:34',
    'DD-MM-YYYY': '31-12-2021',
    'MM-DD-YYYY': '12-31-2021',
    'YYYY-MM-DD': '2021-12-31',
    'YYYY-MM-DD HH:mm:ss': '2021-12-31 23:59:59',
    'MM/DD/YYYY HH:mm': '12-31-2021 23:59'
  }

  const [updateInitVal, onSetUpdateInitVal] = useState(false);
  const [projNameInit, onSetProjNameInit] = useState(null);

  useImperativeHandle(ref, () => ({
    async uploadExits(logo, type) {
      let _newUIDetails = newUIDetails;
      if (logo && type === 'logo') {
        setProjectLogo(logo);
        setUploadCheck(true);
        _newUIDetails[activeTab]["settings"]["logo"] = logo;
      } else if (logo && type === 'bg') {
        setProjectBg(logo);
        setUploadCheck(true);
        _newUIDetails[activeTab]["settings"]["bg"] = logo
      } else if (logo && type === 'loginSideImage') {
        setLoginSideImage(logo);
        setUploadCheck(true);
        _newUIDetails[activeTab]["settings"]["loginSideImage"] = logo;
      }

      showSettings ? SettingsRef.current.setprojectlogos(logo, type) : null;
      await setNewUIDet(_newUIDetails);
    },
    returnSelectedControllers() {
      return multipleListDummy;
    },
    returnMailServiceType() {
      return emailServiceType;
    },
    returnsendgridApiKey() {
      return sendgridApiKey;
    },
    returnnodeMailerEmail() {
      return nodeMailerEmail;
    },
    returnsmtphost() {
      return smtphost;
    },
    returnsmtpuser() {
      return smtpuser;
    },
    returnsmtppassword() {
      return smtppassword;
    },
    returnEmailService() {
      return emailService;
    },
    returnisGoogleLoginRequired() {
      return {
        isGoogleLoginRequired: isGoogleLoginRequired, googleClientID: googleClientID,
        isGoogleRecaptchaRequired: isGoogleRecaptchaRequired, googleRecaptchaSiteKey: googleRecaptchaSiteKey,
      }
    },
    returnsendgridEmail() {
      return sendgridEmail;
    },
    returnProjectType() {
      return editModal ? 'existing' : 'new';
    },
    returnGetSelectedTemplate() {
      return selectedLoginTemplate;
    },
    returns_g_project_Id() {
      return s_g_project_Id;
    },
    returnMultiUiData() {
      return {
        multiTabsData: multiTabsData,
        newUIDetails: newUIDetails,
        services: services,
        loginApi: loginApi,
      }
    },
    returnServicesAndLoginApi() {
      let _controllers = JSON.parse(JSON.stringify(controllers));
      let _multiTabsData = multiTabsData;
      let _newUIDetails = newUIDetails;
      let services = [];
      let loginApi = {}
      //for multiUI inserting display services,services,login api and userdetails of New UI
      if (_newUIDetails && _multiTabsData && _multiTabsData.length > 0) {
        for (let o of _multiTabsData) {
          if (o.name && _newUIDetails[o.name]) {
            services.push(o.name)
            let data = _newUIDetails[o.name]
            if (_controllers && _controllers.length > 0) {
              for (let i in _controllers) {
                if (_controllers[i].controllerId && data.selectedAuthScreen && _controllers[i].controllerId == data.selectedAuthScreen) {
                  loginApi[o.name] = _controllers[i].name
                }
              }
            }
          }
        }
      }

      return {
        services: services,
        loginApi: loginApi,
        controllerId: controllerId,
      };
    },
    returnApi() {
      return api;
    },
    returnColor() {
      return color;
    },
    returnTextColor() {
      return textcolor;
    },
    returnAllControllers() {
      return multipleList;
    },
    async showErrorForApiUrl() {
      setShowApiUrlDD(!showApiUrlDropdown);
    },
    async changeLabel(e) {
      if (e == "projectFile") {
        onSetProjectFile("Upload Project Logo");
      } else if (e == "projectFav") {
        onSetFavIcon("Upload Favicon");
      } else if (e == "projectBg") {
        onSetBgImage("Upload Background Image");
      } else if (e == "loginSideImage") {
        setLoginSideImage("Upload Login Side Image");
      }
    },
    async favIconSubmitted(fav) {
      if (fav) {
        setFavIconState(true);
        setProjectFav(fav);
      }
      if (props && props.yesJsonUploaded) {
        setShowControllers(true);
      }
      showSettings && fav ? SettingsRef.current.setprojectlogos(fav, "fav") : null;
      await handleProgressBarWidth();
    },
    //toggleUploadSample
    async toggleUploadSample() {
      setShowSampleFile(!showSampleFile);
    },
    returnDisplayProjectLogoOrName() {
      return displayProjectLogoOrName;
    },
    returnDoNotListInDuplicateScreenDropDown() {
      return doNotListInDuplicateScreenDropDown;
    },
    returnControllerArray() {
      let controllers_ref = JSON.parse(JSON.stringify(controllers));
      let multiTabsData_ref = multiTabsData;
      let newUIDetails_ref = newUIDetails;
      if (controllers_ref && controllers_ref.length > 0) {
        for (let i in controllers_ref) {
          controllers_ref[i]["displayServices"] = ["Admin"];
          if (controllers_ref[i].controllerFields && controllers_ref[i].controllerFields.length > 0) {
            let showOrHideFields = [];
            for (let ob of controllers_ref[i].controllerFields) {
              if (ob.showOrHideFields && ob.showOrHideFields.length > 0) {
                showOrHideFields = [...showOrHideFields, ...ob.showOrHideFields];
              }
            }
            //removing the Duplicate Names
            controllers_ref[i].hideFields = [...new Set(showOrHideFields)];
          }
        }
      }
      let services = [];
      let loginApi = {};
      //for multiUI inserting display services,services,login api and userdetails of New UI
      if (newUIDetails_ref && multiTabsData_ref && multiTabsData_ref.length > 0) {
        for (let o of multiTabsData_ref) {
          if (o.name && newUIDetails_ref[o.name]) {
            services.push(o.name);
            let data = newUIDetails_ref[o.name];
            if (controllers_ref && controllers_ref.length > 0) {
              for (let i in controllers_ref) {
                if (controllers_ref[i].controllerId && data.selectedAuthScreen && controllers_ref[i].controllerId == data.selectedAuthScreen) {
                  controllers_ref[i]["isAuth"] = true;
                  controllers_ref[i]["userDetails"] = data.userDetails;
                  loginApi[o.name] = controllers_ref[i].name;
                  if (o.name != "Admin") {
                    let roleIndex = controllers_ref[i].controllerFields.findIndex(val => val.name === "role" && val.type === "dropDown");
                    if (roleIndex == -1) {
                      controllers_ref[i].controllerFields.push({
                        "name": "role",
                        "type": "dropDown",
                        "placeholder": "Role",
                        "label": "Role",
                        "header": "Role",
                        "derivedValue": "role=undefined",
                        "actions": [],
                        "width": 110,
                        "actionsNumber": [],
                        "id": "role",
                        "displayinaddForm": "true",
                        "displayineditForm": "true",
                        "displayinlist": "true",
                        "controllerName": null,
                        "options": [],
                        "textAlign": "Center",
                        "show": true,
                        "disabled": true,
                        "field": "role",
                        "mobile": true,
                        "globalSearchField": 'true',
                        "required": true,
                        "displayInSettings": true,
                        "fieldType": "dropDown",
                        // "style": {
                        //   "color": "#0e4768",
                        //   "cursor": "pointer",
                        //   "textTransform": "capitalize"
                        // }
                      })
                    }
                  }
                }
                if (controllers_ref[i].name && data.selectedScreens && data.selectedScreens.length > 0 && o.name != "Admin") {
                  const index = data.selectedScreens.findIndex(val => val === controllers_ref[i].name);
                  if (index != -1) {
                    controllers_ref[i].displayServices.push(o.name);
                  }
                }
              }
            }
          }
        }
      }

      // let asyncVal = new Promise((resolve, reject)=> {
      //   resolve();
      // });
      // asyncVal.then((response)=> {
      onSetServices(services);
      setLoginAPI(loginApi);
      return controllers_ref;
      // });
    },
    returnBgColor() {
      return bgColor;
    },
    setProjId(id) {
      onSetProjectID(id);
    },
    changeEditModal(str) {
      onSetEditModal(str);
    },
    setType(value) {
      if (value == "getdata") {
        return updateType;
      } else {
        setUpdateType(value);
      }
    },
    async submit(type, clicked) {
      let body = {
        color: color,
        textcolor: textcolor,
        projectName: projectNameValue,
        emailServiceType: emailServiceType,
        sendgridApiKey: sendgridApiKey,
        sendgridEmail: sendgridEmail,
        nodeMailerEmail: nodeMailerEmail,
        smtphost: smtphost,
        smtpuser: smtpuser,
        bgColor: bgColor,
        smtppassword: smtppassword,
        displayProjectLogoOrName: displayProjectLogoOrName,
        doNotListInDuplicateScreenDropDown: doNotListInDuplicateScreenDropDown,
        type: editModal ? 'existing' : 'new'
      }
      if (type !== 'update') {
        body.api = api;
        body.loginApi = loginApi;
      } else if (clicked == "clicked") {
        body.loginApi = loginApi;
      }
      onSetBody(body);
      props.submit(body);
    },
    setEditProjectdata

  }))


  useEffect(() => {
    props.getchildData(activeTab);
  }, [activeTab]);

  useEffect(() => {
    logoutTime.logoutTime();
    console.log(props);
    // loading menu list
    onSetUpdateInitVal(true);
    if (props && !props.editProject) {
      // props.setLoadingStatus(true);
      loadDataInHook();
      // props.setLoadingStatus(fase);
    }
  }, []);

  const loadDataInHook = async () => {
    setUpdateType('new');
    setUploadCheck(true);
    await projectInfo();
    await favIconCallback();
    await getRandomIdForProject();
  }

  const favIconCallback = async () => {
    if (props && props.yesJsonUploaded) {
      setShowControllers(true);
    }
    showSettings && fav ? SettingsRef.setprojectlogos(fav, "fav") : null;
    await handleProgressBarWidth();
  }

  useEffect(() => {
    renderSideMenuBar()
  }, [newUIDetails, controllers])


  useEffect(() => {
    updateMenuList(controllers);
  }, [updateInitVal]);

  useEffect(() => {
    setProjectNameValue(projNameInit);
    setShowControllers(true);
  }, [projNameInit]);

  useEffect(() => {
    let _newUIDetails = newUIDetails;
    _newUIDetails[activeTab]["settings"]["fav"] = projectFav;
    setNewUIDet(_newUIDetails);
  },
    [favIconState, projectFav]
  );

  // Project Info Api call
  const projectInfo = async (type, method = 'GET', Url, payload = {}) => {
    let apiUrl;
    if (type === "rename") {
      apiUrl = Url;
    } else {
      let filterCriteria = { "limit": 20, "page": 1, "criteria": [], "sortfield": "created", "direction": "desc", "globalSearch": { "value": "demoproject", "type": "user" } }
      apiUrl = `${apiCall.Project}?filter=${JSON.stringify(filterCriteria)}`;
    }
    fetchMethodRequest(method, apiUrl, payload).then(async (response) => {
      let sessionexpired = localStorage.getItem('sessionexpired');
      if (sessionexpired === "true") {
        setSessionExpiryModal(true);
      }
      if (response) {
        let project_name = '';
        if (type !== "rename") {
          if (response.projects && response.projects.length > 0) {
            let finalarr = "demoproject" + response.projects.length++;
            project_name = finalarr.charAt(0).toUpperCase() + finalarr.slice(1);
          } else {
            project_name = 'Demoproject';
          }
          await handleExistingProject(project_name);
        }
      }
    }).catch((err) => {
      props.setLoadingStatus(false);
      setLoading(false);
      return err;
    });

  }

  // handleyesExistingProject
  const handleExistingProject = (e, type) => {
    if (e) {
      let name;
      if (editRecord) {
        name = e;
      } else {
        if (e && e.target && e.target.value) {
          name = e.target.value;
        } else {
          name = e;
        }
      }
      onSetProjNameInit(name);

      props.handleyesExistingProject(true, name);

    }
    else {
      if (type && type == "check") {
        projectInfo();
      }
      //KEEP-MIGHT REQUIRE LATER
      // else {
      //   setProjectNameValue("");
      //   setProjNameErrorMsg(`Field Name field must contain alphabets & numbers.`);
      //   setShowProjNameErrorMsg(true);
      // }
    }
  }


  useEffect(() => {
    setTabTwoActive(true);
    setTabOneActive(false);
    setActTwoTab(true);
    onSetMainTab('two');
  }, [loginCheck]);

  // Progress bar width
  const handleProgressBarWidth = async () => {
    setLoginCheck(true);
    if (props && props.yesJsonUploaded) {
      await props.changeprogressWidth(80)
    } else {
      await props.changeprogressWidth(60)
    }
  }

  const handleTabs = async (type) => {
    if (type === 'one') {
      onSetMainTab('one');
      setTabOneActive(true);
      setTabTwoActive(false);
      setTabThreeActive(false);
      // props.changeprogressWidth(60);
    } else if (type === 'two') {
      onSetMainTab('two');
      setTabOneActive(false);
      setTabTwoActive(true);
      setTabThreeActive(false);
      // props.changeprogressWidth(80);
    } else if (type === 'three') {
      onSetMainTab('three');
      setTabOneActive(false);
      setTabTwoActive(false);
      setTabThreeActive(true);
      props.changeprogressWidth(100);
    }
  }


  const resetEditModal = () => {
    onSetEditModal(false);
  }


  const getRandomIdForProject = () => {
    fetchMethodRequest('GET', apiCall.randomString)
      .then(async (response) => {
        if (response && response.randomString) {
          const { randomString } = response;
          setProjectID(randomString);
        }
      }).catch((err) => {
        setLoading(false);
        return err;
      });
  }



  //emptyController
  const emptyController = async () => {
    updateControllers([]);
    setControllerFields([]);
    setShowControllers(false);
    setLoginCheck(false);
    setSelFilterIndex(0);
    setSelProfileIndex(0);
    setShowFilterElement(false);
    setSelControllerInd(0);
    setSelFormInd(0);
    setUploadCheck(false);
    setFavIconState(false);
  }

  // returnController



  //  Settings Data
  const setShowSettings = async (isOpen) => {
    let _newUIDetails = newUIDetails;
    onSetShowSettings(true);
    let favIcon;
    let bgImage;
    let logo;
    let defaultIcon;
    if (_newUIDetails[activeTab]["settings"]["fav"] === "default_fav_icon.png") {
      favIcon = "default_fav_icon.png";
    } else if (_newUIDetails[activeTab]["settings"]["fav"]) {
      let favicon_new = _newUIDetails[activeTab]["settings"]["fav"];
      let favSplit = favicon_new.split("_");
      favSplit.splice(0, 1);
      defaultIcon = favSplit?.join("_");
    }

    if (_newUIDetails[activeTab]["settings"]["bg"]) {
      let image = _newUIDetails[activeTab]["settings"]["bg"];
      let imageSplit = image.split("_");
      imageSplit.splice(0, 1);
      bgImage = imageSplit.join("_");
    }

    if (_newUIDetails[activeTab]["settings"]["logo"] === "default_project_logo.png") {
      logo = "default_project_logo.png";
    } else if (_newUIDetails[activeTab]["settings"]["logo"]) {
      let logo_new = _newUIDetails[activeTab]["settings"]["logo"];
      let logo_newSplit = logo_new.split("_");
      logo_newSplit.splice(0, 1);
      logo = logo_newSplit.join("_");
    }
    if (isOpen && SettingsRef) {
      setTimeout(() => {
        SettingsRef.current.setDetails({
          projectLogo: _newUIDetails[activeTab]["settings"]["logo"],
          projectFav: _newUIDetails[activeTab]["settings"]["fav"],
          selectedBackground: _newUIDetails[activeTab]["settings"]["selectedBackground"],
          projectBg: _newUIDetails[activeTab]["settings"]["bg"],
          navColour: _newUIDetails[activeTab]["settings"]["navColour"],
          navTextColour: _newUIDetails[activeTab]["settings"]["navTextColour"],
          fontType: _newUIDetails[activeTab]["settings"]["fontType"],
          paginationPosition: _newUIDetails[activeTab]["settings"]["paginationPosition"],
          buttonType: _newUIDetails[activeTab]["settings"]["buttonType"],
          buttonRounded: _newUIDetails[activeTab]["settings"]["buttonRounded"],
          buttonColour: _newUIDetails[activeTab]["settings"]["buttonColour"],
          buttonTextColour: _newUIDetails[activeTab]["settings"]["buttonTextColour"],
          selectedFrontEnd: newUIDetails[activeTab]["settings"]["selectedFrontEnd"],
          selectedBackEnd: newUIDetails[activeTab]["settings"]["selectedBackEnd"],

          projectFile: logo,
          favicon: favIcon,
          backgroungImage: bgImage,
          textcolor: _newUIDetails[activeTab]["settings"].textcolor,
          color: _newUIDetails[activeTab]["settings"].color,
          bgColor: _newUIDetails[activeTab]["settings"].bgColor,
          multipleList: multipleList,
          multipleListDummy: multipleListDummy,
          emailService: emailService,
          displayProjectLogoOrName: _newUIDetails[activeTab]["settings"].displayProjectLogoOrName,
          emailServiceType: emailServiceType,
          emailDetails: emailDetails,
          emailDetails1: emailDetails1,
          sendgridApiKey: sendgridApiKey,
          sendgridEmail: sendgridEmail,
          nodeMailerEmail: nodeMailerEmail,
          smtphost: smtphost,
          smtpuser: smtpuser,
          smtppassword: smtppassword,
          projectNameValue: projectNameValue,
          isGoogleLoginRequired: _newUIDetails[activeTab]["settings"].isGoogleLoginRequired,
          googleClientID: _newUIDetails[activeTab]["settings"].googleClientID,
          isGoogleRecaptchaRequired: _newUIDetails[activeTab]["settings"].isGoogleRecaptchaRequired,
          googleRecaptchaSiteKey: _newUIDetails[activeTab]["settings"].googleRecaptchaSiteKey,
          loginSideImage: _newUIDetails[activeTab]["settings"].loginSideImage
        })
        setNewUIDet(_newUIDetails);
      }, 200);
    }
  }

  const setEditProjectdata = async (projectData) => {

    let bgImage, favIcon, logo;
    if (props && props.editProject && projectData._id) {
      let projectId = projectData._id;
      let editRecord = projectData;
      let jsonData = JSON.parse(editRecord.jsonObject);
      let controllers = jsonData.controllers;
      console.log("..............................JSON DATA...................................", jsonData)
      if (jsonData.uiSettings.Admin.fav === "default_fav_icon.png") {
        favIcon = "default_fav_icon.png";
      } else {
        let x = jsonData.uiSettings.Admin.fav;
        let y = x.split("_");
        y.splice(0, 1);
        favIcon = y.join("_");
      }

      if (jsonData && jsonData.uiSettings.Admin && jsonData.uiSettings.Admin.bg) {
        let x1 = jsonData.uiSettings.Admin.bg;
        let y1 = x1.split("_");
        y1.splice(0, 1);
        bgImage = y1.join("_");
      }

      if (jsonData.uiSettings.Admin.logo === "default_project_logo.png") {
        logo = "default_project_logo.png";
      } else {
        let x2 = jsonData.uiSettings.Admin.logo;
        let y2 = x2.split("_");
        y2.splice(0, 1);
        logo = y2.join("_");
      }

      setEditRecord(editRecord);
      setMultipleListDummy(jsonData.multipleListDummy);
      setMultipleList(jsonData.multipleList);
      updateControllers(controllers);
      updateColor(jsonData.uiSettings.Admin.color);
      setEmailService(jsonData.emailService);
      setIsGoogleLoginReq(jsonData.uiSettings.Admin.isGoogleLoginRequired);
      setIsGoogleCaptchaReq(jsonData.uiSettings.Admin.isGoogleRecaptchaRequired);
      setGoogleCaptchaKey(jsonData.uiSettings.Admin.googleRecaptchaSiteKey);
      setGoogleClientID(jsonData.uiSettings.Admin.googleClientID);
      updateTextColor(jsonData.uiSettings.Admin.textcolor);
      setEmailServiceType(jsonData.emailSourceType);
      setEmailDetails(jsonData.emailSourceType == "sendgrid" ? true : false);
      setEmailDetails1(jsonData.emailSourceType == "nodeMailer" ? true : false);
      setNodeMailerEmail(jsonData.nodeMailerEmail);
      setSMTPhost(jsonData.smtphost);
      setSMTPuser(jsonData.smtpuser);
      setSMTPpassword(jsonData.smtppassword);
      setSendGridApiKey(jsonData.sendGridApiKey);
      setSendGridEmail(jsonData.sendGridEmail);
      onSetProjectID(projectId);
      setProjectNameValue(jsonData.projectName.charAt(0).toUpperCase() + jsonData.projectName.slice(1));
      setDisplayProjLogoName(jsonData.uiSettings.Admin.displayProjectLogoOrName);
      setProjectLogo(jsonData.uiSettings.Admin.logo);
      setProjectFav(jsonData.uiSettings.Admin.fav);
      setProjectBg(jsonData.uiSettings.Admin.bg);
      updateBgColor(jsonData.uiSettings.Admin.bgColor);
      setSelectedBg(jsonData.uiSettings.Admin.selectedBackground);
      onSetServices(jsonData.services);
      setMultiTabData(jsonData.multiTabsData);
      setControllerID(jsonData.controllerId);
      setNewUIDet(jsonData.newUIDetails);
      onSetProjectFile(logo);
      onSetBgImage(bgImage);
      setSelLoginTemplate(jsonData.uiSettings.Admin.selectedLoginTemplate);
      setLoginSideImage(jsonData.uiSettings.Admin.loginSideImage);
      onSetDuplicateScreenDD(jsonData.doNotListInDuplicateScreenDropDown);
      onSetFavIcon(favIcon);
      setApiValue(jsonData.loginApi ? true : false);
      setLoginUrl(jsonData.loginApi ? jsonData.loginApi : "");
      setLoginAPI(jsonData.loginApi ? jsonData.loginApi : "");
      const _api = jsonData.api === 0 ? 0 : jsonData.api ? jsonData.api : "";
      setApi(_api);
      const project_id = jsonData.s_g_project_Id ? jsonData.s_g_project_Id : "";
      setProjectID(project_id);
      onSetEditModal(true);

      handleExistingProject(projectNameValue);
      setUpdateType('update');
      favIconSubmitted();
      uploadExits();
      submit('update');
    }
  }

  // triggers on color change 
  const handleColorChange = async (e, type) => {
    let _newUIDetails = newUIDetails;
    if (type === 'templateColor') {
      _newUIDetails[activeTab]["settings"]["color"] = e;
      updateColor(e);
    } else if (type === 'textColor') {
      _newUIDetails[activeTab]["settings"]["textcolor"] = e;
      updateTextColor(e);
    } else if (type === 'buttonColor') {
      updateBtnColor(e);
    } else if (type === 'buttonTextColour') {
      updateBtnTxtColor(e);
    } else if (type === 'navColour') {
      _newUIDetails[activeTab]["settings"]["navColour"] = e;
      updateNavColor(e);
    } else if (type === 'navTextColour') {
      _newUIDetails[activeTab]["settings"]["navTextColour"] = e;
      updateNavTxtColor(e);
    }
    setNewUIDet(_newUIDetails);
  };

  // triggers on background output
  const backgroundOutput = async (values) => {
    let _newUIDetails = newUIDetails;
    // const { color, image, selectedBackground} = values;
    let color, image, selectedBackground;
    color = values && values.color ? values.color : '';
    image = values && values.image ? values.image : '';
    selectedBackground = values && values.selectedBackground ? values.selectedBackground : '';
    _newUIDetails[activeTab]["settings"]["bgColor"] = color;
    _newUIDetails[activeTab]["settings"]["bg"] = image;
    _newUIDetails[activeTab]["settings"]["selectedBackground"] = selectedBackground;

    updateBgColor(color);
    setProjectBg(image);
    setSelectedBg(selectedBackground);
    setNewUIDet(_newUIDetails);
  }

  const setmultipleListDummy = async (value) => {
    setMultipleListDummy(value);
    setSelControllerInd(0);
  }



  const mailFields = (e) => {
    let _newUIDetails = newUIDetails;
    if (e == "sendgrid") {
      setEmailDetails1(false);
      setEmailDetails(prevState => !prevState.emailDetails);
    } else {
      setEmailDetails(false);
      setEmailDetails1(prevState => !prevState.emailDetails1);
    }
    setEmailServiceType(e);
  }


  //To Save Email Service Details
  const saveServiceDetails = (field, value) => {
    let _newUIDetails = newUIDetails;
    if (field !== "sendgridApiKey" || field !== "sendgridEmail" || field !== "smtphost" || field !== "smtpuser" || field !== "smtppassword" || field !== "emailService" || field !== "nodeMailerEmail") {
      _newUIDetails[activeTab]["settings"][field] = value
    }

    switch (field) {
      case 'sendgridApiKey': setSendGridApiKey(value); break;
      case 'sendgridEmail': setSendGridEmail(value); break;
      case 'nodeMailerEmail': setNodeMailerEmail(value); break;
      case 'smtphost': setSMTPhost(value); break;
      case 'smtpuser': setSMTPuser(value); break;
      case 'smtppassword': setSMTPpassword(value); break;
      case 'emailService': setEmailService(value); break;
      case 'displayProjectLogoOrName': setDisplayProjLogoName(value); break;
      case 'isGoogleLoginRequired': setIsGoogleLoginReq(value); break;
      case 'googleClientID': setGoogleClientID(value); break;
      case 'isGoogleRecaptchaRequired': setIsGoogleCaptchaReq(value); break;
      case 'googleRecaptchaSiteKey': setGoogleCaptchaKey(value); break;
      case 'loginSideImage': setLoginSideImage(value); break;
    }
    setNewUIDet(_newUIDetails);
  }

  const [fontType, setFontType] = useState(null);

  const getFontFamily = async (value) => {
    let newUIDetails_ref = newUIDetails;
    newUIDetails_ref[activeTab]["settings"]["fontType"] = value.code;
    setFontType(value.code);
    setNewUIDet(newUIDetails_ref);
  }

  const setPagination = (position) => {
    let newUIDetails_ref = newUIDetails;
    newUIDetails_ref[activeTab]["settings"]["paginationPosition"] = position;
    onSetPaginationPos(position);
    setNewUIDet(newUIDetails_ref);
  }

  const chooseFrontBackEndLanguage = (lang, type) => {
    if (type == 'front') {
      newUIDetails[activeTab]["settings"]["selectedFrontEnd"] = lang;

      setNewUIDet(newUIDetails);
      setSelectedFrontEnd(lang);
    }
    if (type == 'back') {
      newUIDetails[activeTab]["settings"]["selectedBackEnd"] = lang;

      setNewUIDet(newUIDetails);
      setSelectedBackEnd(lang);
    }
  }

  const updateButtonStyle = (type, value) => {
    let _newUIDetails = newUIDetails;
    if (type === 'type') {
      _newUIDetails[activeTab]["settings"]["buttonType"] = value;
      onSetBtnType(value);
    } else if (type === 'rounded') {
      _newUIDetails[activeTab]["settings"]["buttonRounded"] = value;
      onSetBtnRounded(value);
    } else if (type === 'colour') {
      _newUIDetails[activeTab]["settings"]["buttonColour"] = value;
      onSetBtnColor(value);
    } else if (type === 'textColour') {
      _newUIDetails[activeTab]["settings"]["buttonTextColour"] = value;
      updateBtnTxtColor(value);
    }
    setNewUIDet(_newUIDetails);
  }

  const executeSettings = () => {
    return (
      <>
        <Settings
          ref={SettingsRef}
          handleColorChange={handleColorChange} // function call
          backgroundOutput={backgroundOutput}  // function call
          selectedBackground={selectedBackground}
          favicon={props.projectFav}
          projectFav={projectFav}
          projectBg={props.projectBg}
          projectFile={props.projectFile}
          projectLogo={projectLogo}
          projectNameValue={projectNameValue}
          projectLoginSideImage={props.projectLoginSideImage}
          loginSideImage={loginSideImage}
          multipleList={multipleList}
          multipleListDummy={multipleListDummy}
          setmultipleListDummy={setmultipleListDummy}  // function call
          mailFields={mailFields}  // function call
          activeTab={activeTab}
          saveServiceDetails={saveServiceDetails}   // function call
          handleExistingProject={handleExistingProject}   // function call
          setShowSettings={setShowSettings}  // function call
          getFontFamily={getFontFamily}  // function call
          setPagination={setPagination}  // function call
          updateButtonStyle={updateButtonStyle} // function call
          chooseFrontBackEndLanguage={chooseFrontBackEndLanguage}
        ></Settings>
      </>
    )
  }

  const executeSidebar = () => {
    return (
      <>
        <Sidebar visible={showSettings}
          position="right"
          styleclass="sideBarCustomClass"
          className="p-sidebar-md"
          onHide={() => onSetShowSettings(false)}
        >
          {showSettings && executeSettings()}
        </Sidebar>
      </>
    )
  }

  const hideSidebarIcon = (type = 'left') => {
    if (type === 'right') {
      return (
        <>
          <FontAwesomeIcon
            color={newUIDetails[activeTab]["settings"]['navTextColour']}
            icon={faUser}
            data-toggle="tool-tip"
            title="hide Sidebar"
          />
        </>
      )
    } else {
      return (
        <>
          <FontAwesomeIcon
            color={newUIDetails[activeTab]["settings"]['navTextColour']}
            icon={faBars}
            data-toggle="tool-tip"
            title="hide Sidebar"
            style={{ fontSize: 18, opacity: 0.5 }}
          />
        </>
      )
    }
  }

  // Rendering Sidebar Menu
  const renderSideMenuBar = () => {
    return (
      <>
        <Menu
          ref={MenuRef}
          controllers={controllers}
          activeTab={activeTab}
          setMenulistForUI={setMenulistForUI}
          newUIDetails={newUIDetails}
          menuList={newUIDetails[activeTab].menuList}
          menuListObj={newUIDetails[activeTab].menuListObj}
          setControllerIndex={setControllerIndex}
          loadControllerData={loadControllerData}
          deleteController={deleteConfirmModal}
          DefaultMenuList={defaultMenuList}
        >
        </Menu>
      </>
    )
  }

  const addScreenBtn = () => {
    return (
      <>
        <div style={{ borderBottom: "1px solid black" }}>
          {activeTab === 'Admin' &&
            <>
            <label className='labelMargin conrollerFont' style={{ color: textcolor, fontWeight: "bolder", paddingLeft: "18px", 
              fontFamily: newUIDetails[activeTab]["settings"]['fontType'] }}>
                Add Screen
                <span className='px-2 addIcon'>
                  <FontAwesomeIcon
                    className='editIcon icon_Color'
                    color='#ff00008c'
                    icon='plus-circle'
                    data-toggle="tool-tip"
                    title="Add"
                    onClick={(e) => controllerFieldsFunc('controller')}
                  />
                </span>
              </label>
            </>
          }
        </div>
      </>
    )
  }

  const loginScreenView = () => {
    return (
      <>
        <div style={{ borderBottom: "1px solid black", paddingLeft: "18px", fontFamily: newUIDetails[activeTab]["settings"]['fontType'] }}>
          <label className='labelMargin conrollerFont cursor-pointer'
            style={{ color: textcolor }} onClick={(e) => configureLoginScreen()}>
            Login Screen
          </label>
        </div>
      </>
    )
  }

  const forgotScreenView = () => {
    return (
      <>
        <div style={{ borderBottom: "1px solid black", paddingLeft: "18px", fontFamily: newUIDetails[activeTab]["settings"]['fontType'] }}>
          <label className='labelMargin conrollerFont cursor-pointer'
            style={{ color: textcolor }} onClick={(e) => configureForgotScreen()}>
            Forgot Password
          </label>
        </div>
      </>
    )
  }

  const registerScreenView = () => {
    return (
      <>
        <div style={{ borderBottom: "1px solid black", paddingLeft: "18px", fontFamily: newUIDetails[activeTab]["settings"]['fontType'] }}>
          <label className='labelMargin conrollerFont cursor-pointer'
            style={{ color: textcolor }} onClick={(e) => configureRegisterScreen()}>
            Register Screen
          </label>
        </div>
      </>
    )
  }

  const renderSideMenuRestScreens = () => {
    return (
      <>
        {addScreenBtn()}
        {loginScreenView()}
        {forgotScreenView()}
        {registerScreenView()}
      </>
    )
  }

  //KEEP
  // const [UIDataDeepCopy, setUIDataHook] = useState(newUIDetails_copy);
  // useEffect(()=> {
  //   setNewUIDet(UIDataDeepCopy);
  // },[UIDataDeepCopy]);

  // sidemenu methods Start
  const setMenulistForUI = async (tab, menus, menuListObj) => {

    let _newUIDetails = newUIDetails
    // let _newUIDetails = UIDataDeepCopy;  
    if (newUIDetails[tab]) {
      _newUIDetails[tab]["menuList"] = menus;
      _newUIDetails[tab]["menuListObj"] = menuListObj;

      // setUIDataHook(_newUIDetails);  
      const Admin = newUIDetails.Admin
      Admin.menuList = menus;
      setNewUIDet(prev => ({ ...prev, Admin }));
    }
  }

  const setControllerIndex = async (controllerId) => {
    let controllers_ref = controllers;
    let index = await controllers_ref.findIndex((elem) => {
      return (elem.controllerId === controllerId)
    });
    if (index > -1) {
      setSelControllerInd(index);
      setDisplayPreModal(true);
      setShowHideLoginScreen(false);
      setShowHideForgotScreen(false);
      setShowHideRegisterScreen(false);
      setSelFilterIndex(0);
      setSelFormInd(0);
    }
  }

  const loadControllerData = () => {
    setIsActionModal(true);
    setIsOpenLoginUrlModal(true);
    setEditOpenLoginUrlModal(true);
  }

  const deleteConfirmModal = async (index, type, controllerName) => {
    setDeleteControllerModal(true);
    setDeleteIndex(index);
    setDelControllerName(controllerName);
  }

  // sidemenu methods End

  // When clicked on Login Screen
  const configureLoginScreen = () => {
    setShowHideLoginScreen(true);
    setDisplayPreModal(false);
    setShowHideRegisterScreen(false);
    setShowHideForgotScreen(false);
  }

  // When clicked on Register screen 
  const configureRegisterScreen = () => {
    setShowHideRegisterScreen(true);
    setShowHideLoginScreen(false);
    setShowHideForgotScreen(false);
    setDisplayPreModal(false);
  }

  // When clicked on forgot screen
  const configureForgotScreen = () => {
    setShowHideForgotScreen(true);
    setDisplayPreModal(false);
    setShowHideRegisterScreen(false);
    setShowHideLoginScreen(false);
  }

  const setSkipFiles = (skipFile, filesToSkip) => {
    let _controllers = controllers;
    _controllers[selectedControllerIndex]['skipFiles'] = skipFile;
    _controllers[selectedControllerIndex]['filesToSkip'] = filesToSkip;

    updateControllers(_controllers);
  }

  const setGeneratedFiles = (generatedType, filesPropName, files) => {
    let _controllers = controllers;
    _controllers[selectedControllerIndex][generatedType] = true;
    _controllers[selectedControllerIndex][filesPropName] = files;

    updateControllers(_controllers);
  }

  // Rendering Source Code Editor Button
  const renderCodeEditorBtn = () => {
    return (
      <>
        <CodeEditorButton
          projectName={projectNameValue}
          displayProjectLogoOrName={displayProjectLogoOrName}
          projectLogo={projectLogo}
          projectFav={projectFav}
          bgColor={bgColor}
          selectedBackground={selectedBackground}
          loginSideImage={loginSideImage}
          service={activeTab}
          selectedLoginTemplate={selectedLoginTemplate}
          emailService={emailService}
          isGoogleLoginRequired={isGoogleLoginRequired}
          googleClientID={googleClientID}
          isGoogleRecaptchaRequired={isGoogleRecaptchaRequired}
          googleRecaptchaSiteKey={googleRecaptchaSiteKey}
          api={api}
          loginCtrlName={loginurl}
          updateType={updateType}
          s_g_project_Id={s_g_project_Id}
          controller={controllers[selectedControllerIndex]}
          setSkipFiles={setSkipFiles}
          setGeneratedFiles={setGeneratedFiles}
        />
      </>
    )
  }

  const getSelectedTemplate = async (value) => {
    if (value) {
      setSelLoginTemplate(value);
    }
  }

  // Rendering Login Template
  const renderLoginTemplate = () => {
    return (
      <>
        <div className="h-100 w-100">
          <LoginTemplate
            backGroundType={selectedBackground}
            bgImage={projectBg}
            bgColor={bgColor}
            googleLogin={isGoogleLoginRequired}
            projectLogo={projectLogo}
            loginSideImage={loginSideImage}
            googleCaptcha={isGoogleRecaptchaRequired}
            selectedTemplate={selectedLoginTemplate}
            activeTab={activeTab}
            projectLoginSideImage={props.projectLoginSideImage}
            getSelectedTemplate={getSelectedTemplate}
            buttonColour={newUIDetails[activeTab]["settings"]['buttonColour']}
            buttonTextColour={newUIDetails[activeTab]["settings"]['buttonTextColour']}
            buttonType={newUIDetails[activeTab]["settings"]['buttonType']}
            buttonRounded={newUIDetails[activeTab]["settings"]['buttonRounded']}
          >
          </LoginTemplate>
        </div>
      </>
    )
  }

  // Rendering Forgot Template
  const renderForgotTemplate = () => {
    return (
      <>
        <div className="h-100 w-100">
          <ForgotPasswordTemplate
            selectedTemplate={selectedLoginTemplate}
            bgImage={projectBg}
            projectLogo={projectLogo}
            loginSideImage={loginSideImage}
            bgColor={bgColor}
            activeTab={activeTab}
            backGroundType={selectedBackground}
            buttonColour={newUIDetails[activeTab]["settings"]['buttonColour']}
            buttonTextColour={newUIDetails[activeTab]["settings"]['buttonTextColour']}
            buttonType={newUIDetails[activeTab]["settings"]['buttonType']}
            buttonRounded={newUIDetails[activeTab]["settings"]['buttonRounded']}
          >
          </ForgotPasswordTemplate>
        </div>
      </>
    )
  }

  // rendering register template
  const renderRegisterTemplate = () => {
    return (
      <>
        <div className="h-100 w-100">
          <RegisterTemplate
            selectedTemplate={selectedLoginTemplate}
            googleLogin={isGoogleLoginRequired}
            projectLogo={projectLogo}
            googleCaptcha={isGoogleRecaptchaRequired}
            loginSideImage={loginSideImage}
            bgImage={projectBg}
            bgColor={bgColor}
            activeTab={activeTab}
            backGroundType={selectedBackground}
            buttonColour={newUIDetails[activeTab]["settings"]['buttonColour']}
            buttonTextColour={newUIDetails[activeTab]["settings"]['buttonTextColour']}
            buttonType={newUIDetails[activeTab]["settings"]['buttonType']}
            buttonRounded={newUIDetails[activeTab]["settings"]['buttonRounded']}
          >
          </RegisterTemplate>
        </div>
      </>
    )
  }


  const RemoveATab = async (index, type) => {
    if (type) {
      setDisplayUIModal(false);
    } else {

      let obj1 = newUIDetails[multiTabsData[index].name];
      let ob2 = obj1.selectedAuthScreen;

      controllers.forEach((object, i) => {
        let temporaryArr = [];
        if (object.controllerId === ob2) {
          let selcont = object;
          let contIndex = i;

          selcont.controllerFields.forEach((pro, ind) => {
            if (!(pro.manuallyPushed && pro.manuallyPushed === true)) {
              if (pro.name == 'role') {
              }
              else {
                temporaryArr.push(pro);
              }
            }

          });
          controllers[contIndex].controllerFields = temporaryArr;

        }
      });

      await delete newUIDetails[multiTabsData[index].name]
      multiTabsData.splice(index, 1)
      setMultiTabData(multiTabsData);
      onSetActiveTab(multiTabsData[0].name);
      setDisplayUIModal(false);
      updateControllers(controllers);
    };
  }

  const [removeIndex, onSetRemoveIndex] = useState(null);

  // Handling Delete UI
  const handleDeleteUI = (index) => {
    return (
      <DeleteUIModal
        displayUIModal={displayUIModal}
        index={removeIndex}
        RemoveATab={RemoveATab}

      />
    )
  }

  //getTextModal
  const getFieldModal = async (type, rowData, index, selContInd) => {
    oldFieldData = rowData;
    selectedContIndex = selContInd;
    setIsOpenInputModel(true);
    setTypeFromFields(type);
    setRowDataFromField(rowData);
    setIndexFromField(index);
  }

  //To delete the field from specific controller
  const deleteFieldsFromController = async (selectedControllerIndex, item, i, type) => {
    if (type) {
      await setDisplayFieldModal(false);
    }
    else {
      let controllers_ref = controllers;
      let controller = controllers_ref[selectedControllerIndex];
      if (controller) {
        if (controller.controllerFields && controller.controllerFields.length > 0) {
          let index = controller.controllerFields.findIndex(obj => item && item.name == obj.name)
          if (index != -1) {
            controllers_ref[selectedControllerIndex].controllerFields.splice(index, 1)
          }

        }
      }
      updateControllers(controllers_ref);
      setDisplayFieldModal(false);
    }
  }

  const handleDeleteField = () => {

    return (
      <DeleteFieldModal
        displayFieldModal={displayFieldModal}
        fIndex={fieldIndex}
        deleteFieldsFromController={deleteFieldsFromController}
        cIndex={selectedControllerIndex}
        itemObj={itemObj}
      />
    )
  }

  const deleteFieldFunc = (i, sci, item) => {
    setDisplayFieldModal(true);
    setFieldIndex(i);
    setSelControllerInd(sci);
    setItemObj(item);

  }

  const OpenDeleteNewActionModal = async () => {
    await setDisplayNewActionDeleteModal(true);
  }

  // rendering Data table
  // style={{ marginBottom: 0, overflowX: "auto", overflowY: "auto", whiteSpace: "nowrap", border: "1px solid black"}}
  const renderDataTable = () => {
    return (
      <>
        <div className="table-responsive table-height table-responsive_width">
          <Table bordered className="table table-bordered mt-2"
            style={{ whiteSpace: "nowrap" }}>
            <thead>
              <tr>
                {(controllers && controllers.length && controllers[selectedControllerIndex]) &&
                  <>
                    {controllers[selectedControllerIndex].controllerFields.map((item, i) => {
                      return (
                        <>
                          <th key={`citem_${i}`} className="edit_icon" style={{ backgroundColor: color, color: textcolor }}>
                            <div className='d-flex align-items-center w-full'>
                              <div className=''>{item.header} <i className="pi pi-sort-alt"></i></div>
                              {(activeTab === "Admin") &&
                                <>
                                  <FontAwesomeIcon
                                    className="ms-1"
                                    style={{ fontSize: 12 }}
                                    color={textcolor}
                                    icon={faPencilAlt}
                                    data-toggle="tool-tip"
                                    title="Edit"
                                    onClick={() => activeTab == "Admin" ? getFieldModal('edit', item, i, selectedControllerIndex) : null}
                                  />

                                  <FontAwesomeIcon
                                    className='ms-1'
                                    color={(item.disabled || activeTab !== "Admin") ? "grey" : '#ff00008c'}
                                    icon={faTimes}
                                    style={{ fontSize: 12 }}
                                    // style={{ width: 15, height: 15, marginBottom: "5px", marginTop: "5px" }}
                                    data-toggle="tool-tip"
                                    title="Delete Field"
                                    disabled={item.disabled || activeTab !== "Admin" ? true : false}
                                    onClick={() => { deleteFieldFunc(i, selectedControllerIndex, item) }}
                                  />
                                </>
                              }
                            </div>
                            <InputText className="w-100" />
                          </th>
                        </>
                      )
                    })}
                  </>
                }
              </tr>
            </thead>
            <tbody>
              {[...Array(showSampleRowsCount)].map((e, i) =>
                <tr key={i}>
                  {(controllers && controllers.length && controllers[selectedControllerIndex]) &&
                    controllers[selectedControllerIndex].controllerFields.map((item, i) => {
                      return <td key={`item_${i}`}>
                        {item.type != 'email' && item.type != 'date' && item.type != 'profile' && item.name &&
                          <span className='text-capitalize'>
                            {fieldSampleData[item.name.toLowerCase()] ?
                              fieldSampleData[item.name.toLowerCase()] : item.name}
                          </span>
                        }
                        {item.type === 'email' && fieldSampleData.email}
                        {(item.type === 'date' && item.dateFormat && fieldSampleData[item.dateFormat]) && fieldSampleData[item.dateFormat]}
                        {item.type === 'profile' && <img src={fieldSampleData.image} className='h-100' />}

                      </td>
                    })
                  }
                </tr>)
              }
            </tbody>

          </Table>
        </div>
      </>
    )
  }

  // Closing Input modal functionality
  const closeInputModal = (index, type, editview) => {
    setIsOpenInputModel(false);
    setIsActionModal(false);
    setDeleteControllerModal(false);
    setEditOpenLoginUrlModal(false);

    if (type == "loginApimodal" && !editview) {
      let _controllers = controllers;
      _controllers.splice(selectedControllerIndex, 1);

      updateControllers(_controllers);
      setSelControllerInd(0);
      setEditOpenLoginUrlModal(false);
    }
    if (index >= 0 && type == "deleteController") {
      if (editview == "empty") {
        removeFormFields(index, 'controller', "empty");
      } else {
        removeFormFields(index, 'controller');
      }
    }
  }

  //removeFormFields
  const removeFormFields = async (index, type, flag) => {
    let controllers_ref = controllers;
    let multipleList_ref = multipleList;
    let newUIDetails_ref = newUIDetails;
    let multipleListDummy_ref = multipleListDummy;
    if (type === 'table') {
      controllers_ref[selectedControllerIndex]["controllerFields"].splice(index, 1);
      setSelFilterIndex(index > 0 ? index - 1 : 0);
    } else if (type === 'form') {
      controllers_ref[selectedControllerIndex]["controllerFormFields"].splice(index, 1);
      setSelFormInd(index > 0 ? index - 1 : 0);
    } else if (type === 'profile') {
      controllers_ref[0]["controllerProfileFields"].splice(index, 1);
      setShowProfileDD(false);
    } else if (type === 'controller') {
      let tempUrlTypes = [];

      MenuRef.current.handleDeleteController(newUIDetails['Admin']['menuList'][index].controllerId);
      controllers_ref.splice(index, 1);
      if (flag !== "empty") {
        let indexNumber = multipleListDummy_ref.findIndex((element) => element === multipleList_ref[index].label);
        if (indexNumber >= 0) {
          multipleListDummy_ref.splice(indexNumber, 1)
          newUIDetails_ref[activeTab].selectedScreens.splice(indexNumber, 1);
        }
        multipleList_ref.splice(index, 1);
      }
      for (let i = 0; i < controllers_ref.length; i++) {
        Object.keys(controllers_ref[i]).forEach((item, index) => {
          if (item === 'name') {
            tempUrlTypes.push({ label: controllers_ref[i].name, value: controllers_ref[i].name })
          }
        }
        )
      }
      if (index === 0) {
        setSelControllerInd(0);
        setApiUrlTypes(tempUrlTypes);
      } else {
        setSelControllerInd(index - 1);
        setApiUrlTypes(tempUrlTypes);
      }
    }
    updateControllers(controllers_ref);
    setNewUIDet(newUIDetails_ref);
    setMultipleListDummy(multipleListDummy_ref);
    setMultipleList(multipleList_ref);
  }

  const closeAddModalSampleModal = async () => {
    setisOpenAddByModal(false);
  }

  //removeFilterFields
  const removeFilterFields = async (index, type, filterIndex) => {
    let _controllers = controllers;
    if (type == 'tableFilter') {
      _controllers[selectedControllerIndex]["controllerFields"][index]['filterElement'].splice(filterIndex, 1);
    } else if (type == 'formFilter') {
      _controllers[selectedControllerIndex]["controllerFormFields"][index]['options'].splice(filterIndex, 1);
    }
    updateControllers(_controllers);
  }

  //activeTabs
  const activeTabs = async (index, fieldName) => {
    let _controllers = controllers;
    let valueType = (_controllers[selectedControllerIndex]["controllerFields"][index][fieldName] || '');
    setSelFilterIndex(index);
    if (valueType) {
      setShowFilterElement(true);
    } else {
      setShowFilterElement(false);
    }
  }






  const controllerFieldsFunc = async (values, type, formType, index, i, showOrHideFields) => {

    newFieldData = values;
    let contrs = controllers;
    let neededController = controllers[selectedContIndex];

    if ((oldFieldData && oldFieldData.type == "dropDown") || (oldFieldData && oldFieldData.type == "multiSelect")) {
      if (newFieldData.name != oldFieldData.name || newFieldData.options != oldFieldData.options) {
        let defCriteria = neededController.defaultCriteria;

        const indexToRemove = defCriteria.findIndex(item => item.key == oldFieldData.name);

        if (indexToRemove !== -1) {
          defCriteria.splice(indexToRemove, 1);
        }
        if (defCriteria.length == 0) {
          delete contrs[selectedContIndex].defaultCriteria;
          delete contrs[selectedContIndex].defaultFilterRequired;
        }
        contrs[selectedContIndex].defaultCriteria = defCriteria;
        updateControllers(contrs);

      }
    }

    let controllers_ref = controllers;
    let selectedControllerIndex_ref = selectedControllerIndex;
    let controllerId_ref = controllerId + 1;
    if (values === 'controller') {
      controllers_ref.push({
        controllerId: controllerId_ref,
        "displayServices": ["Admin"],
        controllerFields: [
          {
            "name": "name",
            "type": "text",
            "placeholder": "Name",
            "label": "Name",
            "header": "Name",
            "width": 110,
            "id": "name",
            "displayinaddForm": true,
            "displayineditForm": true,
            "displayinlist": true,
            "profileField": false,
            "controllerName": null,
            "fieldType": "Link",
            "textAlign": "Center",
            "displayinServer": "True",
            "displayinregisterForm": false,
            "isBulkUploadField":true,
            "serverFieldType": "String",
            "globalSearchField": true,
            "show": true,
            "field": "name"
          },
          {
            "name": "createdByName",
            "type": "text",
            "placeholder": "Created By",
            "label": "Created By",
            "width": 120,
            "header": "Created By",
            "id": "createdByName",
            "displayinlist": true,
            "globalSearchField": "false",
            "controllerId": 1001,
            "fieldType": "Text",
            "textAlign": "Center",
            "displayinregisterForm": false,
            "globalSearchField": false,
            "isBulkUploadField": false,
            "show": true,
            "field": "createdByName",
            "mobile": true,
            "filter": true,
            "displayInSettings": true
          },
          {
            "name": "updatedByName",
            "type": "text",
            "placeholder": "Updated By",
            "label": "Updated By",
            "width": 120,
            "header": "Updated By",
            "id": "updatedByName",
            "displayinlist": true,
            "fieldType": "Text",
            "textAlign": "Center",
            "displayinregisterForm": false,
            "globalSearchField": false,
            "isBulkUploadField": false,
            "show": true,
            "filter": true,
            "field": "updatedByName",
            "mobile": true,
            "displayInSettings": true
          },
          {
            "name": "created",
            "type": "date",
            "placeholder": "Created",
            "label": "Created",
            "width": 90,
            "header": "Created",
            "derivedValue": "created=undefined",
            "actions": [],
            "actionsNumber": [],
            "id": "created",
            "displayinaddForm": false,
            "displayineditForm": false,
            "displayinlist": true,
            "globalSearchField": false,
            "controllerId": null,
            "fieldType": "Date",
            "dateFormat": config.fullDateFormat,
            "textAlign": "Center",
            "isBulkUploadField": false,
            "disabled": true,
            "displayinServer": "True",
            "serverFieldType": "Date",
            "show": true,
            "field": "created"
          },
          {
            "name": "updated",
            "type": "date",
            "placeholder": "Updated",
            "label": "Updated",
            "width": 90,
            "header": "Updated",
            "derivedValue": "updated=undefined",
            "actions": [],
            "actionsNumber": [],
            "id": "updated",
            "displayinaddForm": false,
            "displayineditForm": false,
            "displayinlist": true,
            "globalSearchField": false,
            "controllerId": null,
            "fieldType": "Date",
            "dateFormat": config.fullDateFormat,
            "textAlign": "Center",
            "isBulkUploadField": false,
            "disabled": true,
            "displayinServer": "True",
            "serverFieldType": "Date",
            "show": true,
            "field": "updated"
          }
        ],
      });
      updateControllers(null);
      updateControllers(controllers_ref);
      setSelControllerInd(controllers_ref.length && controllers_ref.length !== 1 ? controllers_ref.length - 1 : 0);
      setShowProfileDD(false);


      setTabThreeActive(true);
      setActThreeTab(true);
      onSetMainTab('three');
      setControllerID(controllerId_ref);
      setIsActionModal(true);

      if (!api) {
        setIsOpenLoginUrlModal(true);
      }

    } else if (type == 'draggableController') {
      let array = controllers_ref[selectedControllerIndex_ref].controllerFields;
      for (let obj of array) {
        let Exists = await values.find(o => o.name == obj.name);
        if (!Exists) {
          values.push(obj);
        }
      }
      controllers_ref[selectedControllerIndex_ref].controllerFields = values;
      closeAddModalSampleModal();
    } else {
      if (controllers_ref && controllers_ref.length && controllers_ref[selectedControllerIndex_ref]) {
        let tableFields = controllers_ref[selectedControllerIndex_ref].controllerFields;
        values.textAlign = "center";
        values.displayinServer = "True";
        values.serverFieldType = values.type == "number" ? "Number" : values.type == "date" ? "Date" : values.type == "dropDown" && values.isMultiSelect == true ? "Object" : "String";
        values.show = true;
        values.field = values.name;
        values.header = values.label;
        values.displayinForm = values.displayinForm;
        values.profileField = values.profileField;
        values.id = values.id;
        values.showOrHideFields = showOrHideFields && showOrHideFields.length > 0 ? showOrHideFields : [];
        if (values.options) {
          values.options = values.options;
        }
        if (values.addToSettings) {
          values.addToSettings = true;
        }

        if (formType == "edit") {
          controllers_ref[selectedControllerIndex_ref].controllerFields[index] = values;
        } else {
          tableFields.push(values);
        }

        updateControllers(controllers_ref);
        setTableFields(tableFields);
      }
    }
  }

  // changeControllerName
  const changeContrllerName = async (e, selectedControllerIndex) => {
    let controllers_ref = controllers;
    let tempUrlTypes = [];
    if (e && e.target.value) {
      for (let i = 0; i < controllers_ref.length; i++) {
        Object.keys(controllers_ref[i]).forEach((item, index) => {
          if (item === 'name') {
            tempUrlTypes.push({ label: controllers_ref[i].name, value: controllers_ref[i].name })
          }
        })
      }
      if (apiValue && !loginurl) {
        setLoginUrl(controllers_ref[api].name);
      }
      setApiUrlTypes(tempUrlTypes);
      setProjectDetails(body);
    } else {
      let i = selectedControllerIndex;
      i += 1;
    }
  }

  //handleactions
  const handleControllerActions = async (e, fieldName) => {
    let _controllers = controllers;
    if (fieldName === 'actions') {
      if (e) {
        _controllers[selectedControllerIndex][fieldName] = e;

      } else {
        _controllers[selectedControllerIndex][fieldName] = e;
      }
    } else if ('login') {
      _controllers[selectedControllerIndex][fieldName] = e;
    }
    updateControllers(_controllers);
  }

  // Render Input Modal
  const getInputModal = (e) => {
    let loginApis = [];
    for (let obj of multiTabsData) {
      loginApis.push(newUIDetails[obj.name].selectedAuthScreen);
    }
    return (
      <InputModal
        openInputModal={isOpenInputModel}
        type={type}
        closeInputModal={closeInputModal}
        controllerFieldsFunc={controllerFieldsFunc}
        Api={apiValue}
        index={index}
        selectedControllerIndex={selectedControllerIndex}
        rowData={rowData}
        registerIndex={registerIndex}
        loginApi={loginApis}
        controllerId={controllers[selectedControllerIndex].controllerId}
        controllers={controllers}
        multipleList={multipleList ? JSON.parse(JSON.stringify(multipleList)) : []}
        controllerIndex={selectedControllerIndex}
        openlogInUrlModal={isOpenlogInUrlModal}
        currentControllerFields={controllers[selectedControllerIndex].controllerFields}
      />
    )
  }

  const login = async (values) => {
    let methods = ["get", "list", "create", "update", "remove", "load"];
    let _controllers = controllers;
    let _multipleList = multipleList;
    let _newUIDetails = newUIDetails;
    let _multipleListDummy = multipleListDummy;
    _controllers[values.actionsIndex].actions = values.actions;
    let methodsArray = [];
    methodsArray.push(methods[0]);
    methodsArray.push(methods[1]);
    methodsArray.push(methods[5]);
    for (const element of values.actions) {
      if (element == "add") {
        methodsArray.push(methods[2])
      } else if (element == "edit") {
        methodsArray.push(methods[3])
      } else if (element == "delete") {
        methodsArray.push(methods[4])
      }
    }
    values.description ? _controllers[values.actionsIndex].description = values.description : null;
    values.emailDescription ? _controllers[values.actionsIndex].emailDescription = values.emailDescription : null;
    values.defaultCriteria != "undefined" ? _controllers[values.actionsIndex].defaultCriteria = values.defaultCriteria : null;
    values.criDetails != "undefined" ? _controllers[values.actionsIndex].criDetails = values.criDetails : null;

    _controllers[values.actionsIndex].methods = methodsArray;
    if (values.loginUrl == true) {
      _controllers[values.actionsIndex].isAuth = true;
    }
    let _controllerIndex = controllerIndex + 1;
    if (values.createAScreenFrom == "duplicateExistingScreen" && values.createNewScreenWithOutServer == true){
      _controllers[values.actionsIndex].name = values.name;
      _controllers[values.actionsIndex].label = values.value;
      _controllers[values.actionsIndex].value = values.value;
    }
    else{
    _controllers[values.actionsIndex].name = values.name;
    _controllers[values.actionsIndex].label = values.name;
    _controllers[values.actionsIndex].value = values.name;
    }
    _controllers[values.actionsIndex].displayViewOfForm = values.displayViewOfForm;
    _controllers[values.actionsIndex].defaultFilterRequired = values.defaultFilterRequired;
    _controllers[values.actionsIndex].sideFormLeftOrRight = values.sideFormLeftOrRight ? values.sideFormLeftOrRight : "right";
    values.defaultFilterRequired ? _controllers[values.actionsIndex].defaultCriteria = values.defaultCriteria : null;
    _controllers[values.actionsIndex].displayActionsInTable = values.displayActionsInTable ? values.displayActionsInTable : false;
    _controllers[values.actionsIndex].isSendCustomEmail = values.isSendCustomEmail ? values.isSendCustomEmail : false;
    _controllers[values.actionsIndex].searchInDateRangeField = values.searchInDateRangeField && values.dateSearchRequired ? values.searchInDateRangeField : [];
    _controllers[values.actionsIndex].dateSearchRequired = values.dateSearchRequired ? values.dateSearchRequired : false;
    _controllers[values.actionsIndex].createNewScreenWithOutServer = values.createNewScreenWithOutServer ? values.createNewScreenWithOutServer : false;
    _controllers[values.actionsIndex].fetchFields = values.fetchFields && values.fetchFields.length > 0 ? values.fetchFields : [];
    _controllers[values.actionsIndex].createAScreenFrom = values.createAScreenFrom;
    // _controllers[values.actionsIndex].uiFilteringAndSorting = values.uiFilteringAndSorting ? values.uiFilteringAndSorting : false; //keeping for later ui filter and sort
    let indexNumber;
    if (_multipleList[values.actionsIndex] && _multipleList[values.actionsIndex].label) {
      indexNumber = _multipleListDummy.findIndex((element) => element === _multipleList[values.actionsIndex].label);
    } else {
      _multipleListDummy.push(_controllers[values.actionsIndex].name);
      _newUIDetails[activeTab].selectedScreens.push(_controllers[values.actionsIndex].name);
    }
    if (indexNumber >= 0) {
      _multipleListDummy[indexNumber] = controllers[values.actionsIndex].name;
      _newUIDetails[activeTab].selectedScreens[indexNumber] = _controllers[values.actionsIndex].name;
    }
    _multipleList[values.actionsIndex] = { label: _controllers[values.actionsIndex].name, value: _controllers[values.actionsIndex].name };
    _controllers[values.actionsIndex].actionsShowOrHideValue = [];
    for (let obj of actionsShowOrHide) {
      obj && _controllers[values.actionsIndex].actions.includes(obj.value) ? _controllers[values.actionsIndex].actionsShowOrHideValue.push(obj.value) : "";
    }
    if (values && values.loginUrl) {
      setApiValue(true);
      setApi(values.index);
      setLoginUrl(_controllers[values.actionsIndex].name);
    }

    if (values && values.loginUrl) {
      updateApiSendIndex(values.index);
    }

    if (editOpenlogInUrlModal) {
      MenuRef.current.updateControllerName(_controllers[values.actionsIndex]);
    } else {
      MenuRef.current.handleAddController([_controllers[values.actionsIndex]]);
    }
    onSetControllerIndex(_controllerIndex);
    setMultipleListDummy(_multipleListDummy);
    setMultipleList(_multipleList);
    setIsOpenLoginUrlModal(false);
    updateControllers(_controllers);
    setEditOpenLoginUrlModal(false);
    setNewUIDet(_newUIDetails);
    await checkActions(values.actionsIndex);
  }

  // check action  
  const checkActions = async (index) => {
    let controller = controllers[index];
    let actionIndexNumber = controller.controllerFields.findIndex((obj) => obj.fieldType === "Actions");
    if (controller && controller.displayActionsInTable && controller.displayActionsInTable === 'actions' && controller.controllerFields && actionIndexNumber == -1) {
      controller.controllerFields.push({
        "show": true,
        "textAlign": "center",
        "width": 60,
        "fieldType": "Actions",
        "field": "Actions",
        "header": "Actions",
        "label": "Actions",
        "filter": false,
        "sortable": false,
        "displayInSettings": true,
        "displayinServer": "false",
        "displayinlist": "true",
        "displayinaddForm": "false",
        "displayineditForm": "false",
      })
    } else if (controller && (!controller.displayActionsInTable || controller.displayActionsInTable !== 'actions') && controller.controllerFields) {
      let indexNumber = controller.controllerFields.findIndex((obj) => obj.fieldType === "Actions")
      if (indexNumber >= 0) {
        controller.controllerFields.splice(indexNumber, 1)
      }
    }
  }

  const handleDefaultScreens = async (controllers_argmts, index) => {
    let stateController = controllers;
    let newUIDetails_ref = newUIDetails;
    let multipleList_ref = multipleList;
    let multipleListDummy_ref = multipleListDummy;
    let newlyAddedControllers = [];
    const defaultScreenControllers = DefaultScreens.controllers;
    let countInd = 0;
    for (let controller of controllers_argmts) {
      countInd++;
      let index = stateController.findIndex(function (obj) {
        return (controller === obj.name)
      });
      if (index === -1) {
        let screenIndex = defaultScreenControllers.findIndex(function (obj) {
          return (controller === obj.value);
        });
        if (screenIndex !== -1) {
          let controllerId_ref = controllerId + countInd;
          let contData = JSON.parse(JSON.stringify(defaultScreenControllers[screenIndex]));
          contData.controllerId = controllerId_ref;
          contData.displayServices = ["Admin"];
          stateController.push(contData);
          newlyAddedControllers.push(contData);
          multipleList_ref.push({
            "label": controller,
            "value": controller
          });
          newUIDetails_ref[activeTab].selectedScreens.push(controller);
          multipleListDummy_ref.push(controller);
          setControllerID(controllerId_ref);
        }
      }
    }
    updateControllers(stateController);
    setMultipleListDummy(multipleListDummy_ref);
    setMultipleList(multipleList_ref);
    setSelControllerInd(index);
    setNewUIDet(newUIDetails_ref);
    MenuRef.current.handleAddController(newlyAddedControllers);
  }

  // Handling Duplicate Screens
  const handleDuplicateScreens = async (ctrlId, screenName, i, screenWithoutNewApi) => {
    let stateController = controllers;
    let multipleList_ref = multipleList;
    let multipleListDummy_ref = multipleListDummy;
    let controllerId_ref = controllerId;
    let newUIDetails_ref = newUIDetails;
    let doNotListDupScreenDD = doNotListInDuplicateScreenDropDown;

    let index = stateController.findIndex(function (obj) {
      return (ctrlId === obj.controllerId)
    });

    if (index >= 0) {
      controllerId_ref = controllerId_ref + 1;
      stateController[i] = {};
      stateController[i] = JSON.parse(JSON.stringify(stateController[index]));
      stateController[i].createNewScreenWithOutServer = false;
      if (screenWithoutNewApi) {
        stateController[i].ParentcontrollerId = (stateController[index].controllerId || stateController[index].controllerId_ref);
        stateController[i].createNewScreenWithOutServer = screenWithoutNewApi;
        doNotListDupScreenDD.push(controllerId_ref);
      }
      stateController[i].displayServices = ["Admin"];
      stateController[i].isAuth = false;
      stateController[i].name = screenName;
      stateController[i].controllerId = controllerId_ref;
      stateController[i].createAScreenFrom = "duplicateExistingScreen";
    }
    multipleList_ref.push({
      "label": screenName,
      "value": screenName
    });
    multipleListDummy_ref.push(screenName);
    MenuRef.current.handleAddController([stateController[i]]);
    newUIDetails_ref[activeTab].selectedScreens.push(screenName);

    setControllerID(controllerId_ref);
    updateControllers(stateController);
    setMultipleListDummy(multipleListDummy_ref);
    setMultipleList(multipleList_ref);
    setSelControllerInd(index);
    onSetDuplicateScreenDD(doNotListDupScreenDD);
    setNewUIDet(newUIDetails_ref);
  }

  // Rendering Login Api POPUP
  const getloginApiPopup = (index) => {
    return (
      <LoginApiModal
        openlogInUrlModal={isOpenlogInUrlModal}
        isActionModal={isActionModal}
        closeInputModal={closeInputModal}
        controllers={editOpenlogInUrlModal ? controllers : []}
        checkcontrollers={controllers}
        controllersList={controllers}
        editOpenlogInUrlModal={editOpenlogInUrlModal ? editOpenlogInUrlModal : editOpenlogInUrlModal}
        changeContrllerName={changeContrllerName}
        index={index}
        isBackendGenerated={controllers[index].isBackendGenerated}
        isFrontEndGenerated={controllers[index].isFrontEndGenerated}
        projectInfo={projectInfo}
        s_g_project_Id={s_g_project_Id}
        activeTab={activeTab}
        fetchFields={JSON.parse(JSON.stringify(controllers[index].controllerFields))}
        dateSearchOptions={JSON.parse(JSON.stringify(controllers[index].controllerFields))}
        loginApiValue={login}
        defaultScreen={handleDefaultScreens}
        HandleDuplicateScreens={handleDuplicateScreens}
        doNotListInDuplicateScreenDropDown={doNotListInDuplicateScreenDropDown}
        Api={apiValue}
      />
    )
  }

  const closeBackGroundColorModal = async () => {
    setIsBackgroundModal(false);
  }

  const openBackGroundColorModal = async (values) => {
    setIsBackgroundModal(true);
  }

  // Get BG color popup
  const getBackgroundColorPopup = () => {
    return (
      <BackgroundColorModal
        openlogInUrlModal={isBackgroundColorModal}
        closeInputModal={closeBackGroundColorModal}
        output={backgroundOutput}
        projectBg={props.projectBg}
      />
    )
  }

  //deleteConfirmModel
  const confirmationModal = () => {
    return (
      <DeleteConfirmMod
        index={deleteindex}
        selectModalShow={isdeleteControllerModal}
        name={deleteControllerName}
        closeInputModal={closeInputModal}
      />
    )
  }

  const [isOpenNewModal, onSetOpenNewModal] = useState(false);
  const [formFieldsArr, onSetFormFieldsArr] = useState(null);
  const [isOpenNewModals, onSetOpenNewModals] = useState(false);
  const [selectedControllerIndex1, onSetSelContIndex1] = useState(null);
  const [targetE, onSetTargetE] = useState(null);
  const [formFieldArrEdit, setFormFieldsArrEdit] = useState(null);
  const [formFields, onUpdateFormFields] = useState(null);

  const closeGroupByModal = () => {
    onSetOpenNewModal(false);
  }

  const closeGroupByModals = () => {
    onSetOpenNewModals(false);
  }

  // On set new modal fields
  const setNewModalFields = async (values) => {
    let controllers_copy = controllers;
    if (values.isEdit === "true" || values.isEdit) {
      let arr = controllers[values.actionsIndex].newModels;
      let index = arr.findIndex(x => x.newModelName == values.modelName);
      if (index > -1) {
        arr[index].newModelName = values.NewModelName;
        arr[index].newModelValues = values.actions;

        arr[index].newactions = values.newactions;
        arr[index].NewModelName = values.NewModelName;
      }
      controllers[values.actionsIndex].newModels = arr;

      let arr1 = controllers[values.actionsIndex].newModelNames;
      let index1 = arr1.findIndex(x => x.label == values.modelName);
      if (index1 > -1) {
        arr1[index1].label = values.NewModelName;
        arr1[index1].value = values.NewModelName;
      }
      controllers[values.actionsIndex].newModelNames = arr1;
    } else {
      if (controllers[values.actionsIndex].newModels && controllers[values.actionsIndex].newModels.length > 0) {
        controllers[values.actionsIndex].newModels = controllers[values.actionsIndex].newModels;
      } else {
        controllers[values.actionsIndex].newModels = [];
        controllers[values.actionsIndex]["newModelNames"] = [];
        controllers[values.actionsIndex]["newactions"] = [];
      }
      controllers[values.actionsIndex].newModels.push({ newModelName: values.NewModelName, newModelValues: values.actions });
      controllers[values.actionsIndex]["newModelNames"].push({ label: values.NewModelName, value: values.NewModelName });
      controllers[values.actionsIndex]["newactions"].push(values.newactions);
    }
    updateControllers(controllers);
    if (values.isEdit === "true" || values.isEdit) {
      onSetOpenNewModals(false);
    } else {
      onSetOpenNewModal(false);
    }
  }


  const onActionsChange = (e, selectedControllerIndex1) => {
    let formFieldArr = [];
    let controllerTotalfields = controllers[selectedControllerIndex];
    let formFields = controllerTotalfields.controllerFields;
    for (let formField of formFields) {
      let obj = { value: formField.name, label: formField.name };
      formFieldArr.push(obj);
    }

    onSetOpenNewModals(true);
    onSetSelContIndex1(selectedControllerIndex1);
    onSetTargetE(e.target.value);
    setFormFieldsArrEdit(formFieldArr);
  }

  const addNewModal = async () => {
    let formFieldArr = [];
    let controllerTotalfields = controllers[selectedControllerIndex];
    let formFields = controllerTotalfields.controllerFields.filter(item => item.displayineditForm === true);
    for (let formField of formFields) {
      if (!doNotListInFieldsDropDown.includes(formField.name)) {
        let obj = { value: formField.name, label: formField.name };
        formFieldArr.push(obj);
      }
      onSetOpenNewModal(true);
      onSetFormFieldsArr(formFieldArr);
    }
  }

  // Open new modal popup
  const openNewModal = (index) => {
    return (
      <NewModal
        isOpenAddByModal={isOpenNewModal}
        formFieldsArr={formFieldsArr}
        closeInputModal={closeGroupByModal}
        loginApiValue={setNewModalFields}
        controllers={controllers}
        index={index}
      />
    )
  }

  // New Edit Modal
  const openNewEditModal = () => {
    return (
      <NewModal
        isOpenAddByModal={isOpenNewModals}
        formFieldsArr={formFieldArrEdit}
        closeInputModal={closeGroupByModals}
        loginApiValue={setNewModalFields}
        isEdit={true}
        modelName={targetE}
        controllers={controllers}
        index={selectedControllerIndex1}
        deleteNewActionFromController={deleteNewActionFromController}
        OpenDeleteNewActionModal={OpenDeleteNewActionModal}
      />
    )
  }

  const deleteNewActionFromController = async (type) => {
    let contIndex = selectedControllerIndex1;
    let toDelete = targetE;
    let controllerss = controllers;
    let cont = controllerss[contIndex];
    if (type == 'no') {
      setDisplayNewActionDeleteModal(false);
    }
    if (type == 'yes') {
      let toDeleteIndex = cont.newModelNames.findIndex(obj => toDelete == obj.label)
      controllerss[contIndex].newModelNames.splice(toDeleteIndex, 1)
      controllerss[contIndex].newModels.splice(toDeleteIndex, 1)
      updateControllers(controllerss);
      setDisplayNewActionDeleteModal(false);
    }
  }

  const getSessionExpiryModal = () => {
    return (
      <SessionExpiryModal
        SOpen={sessionExpiryModal}
      />
    )
  }

  const getDeleteNewActionModal = () => {
    return (
      <DeleteNewActionModal
        displayNewActionDeleteModal={displayNewActionDeleteModal}
        deleteNewActionFromController={deleteNewActionFromController}
      />
    )
  }

  const getAddModalSample = () => {
    return (
      <AddModalSample
        isOpenAddByModal={isOpenAddByModal}
        formFields={formFields}
        closeGroupByModal={closeAddModalSampleModal} // function
        controllerFieldsFunc={controllerFieldsFunc} // function
      />
    )
  }

  const closeAddCreateUser = async () => {
    setOpenCreateUserModal(false);
    setOpenEditUserModal(false);
  }

  const setNewUserDetails = async (values) => {
    let newUIDetails_ref = newUIDetails;
    newUIDetails_ref[activeTab]["userDetails"] = {}
    newUIDetails_ref[activeTab]["userDetails"]["email"] = values.email;
    newUIDetails_ref[activeTab]["userDetails"]["password"] = values.password;
    newUIDetails_ref[activeTab]["userDetails"]["name"] = values.name;

    let details={
      email: values.email,
      name: values.name,
      password: values.password
    }

    let _controllers = controllers;
    _controllers[selectedControllerIndex]['userDetails'] = details;

    setNewUIDet(newUIDetails_ref);
    updateControllers(_controllers);
  }

  const getAddCreateUserModal = () => {
    return (
      <AddCreateUserModal
        openCreateUserModal={openCreateUserModal}
        index={selectedControllerIndex}
        details={openEditUserModal ? controllers[selectedControllerIndex].userDetails : null}
        isEdit={openEditUserModal}
        name={controllers[selectedControllerIndex] && controllers[selectedControllerIndex].name ? controllers[selectedControllerIndex].name : "User"}
        closeAddCreateUser={closeAddCreateUser}
        setNewUserDetails={setNewUserDetails}
        activeTab={activeTab}
        newUIDetails={newUIDetails[activeTab]}
      />
    )
  }

  const addCreateUser = async (value) => {
    if (value == "Edit") {
      setOpenEditUserModal(true);
    }
    setOpenCreateUserModal(true);
  }

  // Add Modal
  const addModal = async () => {
    const selControllerIndex = selectedControllerIndex;
    let controllerTotalfields = controllers[selControllerIndex];
    let formFields = [];
    for (let j = 0; j < controllerTotalfields.controllerFields.length; j++) {
      if (controllerTotalfields.controllerFields[j].name) {
        formFields.push(controllerTotalfields.controllerFields[j]);
      }
    }
    setisOpenAddByModal(true);
    onUpdateFormFields(formFields);
  }

  const closeAddNewTabModal = async (name, value) => {
    setOpenAddNewTabModal(value);
    setOpenEditNewTabModal(value);
  }

  const setNewUiDetails = async (value, type) => {
    let newUIDetails_ref = newUIDetails;
    type == "Add" ? newUIDetails_ref[value.newUIName] = {} : null;
    newUIDetails_ref[value.newUIName]["newUIName"] = value["newUIName"];
    newUIDetails_ref[value.newUIName]["selectedScreens"] = value["selectedScreens"];
    newUIDetails_ref[value.newUIName]["selectedAuthScreen"] = value["selectedAuthScreen"];
    newUIDetails_ref[value.newUIName]["settings"] = {
      "logo": "default_project_logo.png",
      "fav": "default_fav_icon.png",
      "selectedBackground": null,
      "projectBg": null,
      "projectFile": "default_project_logo.png",
      "favicon": "default_fav_icon.png",
      "bg": null,
      // "backgroungImage": null,
      "color": "rgb(60, 97, 119)",
      "textcolor": "rgb(255, 255, 255)",
      "bgColor": null,
      "displayProjectLogoOrName": false,
      "isGoogleLoginRequired": false,
      "googleClientID": null,
      "isGoogleRecaptchaRequired": false,
      "googleRecaptchaSiteKey": null,
      "loginSideImage": "default_LoginSideImage.webp",
      "selectedLoginTemplate": '1',
      "navColour": '#ffffff',
      "navTextColour": '#3c6177',
      "paginationPosition": 'top',
      "selectedFrontEnd": "React",
      "selectedBackEnd": "Node",
      "fontType": 'inherit',
      "buttonType": 'regular',
      "buttonRounded": false,
      "buttonColour": '#3c6177',
      "buttonTextColour": '#ffffff',
    }
    let menus = await createMenu(value["selectedScreens"], controllers);
    newUIDetails_ref[value.newUIName]["menuList"] = menus.menuList;
    newUIDetails_ref[value.newUIName]["menuListObj"] = menus.menuListObj;
    setNewUIDet(newUIDetails_ref);
  }

  const createMenu = async (selectedScreens, controllers) => {
    let menuListObj = {};
    let menuList = [];
    for (let i in controllers) {
      if (selectedScreens.includes(controllers[i].name)) {
        let defaultMenuIndex = defaultMenuList.findIndex((obj) => obj.name === controllers[i].name)
        let menuItem = {};
        if (defaultMenuIndex >= 0) {
          menuItem = defaultMenuList[defaultMenuIndex];
          menuItem.id = i + 1;
        } else {
          menuItem.name = (controllers[i].name) ? controllers[i].name.charAt(0).toUpperCase() + controllers[i].name.slice(1) : `Page ${i}`;
          menuItem.controllerId = (controllers[i].controllerId) ? controllers[i].controllerId : i;
          menuItem.id = i + 1;
          menuItem.displayTitle = menuItem.name.charAt(0).toUpperCase() + menuItem.name.slice(1);
          menuItem.disableDelete = controllers[i].disableDelete;
          menuItem.route = '/' + menuItem.name;
          menuItem.iconType = 'letters';
          menuItem.iconLetters = controllers[i].name ? (controllers[i].name).substring(0, 2) : '';
          menuItem.iconName = null;
        }
        menuListObj[menuItem.id] = { ...menuItem };
        menuList.push(menuItem);
      }
    };
    return { menuList: menuList, menuListObj: menuListObj }
  }

  const getAddNewTabModal = (e) => {
    return (
      <AddNewTabModal
        openAddNewTabModal={openAddNewTabModal}
        closetheModal={closeAddNewTabModal}
        multiTabsData={multiTabsData}
        type={openEditNewTabModal ? "Edit" : "Add"}
        openEditNewTabModal={openEditNewTabModal}
        multipleList={multipleList}
        controllersJson={JSON.parse(JSON.stringify(controllers))}
        controllers={controllers}
        doNotListInDropDown={doNotListInDuplicateScreenDropDown}
        newUIDetails={newUIDetails}
        activeTab={activeTab}
        setNewUiDetails={setNewUiDetails}
        UITabIndex={UITabIndex}
      />
    )
  }

  const toggle = (tab, index) => {

    let selectedAuthScreen = null;
    if (newUIDetails[tab] && newUIDetails[tab].selectedAuthScreen) {
      selectedAuthScreen = newUIDetails[tab].selectedAuthScreen
    } else {
      selectedAuthScreen = newUIDetails[multiTabsData[0].name] && newUIDetails[multiTabsData[0].name].selectedAuthScreen ?
        newUIDetails[multiTabsData[0].name].selectedAuthScreen : "Admin";
    }
    if (!newUIDetails[tab] && multiTabsData[0].name) tab = multiTabsData[0].name

    // selectedAuthScreen
    let i = controllers.findIndex(val => val.controllerId === selectedAuthScreen);
    if (i == -1) {
      i = 0
    }

    let bgImage, favicon, logo;
    if (newUIDetails[tab]["settings"]["fav"] == "default_fav_icon.png") {
      favicon = "default_fav_icon.png";
    } else if (newUIDetails[tab]["settings"]["fav"]) {
      let x = newUIDetails[tab]["settings"]["fav"];
      let y = x.split("_");
      y.splice(0, 1);
      favicon = y.join("_");
    }
    if (newUIDetails[tab]["settings"]["bg"]) {
      let x1 = newUIDetails[tab]["settings"]["bg"]
      let y1 = x1.split("_")
      y1.splice(0, 1)
      bgImage = y1.join("_")
    }
    if (newUIDetails[tab]["settings"]["logo"] === "default_project_logo.png") {
      logo = "default_project_logo.png"
    } else if (newUIDetails[tab]["settings"]["logo"]) {
      let x2 = newUIDetails[tab]["settings"]["logo"]
      let y2 = x2.split("_")
      y2.splice(0, 1)
      logo = y2.join("_")
    }
    if (activeTab !== tab) {
      onSetActiveTab(tab);
      setUITabIndex(index);
      setSelControllerInd(i);
      setDisplayPreModal(true);
      setShowHideLoginScreen(false);
      setShowHideForgotScreen(false);
      setSelFilterIndex(0);
      setSelFormInd(0);
      setProjectLogo(newUIDetails[tab]["settings"]["logo"]);
      setProjectFav(newUIDetails[tab]["settings"]["fav"]);
      setSelectedBg(newUIDetails[tab]["settings"]["selectedBackground"]);
      setProjectBg(newUIDetails[tab]["settings"]["bg"]);
      onSetProjectFile(logo);
      onSetFavIcon(favicon);
      onSetBgImage(bgImage);
      setSelLoginTemplate();
      updateTextColor(newUIDetails[tab]["settings"].textcolor);
      updateColor(newUIDetails[tab]["settings"].color);
      updateBgColor(newUIDetails[tab]["settings"].bgColor);
      setDisplayProjLogoName(newUIDetails[tab]["settings"].displayProjectLogoOrName);
      setIsGoogleLoginReq(newUIDetails[tab]["settings"].isGoogleLoginRequired);
      setGoogleClientID(newUIDetails[tab]["settings"].googleClientID);
      // setIsGoogleCaptchaReqnewUIDetails[tab]["settings"].isGoogleRecaptchaRequired();
      setGoogleCaptchaKey(newUIDetails[tab]["settings"].googleRecaptchaSiteKey);
      setLoginSideImage(newUIDetails[tab]["settings"].loginSideImage);
    }
  };


  const setHeaderSection = () => {
    return (
      <>
        <div className='d-flex justify-content-between align-items-center h-100'
          style={{ float: "left", color: textcolor, backgroundColor: newUIDetails[activeTab]["settings"]["navColour"] }}>
          <div style={{ display: "inline-flex", float: "left" }}>
            <div className="p-0 align-self-center ms-3 mt-2">
              <span> {hideSidebarIcon()}</span>
            </div>
            {displayProjectLogoOrName ?

              <img className='ms-2 settings_img_sample p-1' src={`${config.logoUrl}${projectLogo}`} />
              : <b className="topbarLogo ms-3 font-weight-bolder"
                style={{ font: 24, color: newUIDetails[activeTab]["settings"]['navTextColour'], 
                  fontFamily: newUIDetails[activeTab]["settings"]['fontType'] }}>
                {projectNameValue}
                {/* {props.projectData.name} */}
              </b>
            }
          </div>
          <div style={{ display: "inline-flex", float: "right", paddingRight: '20px' }}>
            <div className="p-0">
              <span>{hideSidebarIcon('right')}</span>
            </div>
            <p className="mt-0 ms-2 me-2" style={{ color: newUIDetails[activeTab]["settings"]['navTextColour'] }}>John</p>
          </div>
        </div>
      </>
    )
  }

  // Table Header Template
  const tableHeaderPreview = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-nowrap justify-content-between">
          <h4 style={{ textTransform: 'capitalize', float: "left", color: color, fontWeight: "bold" }}>
            {controllers && controllers.length && controllers[selectedControllerIndex].name
              ? controllers[selectedControllerIndex].name : `Controller ${selectedControllerIndex + 1}`}
          </h4>

          <div className='dummyActions d-flex align-items-center'>
            <Dropdown disabled placeholder="Filter" className="w-10rem me-2" />
            <Dropdown
              name='actions'
              disabled={controllers && selectedControllerIndex >= 0 && controllers[selectedControllerIndex] && controllers[selectedControllerIndex].newModelNames ? false : true}
              value={controllers && selectedControllerIndex >= 0 && controllers[selectedControllerIndex] && controllers[selectedControllerIndex].newModelNames ? controllers[selectedControllerIndex].newModelNames : []}
              options={controllers && selectedControllerIndex >= 0 && controllers[selectedControllerIndex] && controllers[selectedControllerIndex].newModelNames ? controllers[selectedControllerIndex].newModelNames : []}
              placeholder="Actions"
              className="w-10rem me-2"
              onChange={(e) => onActionsChange(e, selectedControllerIndex)}
            />

            {(controllers && controllers.length && controllers[selectedControllerIndex].actions) &&
              <>
                {controllers[selectedControllerIndex].actions.map((item, index) => (
                  <>
                    {(item === 'add' || item === 'BulkUpload' || item === 'exportToCsv') &&
                      <>
                        <div className='me-1'>
                          {(item === "add") &&
                            <>
                              <div className="sample-actions" style={{ borderWidth: 'thin', borderStyle: 'solid', borderColor: newUIDetails[activeTab]["settings"]['buttonColour'], borderRadius: (newUIDetails[activeTab]["settings"]['buttonRounded'] ? '20px' : '4px'), backgroundColor: (newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonColour'] : 'transparent') }}>
                                <FontAwesomeIcon
                                  style={{ fontSize: 15, paddingTop: 2 }}
                                  color={newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonTextColour'] : newUIDetails[activeTab]["settings"]['buttonColour']}
                                  icon={faPlus}
                                  data-toggle="tool-tip"
                                  title="Add"
                                />
                              </div>
                            </>
                          }
                          {(item === "BulkUpload") &&
                            <>
                              <div className="sample-actions"
                                style={{ borderWidth: 'thin', borderStyle: 'solid', borderColor: newUIDetails[activeTab]["settings"]['buttonColour'], borderRadius: (newUIDetails[activeTab]["settings"]['buttonRounded'] ? '20px' : '4px'), backgroundColor: (newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonColour'] : 'transparent') }}>
                                <FontAwesomeIcon
                                  style={{ fontSize: 15, paddingTop: 2 }}
                                  color={newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonTextColour'] : newUIDetails[activeTab]["settings"]['buttonColour']}
                                  icon={faUpload}
                                  data-toggle="tool-tip"
                                  title="Upload"
                                />
                              </div>
                            </>
                          }
                          {(item === "exportToCsv") &&
                            <>
                              <div className="sample-actions" style={{ borderWidth: 'thin', borderStyle: 'solid', borderColor: newUIDetails[activeTab]["settings"]['buttonColour'], borderRadius: (newUIDetails[activeTab]["settings"]['buttonRounded'] ? '20px' : '4px'), backgroundColor: (newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonColour'] : 'transparent') }}>
                                <FontAwesomeIcon
                                  style={{ fontSize: 15, paddingTop: 2 }}
                                  color={newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonTextColour'] : newUIDetails[activeTab]["settings"]['buttonColour']}
                                  icon={faFileExport}
                                  data-toggle="tool-tip"
                                  title="Export To Csv"
                                />
                              </div>
                            </>
                          }
                        </div>
                      </>
                    }
                  </>
                ))
                }
                <div className="sample-actions me-1"
                  style={{
                    borderWidth: 'thin', borderStyle: 'solid',
                    borderColor: newUIDetails[activeTab]["settings"]['buttonColour'],
                    borderRadius: (newUIDetails[activeTab]["settings"]['buttonRounded'] ? '20px' : '4px'),
                    backgroundColor: (newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonColour'] : 'transparent')
                  }}
                  onClick={() => addModal()}>
                  <FontAwesomeIcon
                    color={newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonTextColour'] : newUIDetails[activeTab]["settings"]['buttonColour']}
                    icon={faCog}
                    style={{ fontSize: 14, paddingTop: 2 }}
                    data-toggle="tool-tip"
                    title="Settings" />
                </div>

                <div className="sample-actions"
                  style={{ borderRadius: (newUIDetails[activeTab]["settings"]['buttonRounded'] ? '20px' : '4px'), borderBottomRightRadius: 0, borderTopRightRadius: 0, borderWidth: 'thin', borderStyle: 'solid', borderColor: newUIDetails[activeTab]["settings"]['buttonColour'], backgroundColor: (newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonColour'] : 'transparent') }}>
                  <FontAwesomeIcon
                    color={newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonTextColour'] : newUIDetails[activeTab]["settings"]['buttonColour']}
                    icon={faGripHorizontal}
                    style={{ fontSize: 15, paddingTop: 2 }}
                    data-toggle="tool-tip"
                    title="Grid view"
                  />
                </div>
                <div className="sample-actions me-1"
                  style={{ borderRadius: (newUIDetails[activeTab]["settings"]['buttonRounded'] ? '20px' : '4px'), borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderWidth: 'thin', borderStyle: 'solid', borderColor: newUIDetails[activeTab]["settings"]['buttonColour'], backgroundColor: (newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonColour'] : 'transparent') }}>
                  <FontAwesomeIcon
                    style={{ fontSize: 15, paddingTop: 2 }}
                    color={newUIDetails[activeTab]["settings"]['buttonType'] === 'regular' ? newUIDetails[activeTab]["settings"]['buttonTextColour'] : newUIDetails[activeTab]["settings"]['buttonColour']}
                    icon={faList}
                    data-toggle="tool-tip"
                    title="List view"
                  />
                </div>

                <div className="sample-actions me-2" style={{ borderColor: '#f2f2f2', backgroundColor: '#f2f2f2', }}>
                  <FontAwesomeIcon
                    icon='sync-alt'
                    style={{ fontSize: 14, paddingTop: 5 }}
                    color='grey'
                    data-toggle="tool-tip" title="Refresh"
                  />
                </div>

                {globalFilterHeader()}
              </>
            }
          </div>
        </div>
      </>
    )
  }

  const [first] = useState([0, 0, 0]);
  const [rows] = useState([10, 10, 10]);

  const template2 = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 100, value: 100 }
      ];

      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none', fontFamily: newUIDetails[activeTab]["settings"]['fontType'] }}>
            Items per page:{' '}
          </span>
          <Dropdown className="custom-page-dropdown" value={rowsPerPage} options={dropdownOptions} onChange={(e) => setRowsPerPage(e.value)} />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    }
  };

  const paginationRender = () => {
    return (
      <div className="d-flex align-items-center flex-nowrap justify-content-end">
        <Paginator template={template2} first={first[1]} rows={rows[1]}
          totalRecords={10} style={{ fontFamily: newUIDetails[activeTab]["settings"]['fontType']}}/>
      </div>
    )
  }

  const globalFilterHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText placeholder="Global Search" />
        </span>
      </div>
    );
  }

  const calcNumberOfUis = () => {
    let arr = multiTabsData;
    if (arr.length < 4) {
      return false
    }
    else {
      return true
    }
  }

  return (
    <>
      <Row className="position-relative" style={{ fontFamily: newUIDetails[activeTab]["settings"]['fontType'] }}>
        <Button color='custom setting-draw' onClick={() => setShowSettings(true)}>
          <FontAwesomeIcon icon={faCog} />
        </Button>
        <div className="card" style={{ display: `${showSettings ? 'block' : 'none'}` }}>
          {executeSidebar()}
        </div>
        <Col sm={12}>
          <div className="tabs tabs--bordered-top" style={{paddingRight:"12px"}}>
            <div className="tabs__wrap">
              <Nav tabs>
                {multiTabsData && multiTabsData.map((item, index) => {
                  return (
                    <>
                      <NavItem key={item.tabId}>
                        <NavLink style={{ textTransform: 'capitalize' }}
                          className={classnames({ active: activeTab === item.name })}
                          onClick={() => { toggle(item.name, index); }}>
                          {(item.name && item.name !== "Admin") ?
                            <>
                              <div className="edit_icon d-flex align-self-center">
                                {item.name}
                                <FontAwesomeIcon
                                  className='ms-1 mt-1'
                                  style={{ fontSize: 14, padding: 1 }}
                                  color={"blue"}
                                  icon={faPencilAlt}
                                  data-toggle="tool-tip"
                                  title="Edit"
                                  onClick={() => { setOpenAddNewTabModal(true); setOpenEditNewTabModal(true) }}
                                />
                                <FontAwesomeIcon
                                  className='editIcon ms-1'
                                  color={'red'}
                                  icon={faTimes}
                                  style={{ fontSize: 14 }}
                                  data-toggle="tool-tip"
                                  title="Delete this UI"
                                  onClick={() => { setDisplayUIModal(true); onSetRemoveIndex(index) }}
                                />
                              </div>
                            </>
                            : <span>{item.name}</span>}
                        </NavLink>
                      </NavItem>
                    </>
                  )
                })}
                <Button className='ms-auto mb-2  btn btn-success' disabled={calcNumberOfUis()}
                  size={'sm'} color='primary' onClick={() => { setOpenAddNewTabModal(true) }}>Add New UI
                </Button>
              </Nav>
              <TabContent activeTab={activeTab}>
                {multiTabsData && multiTabsData.map((item, index) =>
                  <>
                    <TabPane tabId={item.name} className='pt-0'>
                      {(showControllers && loginCheck && uploadCheck && favIconState) &&
                        <>
                          {tabTwoActive &&
                            <>
                              <div className="d-flex">
                                <Card style={{ width: "100%" }}>
                                  <Card className="row" style={{ width: "100%", height: "65px", marginLeft: "0px", borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                                    {setHeaderSection()}
                                  </Card>
                                  <div className="sidebar preview-page-height row">
                                    {/* <div className="d-flex flex-nowrap w-full h-100"> */}
                                      <div className="sidebar_left-content col-3" style={{ width: "17.5%", height:"100%" }}>
                                        <ScrollPanel style={{ width: '100%', height: '100%', borderRight: "1px solid grey", backgroundColor: color }} className="custombar2">
                                          <div className='side-menu' style={{fontFamily: newUIDetails[activeTab]["settings"]['fontType']}}>
                                            {(props && !props.yesJsonUploaded && activeTab && newUIDetails && controllers && controllers.length) &&
                                              <>
                                                {renderSideMenuBar()}
                                              </>
                                            }
                                            {renderSideMenuRestScreens()}
                                          </div>
                                        </ScrollPanel>
                                      </div>
                                <div className='main_right-content col-9' style={{ width: "82.5%", height: "100%", paddingRight: "20px", paddingLeft: "0px" }}>
                                        <div className='d-flex align-items-center justify-content-end py-2'>
                                          {(controllers && controllers.length > 0 && controllers[selectedControllerIndex] && activeTab == "Admin") &&
                                            <>
                                              {renderCodeEditorBtn()}
                                            </>
                                          }
                                          {(controllers && controllers.length > 0 && controllers[selectedControllerIndex] && !controllers[selectedControllerIndex]["ParentcontrollerId"] && activeTab == "Admin") &&
                                            <>
                                              <Button style={{ marginBottom: 0 }} size={'sm'} color='custom' onClick={(e) => getFieldModal('add', e)}>
                                                Add Field
                                              </Button>
                                            </>
                                          }
                                          {(controllers && controllers.length > 0 && controllers[selectedControllerIndex] && !controllers[selectedControllerIndex]["ParentcontrollerId"] && activeTab == "Admin") &&
                                            <>
                                              <Button style={{ marginBottom: 0 }} size={'sm'} color='custom' onClick={() => addNewModal()}>
                                                New Action
                                              </Button>
                                            </>
                                          }
                                          {((api >= 0 && selectedControllerIndex === api) || (controllers[selectedControllerIndex] &&
                                            controllers[selectedControllerIndex].controllerId && newUIDetails[activeTab] && newUIDetails[activeTab].selectedAuthScreen
                                            && newUIDetails[activeTab].selectedAuthScreen === controllers[selectedControllerIndex].controllerId)) &&
                                            <>
                                              <Button style={{ marginBottom: 0 }} size={'sm'} color='custom'
                                                onClick={() => controllers[selectedControllerIndex]["userDetails"] && controllers[selectedControllerIndex]["userDetails"]["email"] ? addCreateUser("Edit") : addCreateUser("New")}
                                              >
                                                {newUIDetails[activeTab]["userDetails"] && newUIDetails[activeTab]["userDetails"]["email"] ? `Edit ${newUIDetails[activeTab]["newUIName"]}` : `Add ${newUIDetails[activeTab]["newUIName"]}`}
                                              </Button>
                                            </>
                                          }
                                        </div>
                                        <div className='preview-card'>
                                          {/* style={{ padding: 10,  boxShadow: "2px 0px 5px 2px #888888",borderRadius:'0px' }} */}
                                          <Card className="p-2">
                                            {(displayPreviewModal) &&
                                              <>
                                                {tableHeaderPreview()}
                                                {(newUIDetails && newUIDetails[activeTab]["settings"]['paginationPosition'] === 'top') &&
                                                  <>
                                                    {paginationRender()}
                                                  </>
                                                }
                                                {renderDataTable()}
                                                {(newUIDetails && newUIDetails[activeTab]["settings"]['paginationPosition'] === 'bottom') &&
                                                  <>
                                                    {paginationRender()}
                                                  </>
                                                }

                                              </>
                                            }
                                            {(showLoginScreen) &&
                                              <>
                                                {renderLoginTemplate()}
                                              </>
                                            }
                                            {(showForgotScreen) &&
                                              <>
                                                {renderForgotTemplate()}
                                              </>
                                            }

                                            {(showRegisterScreen) &&
                                              <>
                                                {renderRegisterTemplate()}
                                              </>
                                            }
                                            {displayUIModal &&
                                              <>
                                                {handleDeleteUI()}
                                              </>
                                            }
                                          </Card>
                                        </div>
                                      </div>
                                    {/* </div> */}
                                  </div>
                                </Card>
                              </div>
                            </>
                          }

                        </>
                      }
                    </TabPane>
                  </>
                )}
              </TabContent>
            </div>
          </div>
        </Col>

        {displayFieldModal && handleDeleteField()}
        {displayNewActionDeleteModal && getDeleteNewActionModal()}
        {isOpenInputModel && getInputModal()}
        {isActionModal && getloginApiPopup(selectedControllerIndex)}
        {isBackgroundColorModal && getBackgroundColorPopup()}
        {isdeleteControllerModal && confirmationModal()}
        {isOpenNewModal && openNewModal(selectedControllerIndex)}
        {isOpenNewModals && openNewEditModal()}
        {sessionExpiryModal && getSessionExpiryModal()}
        {isOpenAddByModal && getAddModalSample()}
        {openCreateUserModal && getAddCreateUserModal()}
        {openAddNewTabModal && getAddNewTabModal()}
      </Row>
    </>
  )
})

export default NewProject;