
import React, { useState, useEffect, useRef } from 'react';
// import classNames from 'classnames';
import { classNames } from 'primereact/utils';
import {
    Button, Modal, ModalHeader, ModalBody, Row, Col,
    Card, CardBody, ButtonToolbar, ButtonGroup, Popover, PopoverHeader, PopoverBody
} from 'reactstrap';

// import { connect } from "react-redux";


import { load as loadAccount } from "../../redux/reducers/commonReducer";

// import DefaultInput from "../../shared/components/form/DefaultInput";
// import Select from "../../shared/components/form/Select";
// import renderRadioButtonField from "../../shared/components/form/RadioButton"
// import TextAutocomplete from '../../shared/components/form/TextAutocomplete'
// import Checkbox from "../../shared/components/form/CheckBox";
// import { MultiSelect } from 'primereact/multiselect';
// import { InputText } from 'primereact/inputtext';

import validate from '../Form/validate';

import configMessages from '../../config/configMessages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faQuestion } from '@fortawesome/free-solid-svg-icons';
// import Switch from '@material-ui/core/Switch'; will use primeng switch


import { FieldTypes, defaultCheckBox, fileTypeField } from '../../config/commonData';
import { Badgecolors } from '../../config/commonData';
import { DateFormats } from '../../config/commonData';
import { TableOptions } from '../../config/commonData';
import { basicFormFields } from '../../config/commonData';
import { basicAutocompleteFields } from '../../config/commonData';
import { Methods } from '../../config/commonData';
import { normalFields, radioFields, passwordFields } from '../../config/commonData';
import { checkBoxFields } from '../../config/commonData';

// hook forms
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from 'primereact/inputswitch';
import { AutoComplete } from 'primereact/autocomplete';


import ValidationModal from "./validationModal";
import { actionTypes } from 'redux-form';
import { Dialog } from 'primereact/dialog';

const FieldForm = (props) => {
    const [fieldTypes] = useState(FieldTypes);
    const [badgecolors] = useState(Badgecolors);
    const [keyCount] = useState(0);
    const [showregisterFieldType] = useState(false);
    const [dateFormatOptions] = useState(DateFormats);
    const [tableoptions] = useState(TableOptions);
    const [formFields] = useState(basicFormFields);
    const [autocompleteFields] = useState(basicAutocompleteFields);
    const [methods] = useState(Methods);
    // const [options] = useState([]);
    const [players] = useState([]);
    const [productArr] = useState([]);
    const [values] = useState([]);

    const [stringDetails] = useState(false);
    const [numberDetails] = useState(false);
    const [searchFieldvalues, setSearchFieldValues] = useState([]);

    const [actionsTypes, setActionType] = useState([]);
    const [actionsTypeNumber, setActionTypeNumber] = useState([]);
    const [actionsNumber, setActionNumber] = useState([]);
    const [actions, setActionsEdit] = useState([]);
    const [dependentOptions, setDependentOptions] = useState([]);
    const [dependentField] = useState(null);
    const [errorDisplay, chErrorDisplay] = useState(false);
    const [addToSettings] = useState(false);
    const [capitalizeTableText] = useState(false);
    const [sortable] = useState(false);
    const [displayOptionsInActions] = useState(false);
    const [fieldTypePopover] = useState(false);
    const [displayInAddPopover] = useState(false);
    const [displayInEditPopover] = useState(false);
    const [displayInListPopover] = useState(false);
    const [globalSearchPopover] = useState(false);
    const [isFieldRequiredPopover] = useState(false);
    const [capitalizeTableTextPopover] = useState(false);
    const [registerPopover] = useState(false);
    const [dateFormatPopover] = useState(false);
    const [selectControllerPopover] = useState(false);
    const [searchFieldPopover] = useState(false);
    const [derivedFieldPopover] = useState(false);
    const [profileFieldPopover] = useState(false);
    const [addFormOrderPopover] = useState(false);
    const [editFormOrderPopover] = useState(false);
    const [sortablePopover] = useState(false);
    const [filterPopover] = useState(false);
    const [displayFieldsPopover] = useState(false);
    const [populteFieldsPopover] = useState(false);
    const [fieldNamePopover] = useState(false);
    const [labelPopover] = useState(false);
    const [placeholderPopover] = useState(false);
    const [valuePopover] = useState(false);
    const [widthPopover] = useState(false);
    const [populteFields, setPopulateFields] = useState([]);
    const [displayFields, setDisplayFields] = useState([]);
    const [displayOptionsInActionsIdPopover] = useState(false);
    const [isMultiplePopover] = useState(false);
    const [derivedFieldTypePopover] = useState(false);
    const [isMultiple, setIsMultiple] = useState(false);
    const [openValidationModal] = useState(false);
    const [validationRequired, setValidationReq] = useState(false);
    const [regexPattern, setRegexPattern] = useState(false);
    const [regexData, setRegexData] = useState({});
    const [previousSelectedPopover] = useState(null);
    const [visible, setVisible] = useState(false);

    const [baseFieldType, updateFieldType] = useState(null);
    const [stringType, updateStringType] = useState(null);
    const [derivedFieldType, updateDF] = useState(false);
    const [attributeListArray, updateAttrList] = useState([]);
    const [options, updateOptions] = useState([])
    const attrOptions1 = { label: '', value: '', color: '', dependentField: '', id: 'dpc' };
    const attrOptions2 = { label: '', value: '', id: 'msr' };

    const [showregisterFeildType, changeRegFieldType] = useState(false);
    const [controllerOptions, setControllerOptions] = useState(null);
    const [displayinForm, setDisplayForm] = useState(null);
    const [rowData, setRowData] = useState(null);
    const { type } = props;
    const [multipleList, setMultiList] = useState([]);
    const [isRenderingFields, setRenderFields] = useState(false);
    const [renderedFields, UpdateRenderedFields] = useState([]);
    const [autoRenderFields, setAutoRenFields] = useState([]);
    const [renderInEdit, setRenderInEdit] = useState(false);
    const [formData_Edit, setFormData_Edit] = useState({});
    const [showHasDependency, setShowHasDependency] = useState(false);
    const [hasDependencyFieldList, setHasDependencyFieldList] = useState([]);
    const [fieldForKeyList, setFieldForKeyList] = useState([]);
    const [derFieldType, setDerFieldType] = useState("");

    let defaultWidth = null;

    const defaultValues = {
        fieldType: '',
        profileField: false
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        register, trigger, setError, setValue, getValues
    } = useForm({ defaultValues });


    const { fields, append, remove } = useFieldArray({
        control,
        // name: "attributeListArray"
        name: "options"
    });

    const addFilterElement = () => {
        if (baseFieldType !== 'dropDown') {
            append(attrOptions2);
            // attributeListArray.push(attrOptions2);
        } else {
            append(attrOptions1);
            // attributeListArray.push(attrOptions1);
        }
        // updateAttrList(attributeListArray);
        updateOptions(options)
    }


    useEffect(() => {
        const { rowData: rowData_form } = props;
        let dependentOptions = [];
        if (props.dateSearchOptions && props.dateSearchOptions.length > 0) {
            for (let obj of props.dateSearchOptions) {
                obj.serverFieldType == "Date" ? searchInDateRangeFields.push({ label: obj.field, value: obj.field }) : null
            }
        }
        if (props.currentControllerFields && props.currentControllerFields.length > 0) {
            for (let obj of props.currentControllerFields) {
                (obj.name !== "created" && obj.name !== "updated" && obj.name !== "updatedBy" && obj.name !== "createdBy") ? dependentOptions.push({ label: obj.field, value: obj.field }) : null
            }
            setDependentOptions(dependentOptions);
        }
        if (rowData_form && props && props.type === "edit") {
            setRenderInEdit(true);

            //for autorelate-select controller
            let Controllers = props.controllers;
            let MultipleList_ref = [];
            Controllers.forEach((ele, index) => {
                if (ele && ele.name && ele.controllerId) {
                    // if (props.controllers[props.selectedControllerIndex].name != ele.name) {
                    MultipleList_ref.push({
                        value: ele.name, label: ele.name
                    });
                    // }
                }
            });
            setControllerOptions(MultipleList_ref);

            //derived field
            let formFieldArr = []
            let _formFields = props.controllers[props.controllerIndex].controllerFields;
            for (let formField of _formFields) {
                let obj = { value: formField.name, label: formField.name };
                if ((formField.type == "text" || formField.type == "textarea") && rowData_form.stringType == 'string') {
                    formFieldArr.push(obj);
                }
                // else {
                //     if(f_f.type == "number") {
                //         numberArr.push(obj)
                //     }
                // }
            }
            setActionType(formFieldArr);
            // setActionTypeNumber(numberArr);

            let controllers = [];
            let multipleList_ref = [];
            if (rowData_form.options && rowData_form.options.length > 0) {
                let AttributeList = []
                for (let i = 0; i < rowData_form.options.length; i++) {
                    let key = `label${i}`;
                    let value = `value${i}`;
                    let color = `color${i}`;
                    let dependentField = `dependentField${i}`;
                    AttributeList.push({
                        [key]: rowData_form.options[i].label, [value]: rowData_form.options[i].value, [color]: rowData_form.options[i].color, [dependentField]: rowData_form.options[i].dependentField,
                        label: `label${i}`, value: `value${i}`, color: `color${i}`, dependentField: `dependentField${i}`,
                    })
                    rowData_form[key] = rowData_form.options[i].label;
                    rowData_form[dependentField] = rowData_form.options[i].dependentField;
                    rowData_form[color] = rowData_form.options[i].color;
                    rowData_form[value] = rowData_form.options[i].value;
                }
                // updateAttrList(AttributeList);
                updateOptions(AttributeList);

            }
            //KEEP FOR LATER
            // rowData_form.name ? rowData_form.fieldName = rowData_form.name : null;
            // let formFieldArr = [], numberArr = [];
            // let _formFields = props.controllers[props.controllerIndex].controllerFields;
            // for (let formField of _formFields) {
            //     let obj = { value: formField.name, label: formField.name };
            //     if ((formField.type == "text" || formField.type == "textarea") && rowData_form.stringType == 'string') {
            //         formFieldArr.push(obj);
            //     } else if (formField.type == "number") {
            //         numberArr.push(obj);
            //     }
            // } 
            // setActionType(formFieldArr);
            // setActionTypeNumber(numberArr);
            controllers = props.controllers;
            let tempArray = [];
            for (let obj of controllers) {
                if (obj.name && obj.controllerId && controllers[props.selectedControllerIndex].name !== obj.name) {
                    multipleList_ref.push({
                        value: obj.controllerId, label: obj.name
                    })
                }
                if (obj.controllerId && rowData_form.controllerId && obj.controllerId === rowData_form.controllerId) {
                    rowData_form.controllerName = obj.name;
                    rowData_form.searchApi = obj.name ? obj.name.toLowerCase() + "s" : null;
                }
                if (obj.controllerId === rowData_form.controllerId) {
                    if (obj.controllerFields) {
                        for (let ob of obj.controllerFields) {
                            ob.type == "text" || ob.type == "email" ? tempArray.push({ label: ob.field, value: ob.field }) : null
                        }
                    }
                }
            }
            setSearchFieldValues(tempArray);
            let edit_field_type = (rowData_form.type == "dropDown" && rowData_form.isMultiSelect) ? "multiSelect" : rowData_form.type;
            setValue('fieldType', edit_field_type);
            updateFieldType(edit_field_type);
            onChangeFieldType(edit_field_type, { type: "patchForm", formValue: JSON.stringify(rowData_form) });
            updateStringType(rowData_form.stringType);
            // updateAttrList();
            setDisplayForm(rowData_form.displayinForm);
            setActionsEdit(rowData_form.actions);
            setPopulateFields(rowData_form.populteFields);
            setDisplayFields(rowData_form.displayFields);
            setRowData(rowData_form);
            setActionNumber(rowData_form.actionsNumber);
            setMultiList(multipleList_ref);
            setIsMultiple(rowData_form.isMultiple);
            setValidationReq(rowData_form.validationRequired);
            setRegexPattern(rowData_form.regexPattern);
            setRegexData(rowData_form.regexData);
            // appending form data  

            // setValue('');
        } else {
            let controllers = props.controllers;
            let multipleList_ref = [];
            controllers.forEach((ele, index) => {
                if (ele && ele.name && ele.controllerId) {
                    if (props.controllers[props.selectedControllerIndex].name != ele.name) {
                        multipleList_ref.push({
                            value: ele.name, label: ele.name
                        });
                    }
                }
            });
            setControllerOptions(multipleList_ref);
            let obj = {
                displayinlist: true,
                displayineditForm: true,
                displayinaddForm: true,
                width: "90px",
                isFieldRequired: false,
                globalSearchField: true,
                isAddToIndex: false,
                capitalizeTableText: false,
                sortable: false,
                filter: false,
                isBulkUploadField:true,
                displayOptionsInActions: false,
                addFormOrder: parseInt((props.currentControllerFields.length + 1)),
                editFormOrder: parseInt((props.currentControllerFields.length + 1))
            }
            if (props.controllers && props.controllers[props.controllerIndex]) {
                if (props.controllers[props.controllerIndex].isAuth) {
                    obj.profileField = false
                }
            }
            let formFieldArr = [], numberArr = [];
            let _formFields = props.controllers[props.controllerIndex].controllerFields;
            _formFields.forEach((f_f) => {
                let obj = { value: f_f.name, label: f_f.name };
                if ((f_f.type == "text" || f_f.type == "textarea")) {
                    formFieldArr.push(obj);
                } else {
                    if (f_f.type == "number") {
                        numberArr.push(obj)
                    }
                }
            });
            setActionType(formFieldArr);
            setActionTypeNumber(numberArr);

        }
    }, [props]);

    let closeInputModal = () => {
        props.closeInputModal()
    }

    const setRegexValue = async (regexPattern, regexData) => {
        setValidationReq(regexPattern ? true : false);
        setRegexPattern(regexPattern);
        setRegexData(regexData);
    }

    const getDPOptions = (fieldName) => {
        if (fieldName.name) {
            if (fieldName.name === "controllerName") {
                return controllerOptions;
            }
            if (fieldName.name === "searchField" || fieldName.name === "displayFields") {
                return searchFieldvalues;
            }
            if (fieldName.name === "hasDependencyField") {
                return hasDependencyFieldList;
            }
            if (fieldName.name === "fieldForKey") {
                return fieldForKeyList;
            }
        }
    }

    const getAutocompleteField = () => {
        if (autocompleteFields && autocompleteFields.length) {
            let allFlds;
            let dropFlds;
            let checkFlds;
            let radFlds;
            let multFlds;
            autocompleteFields.forEach((ele, index) => {
                if (ele && ele.type == "dropdown") {
                    dropFlds = <>{dropFlds}{getDropdownFields(ele, index)}</>
                }
                else if (ele && ele.type == "checkbox") {
                    checkFlds = <>{checkFlds}{getCheckBoxFields(ele, index)}</>
                }
                else if (ele && ele.type == "radio") {
                    radFlds = <>{radFlds}{getRadioFields(ele, index)}</>
                }
                else {
                    multFlds = <>{multFlds}{getMultiselectFields(ele, index)}</>
                }

                allFlds = <>{checkFlds}{radFlds}{dropFlds}{multFlds}</>
            });
            return allFlds;
        }
    }

    const getDropdownFields = (field, keyIndex) => {
        if (((field.name == "fieldForKey" || field.name == "hasDependencyField") && showHasDependency == true) || !(field.name == "fieldForKey" || field.name == "hasDependencyField")) {

            return (
                // { field && field.sh && field.sh==true ?
                <>
                    <div className='form-group col-md-6'>
                        <label className="form-label">{field.label}
                            {getTooltipIcon(field, keyIndex)}
                        </label>
                        <Controller
                            name={field.name}
                            rules={field.required && { required: `${field.label} is required` }}
                            control={control}
                            render={({ field, fieldState }) => (
                                <>
                                    <Dropdown
                                        id={field.name}
                                        value={field.value}
                                        optionLabel="label"
                                        // placeholder={placeholder}
                                        // options={getDPOptions(name)}
                                        options={getDPOptions(field)}
                                        focusInputRef={field.ref}
                                        onChange={(e) => { field.onChange(e.value), handledropdown(e.value, "drop", field.name) }}
                                        className={classNames('w-100')}
                                    />
                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                    </div>
                </>
                // : null}
            )
        }
    }

    const getMultiselectFields = (field, keyIndex) => {

        return (
            <>
                <div className='form-group col-md-6'>
                    <label className="form-label">{field.label}
                        {getTooltipIcon(field, keyIndex)}
                    </label>
                    <Controller
                        name={field.name}
                        control={control}
                        render={({ field, fieldState }) => (
                            <MultiSelect
                                id={field.name}
                                value={field.value}
                                optionLabel="label"
                                // placeholder={placeholder}
                                // options={getDPOptions(name)}
                                options={getDPOptions(field)}
                                focusInputRef={field.ref}
                                onChange={(e) => { field.onChange(e.value), handledropdown(e.value, "mult") }}
                                // onChange={(e) => { ()=>{field.onChange(e.value); if(field.name=="controllerName")handledropdown(e.value,"drop") }}}
                                className={classNames('w-100')}
                            />
                        )}
                    />
                </div>
            </>
        )
    }

    const handledropdown = async (value, type, fieldname) => {

        if (!value) { return };

        if (type == "drop" && fieldname == "controllerName") {
            handleFieldsForKey(value);
            let tempArray = [];
            if (props && props.controllers) {
                for (let obj of props.controllers) {
                    if (obj.name == value) {

                        if (obj.controllerFields) {
                            for (let innerObj of obj.controllerFields) {
                                innerObj.type == "text" || innerObj.type == "email" ? tempArray.push({ label: innerObj.field, value: innerObj.field }) : null
                            }
                        }
                    }
                }
                await setSearchFieldValues(tempArray);
            }
        }
        if (type == "mult" && fieldname == "controllerName") {
            let tempArray = [];
            if (props && props.controllers) {
                for (let obj of props.controllers) {
                    if (obj.name === value) {

                        if (obj.controllerFields) {
                            for (let innerObj of obj.controllerFields) {
                                innerObj.type == "text" || innerObj.type == "email" ? tempArray.push({ label: innerObj.field, value: innerObj.field }) : null
                            }
                        }
                    }
                }
                // setTimeout(() => {
                await setDisplayFields(tempArray);
                // }, 2000)
                // setSearchFieldValues(tempArray);
            }
        }
    }

    const handleFieldsForKey = (e) => {
        let tempArray = []
        for (let obj of props.controllers) {
            if (obj.name == e) {

                if (obj.controllerFields) {

                    for (let ob of obj.controllerFields) {
                        ob.type == "relateAutoComplete" ? tempArray.push({ label: ob.field, value: ob.field }) : null
                    }
                }
            }
        }
        setFieldForKeyList(tempArray);
    }

    const handleHasDependency = (val) => {
        let arr = [];

        if (val == false) {
            setShowHasDependency(val);
        }
        if (val == true) {
            for (let ob of props.currentControllerFields) {
                if (ob.type == "relateAutoComplete") {
                    arr.push({ value: ob.name, label: ob.name })
                }
            }
            setShowHasDependency(val);
            setHasDependencyFieldList(arr);
        }
    }

    const handleHasDependencyDuringEdit = () => {

        let arr = [];
        for (let ob of props.currentControllerFields) {
            if (ob.type == "relateAutoComplete") {
                arr.push({
                    value: ob.name, label: ob.name
                })
            }
        }
        setHasDependencyFieldList(arr);
    }

    const generateFields = (nFields, rFields, cFields, field_type) => {
        let normalFields = nFields;
        let radioFields = rFields;
        let checkBoxFields = cFields;

        let allFields = [...normalFields, ...radioFields, ...checkBoxFields];
        allFields.forEach((item) => {
            setValue(item.name, '');
        });
        let originalArray = [];
        if (field_type && field_type !== 'uniqueID') {
            allFields = allFields.filter(field => field.name !== 'uniqueIdLength');
        }
        if (field_type && field_type !== 'fileUpload') {
            allFields = allFields.filter(field => field.name !== 'isMultipleRequired' && field.name !== 'maxFileSize');
        }
        if (field_type && (field_type !== 'text' && field_type !== 'email' && field_type !== 'textarea' && field_type !== 'dropDown' && field_type !== 'date' && field_type !== 'number' && field_type !== 'uniqueField' && field_type !== 'uniqueID' && field_type !== 'derivedFields')) {
            allFields = allFields.filter(field => (field.name !== 'globalSearchField' && field.name !== 'isAddToIndex'));
        }
        if (field_type && field_type !== 'dropDown') {
            allFields = allFields.filter(field => field.name !== 'displayOptionsInActions');
        }
        if (field_type && (field_type !== 'text' && field_type !== 'email' && field_type !== 'textarea' && field_type !== 'dropDown' && field_type !== 'date' && field_type !== 'number' && field_type !== 'uniqueField' && field_type !== 'checkbox' && field_type !== 'radio')) {
            allFields = allFields.filter(field => (field.name !== 'isBulkUploadField'));
        }
        //KEEP FOR LATER
        // image field type
        // if(field_type && (field_type === 'image' || field_type === 'time' || field_type === 'number' || field_type === 'radio' || field_type === 'multiSelect'
        //     || field_type === 'textarea' || field_type === 'checkbox'  || field_type === 'WebSite' || field_type === 'uniqueField' || field_type === 'uniqueField')) { 
        //     allFields = allFields.filter(field => field.type !== 'checkbox');
        // }
        if (field_type && field_type === 'date') {
            allFields = allFields.filter(field => field.type !== 'checkbox');
            allFields = allFields.filter(field => (field.name !== 'displayinregisterForm'));
            allFields.push({
                required: false,
                type: "checkbox",
                name: 'filter',
                value: "",
                label: 'Filter Required',
                id: 'checkbox_FR',
                placeholder: 'Filter Required',
                help: ''
            });
        }
        if (field_type && (field_type !== 'text' && field_type !== 'email')) {
            allFields = allFields.filter(field => field.name !== 'displayinregisterForm');
        }

        let defaultValue = ['fileUpload', 'date', 'checkbox', 'uniqueField', 'uniqueID', 'encryptedField', 'relateAutoComplete', 'derivedFields', 'multiSelect', 'time']
        if (field_type && defaultValue.includes(field_type)) {
            allFields = allFields.filter(field => field.name !== 'value');
        }
        originalArray = allFields;
        return originalArray;
    }

    const setDefaultValues = (allFields, field_type) => {
        allFields.forEach((ele, index) => {
            if (ele && ele.name === 'width') {
                let widthVal = null;
                switch (field_type) {
                    case field_type === 'email': widthVal = "150px"; break;
                    case field_type === 'text': widthVal = "130px";; break;
                    case field_type === 'date': widthVal = "150px";; break;
                    case field_type === 'textarea': widthVal = "200px";; break;
                    case field_type === 'dropDown': widthVal = "130px";; break;
                    case field_type === 'WebSite': widthVal = "150px";; break;
                    case field_type === 'relateAutoComplete': widthVal = "150px";; break;
                    default: widthVal = "120px";
                }
                defaultValues[ele.name] = '';
                setValue(ele.name, widthVal);
            }
            if (field_type === 'password') {
                if (ele && (ele.name === 'displayinaddForm' || ele.name === 'isFieldRequired')) {
                    defaultValues[ele.name] = true;
                    setValue(ele.name, true);
                }
            }
            // if(field_type === 'text' || field_type === 'image' || field_type === 'email' || field_type === 'textarea' 
            // || field_type === 'dropDown' || field_type === 'time' || field_type === 'number' 
            // || field_type === 'radio' || field_type === 'derivedFields' || field_type === 'relateAutoComplete')
            if (field_type !== 'password') {
                if (ele && (ele.name === 'addFormOrder' || ele.name === 'editFormOrder')) {
                    defaultValues[ele.name] = '';
                    setValue(ele.name, 10);
                }
                if (ele?.name === 'maxFileSize') {
                    defaultValues[ele.name] = '';
                    setValue(ele.name, 1);
                }
                if (ele?.name === 'fileType'){
                    defaultValues[ele.name] = '';
                    setValue(ele.name, 'img/video');
                }
                if(ele?.name === 'value' && ele.type === 'radio'){
                    defaultValues[ele.name] = '';
                    setValue(ele.name, false);
                }
                if (ele && (ele.name === 'displayinaddForm' || ele.name === 'displayineditForm' || ele.name === 'displayinlist')) {
                    defaultValues[ele.name] = '';
                    setValue(ele.name, true);
                }
                if (ele && ele.name === 'isFieldRequired' || ele.name === 'isMultipleRequired') {
                    defaultValues[ele.name] = '';
                    setValue(ele.name, false);
                }
                if (ele && (ele.name === 'capitalizeTableText' || ele.name === 'sortable' || ele.name === 'filter')) {
                    defaultValues[ele.name] = '';
                    setValue(ele.name, false);
                }
                if (field_type === 'text' || field_type === 'email' || field_type === 'textarea' || field_type === 'dropDown' || field_type === 'date' || field_type === 'number' || field_type === 'uniqueField' || field_type === 'uniqueID' || field_type === 'derivedFields') {
                    if (ele && ele.name === 'globalSearchField') {
                        defaultValues[ele.name] = '';
                        setValue(ele.name, true);
                    }
                    if (ele && ele.name === 'isAddToIndex') {
                        defaultValues[ele.name] = '';
                        setValue(ele.name, false);
                    }
                    // if (props.loginApi && props.loginApi.length && props.loginApi.includes(props.controllerId)) {
                    //     if (ele && ele.name === "displayinregisterForm") {
                    //         defaultValues[ele.name] = '';
                    //         setValue(ele.name, false);
                    //     }
                    // }
                }
                if (field_type === 'text' || field_type === 'email') {
                    if (ele && ele.name === "displayinregisterForm") {
                        defaultValues[ele.name] = '';
                        setValue(ele.name, false);
                    }
                }
                if (field_type === 'text' || field_type === 'email' || field_type === 'textarea' || field_type === 'dropDown' || field_type === 'date' || field_type === 'number' || field_type === 'uniqueField' || field_type === 'checkbox' || field_type === 'radio') {
                    if (ele && ele.name === 'isBulkUploadField') {
                        defaultValues[ele.name] = '';
                        setValue(ele.name, true);
                    }
                }
            }
            if (ele && ele.name === 'fieldName') {
                defaultValues[ele.name] = '';
                setValue(ele.name, props.rowData.name);
            }

            if (field_type === 'date') {
            }

        });
    }

    //KEEP FOR LATER
    // const patchFormValues=(fields, isEdit, formObj)=> {
    //      if(fields.length > 0 && isEdit) {
    //         const form_value = JSON.parse(formObj['formValue']);
    //         for(let field of fields) {
    //             for(let propKey in form_value) {
    //                 if(field['name'] === propKey) {
    //                     if(form_value[propKey] && form_value[propKey] === "True" || form_value[propKey] === "true") {
    //                         form_value[propKey] = true;
    //                     } else if(form_value[propKey] && form_value[propKey] === "False" || form_value[propKey] === "false") {
    //                         form_value[propKey] = false;
    //                     }
    //                     setValue(propKey, form_value[propKey]);
    //                 }
    //             }
    //         }
    //      }
    // }

    const patchFormValues = (rowdata) => {
        const keys = Object.keys(rowdata);

        for (let fld of keys) {
            setValue(fld, rowdata[fld]);
            if (fld == "hasDependency" && rowdata[fld] == true) {
                setShowHasDependency(true)
                handleHasDependencyDuringEdit();
            }
            if (fld == "controllerName" && rowdata.fieldForKey) {
                handleFieldsForKey(rowdata[fld]);
            }
            if (fld == "stringType") {
                updateStringType(rowdata[fld]);
            }
            if (fld == "actions") {

                setActionType(rowdata[fld]);
            }
        }
    };



    useEffect(() => {
        UpdateRenderedFields(autoRenderFields);
        setRenderFields(true);
    }, [autoRenderFields]);

    //KEEP FOR LATER
    // useEffect(()=> {
    //     if(renderInEdit) {
    //         patchFormValues(renderedFields, renderInEdit, formData_Edit);
    //     }
    // },[renderedFields, renderInEdit,  formData_Edit])

    useEffect(() => {
        if (renderInEdit) {
            patchFormValues(rowData);
        }
    }, [rowData, renderInEdit])

    // triggered when field type is changed
    const onChangeFieldType = (type, formData = {}) => {
        let excessFields = null;
        // renderedFields = null;
        if (type !== 'password') {
            excessFields = generateFields(normalFields, radioFields, checkBoxFields, type);
        } else {
            excessFields = [...passwordFields];
        }
        if (type === 'checkbox') {
            excessFields.push(defaultCheckBox[0]);
        }else if(type === 'fileUpload'){
            excessFields.push(fileTypeField[0]);
        }
        if (excessFields.length) {
            setDefaultValues(excessFields, type);
        }

        setAutoRenFields(excessFields);

        if (typeof formData === 'object' && formData.hasOwnProperty('formValue')) {
            setFormData_Edit(formData);
        }
        // let tryPromise = new Promise((resolve, reject)=> {
        //     if(excessFields && excessFields.length) {
        //         resolve(excessFields);
        //     } else {
        //         reject();
        //     }
        // });
        // tryPromise.then(async (res) => {
        //    if(typeof formData === 'object' && formData.hasOwnProperty('formValue')) { await patchFormValues(res, formData); }
        // });

        chErrorDisplay(false);
    }

    const getDefaultCheckBoxValue = () => {
        return ({
            required: false,
            value: "",
            type: "radio",
            name: 'value',
            label: 'DefaultValue',
            options: [
                { value: true, label: "Checked" },
                { value: false, label: "UnChecked" }
            ],
            id: 'value',
            help: 'Default value of the field'
        })
    }

    const onSubmit = (values, e) => {
        values.type = baseFieldType;
        // values.actions = actions;
        // values.actionsNumber = actionsNumber;
        let showOrHideFields = [], dependent = [];
        let errorRequired = false;
        if (values && (values.type == undefined || !values.type)) {
            errorRequired = true;
            chErrorDisplay(true);
        } else {
            chErrorDisplay(false);
        }
        if (!errorRequired) {
            // if (attributeListArray && attributeListArray.length) {

            //KEEP FOR LATER
            //     if(options && options.length){
            //     console.log("submittt attributeListArray",attributeListArray)

            //         let AttributeList = [];
            //         let keyCount;
            //         if (props.type == "add") {
            //             keyCount =  keyCount + 1;
            //         } else {
            //             // keyCount = attributeListArray.length;
            //             keyCount = options.length;
            //         }

            //         if (props.type == "add") {
            //             for (let i = 0; i < keyCount; i++) {
            //                 let key = `key${i}`
            //                 let key2 = values[key];
            //                 let value = `value${i}`
            //                 let value2 = values[value];
            //                 let color = `color${i}`
            //                 let color2 = values[color];
            //                 let dependentField = `dependentField${i}`
            //                 // let dependent2 = attributeListArray[i][dependentField];
            //                 let dependent2 = options[i][dependentField];
            //                AttributeList.push({
            //                     label: key2, value: value2, color: color2, dependentField: dependent2
            //                 })
            //                 dependent2 && dependent2.length > 0 ? dependent.push({ [value2]: dependent2 }) : null
            //                 if (showOrHideFields && showOrHideFields.length > 0) {
            //                     showOrHideFields = [...dependent2, ...showOrHideFields]
            //                 }
            //                 else {
            //                     showOrHideFields = dependent2;
            //                 }

            //                 delete values[value];
            //                 delete values[key];
            //                 delete values[color];
            //                 delete values[dependentField];
            //             }
            //         } else {
            //             // let r = attributeListArray
            //             let r = options
            //             for (let j = 0; j < r.length; j++) {
            //                 let k = r[j].label
            //                 let t = r[j].value
            //                 let c = r[j].color
            //                 let d = r[j].dependentField
            //                 AttributeList.push({ label: r[j][k], value: r[j][t], color: r[j][c], dependentField: r[j][d] })
            //                 let value3 = r[j][t]
            //                 r[j][d] && r[j][d].length > 0 ? dependent.push({ [value3]: r[j][d] }) : null
            //                 if (showOrHideFields && showOrHideFields.length > 0) {
            //                     showOrHideFields = [...r[j][d], ...showOrHideFields]
            //                 }
            //                 else {
            //                     showOrHideFields = r[j][d]
            //                 }


            //                 let key = `key${j}`
            //                 let key2 = values[key];
            //                 let value = `value${j}`
            //                 let value2 = values[value];
            //                 let color = `color${j}`
            //                 let color2 = values[color];
            //                 let dependentField = `dependentField${j}`
            //                 // let dependent2 = attributeListArray[j][dependentField];
            //                 let dependent2 = options[j][dependentField];
            //                 // let textcolor = `textcolor${j}`
            //                 // let textcolor2 = values[textcolor];
            //                 // delete values[textcolor];
            //                 delete values[value];
            //                 delete values[color];
            //                 delete values[dependentField];
            //                 delete values[key];
            //             }

            //         }
            //         if (AttributeList && AttributeList.length > 0) {
            //             values.options = AttributeList;
            //         }

            // }

            // console.log("...........Values............AttributeList.......................", values, AttributeList)
            let x = '', count = 0
            if (values.type == "derivedFields" && values.stringType == 'String') {
                for (let action of values.actions) {
                    count++
                    if (count == 1) {
                        x = values.fieldName + "=" + action
                    } else {
                        x = x + "+" + action
                    }
                }
            } else {
                x = values.fieldName + "=" + values.derivedValue
            }

            values.type = baseFieldType;
            let body = {
                name: values.fieldName,
                fieldName: values.fieldName,
                type: values.type,
                placeholder: values.placeholder,
                value: values.value,
                label: values.label,
                width: values.width,
                addFormOrder: parseInt(values.addFormOrder),
                editFormOrder: parseInt(values.editFormOrder),
                stringType: stringType,
                header: values.label,
                derivedValue: x ? x : null,
                capitalizeTableText: values.capitalizeTableText,
                sortable: values.sortable,
                filter: values.filter,
                actions: values.actions,
                actionsNumber: values.actionsNumber,
                id: values.fieldName ? values.fieldName : values.id,
                displayinaddForm: values.type == "derivedFields" ? false : values.displayinaddForm,
                displayineditForm: values.type == "derivedFields" ? false : values.displayineditForm,
                displayinregisterForm: values.type == "derivedFields" ? false : values.displayinregisterForm,
                displayinlist: values.displayinlist,
                isFieldRequired: values.isFieldRequired,
                required: values.isFieldRequired && values.isFieldRequired === true ? true : false,
                profileField: values.profileField,
                displayOptionsInActions: values.displayOptionsInActions,
                globalSearchField: (baseFieldType == "text" || baseFieldType == "email" || baseFieldType == "textarea" ||
                    baseFieldType == "dropDown" || baseFieldType == "date" || baseFieldType == "number" ||
                    baseFieldType == "uniqueField" || baseFieldType == "uniqueID" || baseFieldType == "derivedFields") ? values.globalSearchField : "false",
                controllerId: values.controllerName ? values.controllerName : null,
                isClickable: values.isClickable,
                isAddToIndex: values.isAddToIndex,
                isBulkUploadField: ["text", "email", "textarea", "dropDown", "date", "number", "uniqueField", "checkbox", "radio"].includes(baseFieldType) ? values.isBulkUploadField : false,
            }
            if (baseFieldType == "dropDown" || baseFieldType == 'radio' || baseFieldType == 'multiSelect') {
                // body.options = values.attributeListArray;
                // body.attributeListArray = values.attributeListArray;
                body.options = values.options;
                body.fieldType = baseFieldType == 'radio' ? "radio" : "dropDown";
                filter ? body.filterElement = values.options : null;
            }
            if (baseFieldType == "dropDown") {
                body.fieldType = "dropDown";
                body.dependent = dependent;

            }
            if (baseFieldType == 'multiSelect') {
                body['isMultiSelect'] = true
                body['type'] = "dropDown"
            } else if (baseFieldType == 'date') {
                body['dateFormat'] = values.dateFormat
                body["fieldType"] = "Date"
            } else if (baseFieldType == 'fileUpload') {
                body['type'] = "fileUpload"
                body.fieldType = baseFieldType;
                body.fileType = values.fileType,
                body.maxFileSize = values.maxFileSize,
                body.isMultipleRequired = values.isMultipleRequired,
                body['imagePath'] = props.controllers && props.selectedControllerIndex >= 0 ?
                    props.controllers[props.selectedControllerIndex].name : ""
            }
            if (baseFieldType == "uniqueID") {
                body['uniqueIdLength'] = values.uniqueIdLength
                body['displayinaddForm'] = "false";
                body['displayineditForm'] = "false";
            }
            if (baseFieldType == "derivedFields") {
                body['method'] = values.method;
                body['derivedFieldType'] = values.derivedFieldType;
            }
            if (validationRequired) {
                body['validationRequired'] = validationRequired;
                body['regexPattern'] = regexPattern;
                body['regexData'] = regexData;
            }
            if (baseFieldType == "encryptedField") {
                body.fieldType = baseFieldType;
            }

            if (baseFieldType == "WebSite") {
                body.fieldType = "WebSite";
            }
            if (baseFieldType === "autoComplete" || baseFieldType === "relateAutoComplete") {
                body.hasDependency = values.hasDependency;
                if (values.hasDependency == true) {
                    body.fieldForKey = values.fieldForKey;
                    body.hasDependencyField = values.hasDependencyField;
                }
                body.searchField = values.searchField;
                body.fieldType = values.type;
                body.populteFields = populteFields;
                // body.displayFields = displayFields;
                body.displayFields = values.displayFields;
                body.controllerName = values.controllerName;
                for (let obj of props.controllers) {

                    if (obj.name == values.controllerName) body.controllerId = obj.controllerId;
                    // if (obj.controllerId == body.controllerId|| obj.name==body.controllerId) body.controllerName = obj.name
                }
                body.searchApi = body.controllerName ? body.controllerName.toLowerCase() + "s" : null;
                body.isMultiple = values.isMultiple
            }
            let formFieldArr = [], numberArr = [];
            let formFields = props.controllers[props.controllerIndex].controllerFields;
            for (let formField of formFields) {
                if ((formField.type == "text" || formField.type == "textarea")) {
                    let obj = { value: formField.name, label: formField.name }
                    formFieldArr.push(obj)
                } else if (formField.type == "number") {
                    let obj = { value: formField.name, label: formField.name }
                    numberArr.push(obj)
                }
            }

            props.controllerFieldsFunc(body, "fields", props.type, props.index, props.selectedControllerIndex, showOrHideFields);
            props.closeInputModal();
        }
    };

    const loopingFieldTypes = () => {
        if (renderedFields.length) {
            let inputTags;
            let numberTags;
            let radioTags;
            let checkBoxTags;
            renderedFields.forEach((field, index) => {
                if (field && field.type === 'text') {
                    inputTags = <>{inputTags}{getTextFields(field, index)}</>
                }
                if (field && field.type === 'number') {
                    numberTags = <>{numberTags}{getNumberFields(field, index)}</>
                }
                if (field && field.type === 'radio' && (field.name === 'value' || field.name === 'fileType')) {
                    radioTags = <>{radioTags}{getCheckBoxRadioFields(field, index)}</>
                } else if (field && field.type === 'radio') {
                    radioTags = <>{radioTags}{getRadioFields(field, index)}</>
                }
                if (field && field.type === 'checkbox') {
                    checkBoxTags = <>{checkBoxTags}{getCheckBoxFields(field, index)}</>
                }
            });
            let tags = <>{inputTags}{numberTags}{radioTags}{checkBoxTags}</>
            return tags;
        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error mb-1">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const upperCase = (value) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    const getTextFields = (field, keyIndex) => {
        return (
            <>
                <div className='form-group col-md-6'>
                    <label className="form-label">{field.label}
                        {getTooltipIcon(field, keyIndex)}
                    </label>
                    <Controller
                        name={field.name}
                        control={control}
                        rules={field.required && { required: `${field.label} is required` }}
                        render={({ field, fieldState }) => (
                            <>
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')}
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        field.name === 'fieldName' ? setValue('label', upperCase(e.target.value)) : null;
                                        field.name === 'fieldName' ? setValue('placeholder', upperCase(e.target.value)) : null;
                                    }} />
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                    />
                </div>
            </>
        )
    }

    const getNumberFields = (field, keyIndex) => {
        return (
            <>
                <div className='form-group col-md-6'>
                    <div className='d-flex flex-column gap-1'>
                        <label className="form-label">{field.label}
                            {getTooltipIcon(field, keyIndex)}
                        </label>
                        <Controller
                            name={field.name}
                            control={control}
                            rules={field.required && { required: 'Enter a valid order.' }}
                            render={({ field, fieldState }) => (
                                <>
                                    <InputNumber id={field.name} inputRef={field.ref} value={field.value} onBlur={field.onBlur}
                                        onChange={(e) => field.onChange(e.value)} useGrouping={false} inputClassName={classNames({ 'p-invalid': fieldState.error }, 'w-full')} />
                                    {getFormErrorMessage(field.name)}
                                </>
                            )}
                        />
                    </div>
                </div>
            </>
        )
    }

    const getRadioFields = (field, keyIndex) => {
        return (
            <>
                <div className='form-group col-md-6'>
                    <label className="form-label">{field.label}
                        {getTooltipIcon(field, keyIndex)}
                    </label>
                    <Controller
                        name={field.name}
                        control={control}
                        rules={field.required && { required: `${field.label} is required` }}
                        render={({ field, fieldState }) => (
                            <>
                                <div className="d-flex align-items-center my-2">
                                    <RadioButton inputId={`radio_${field.name}_${keyIndex}`} {...field} inputRef={field.ref} value={true} checked={field.value === true}
                                        // onClick={(e) => data(e)}
                                        onClick={field.name == "hasDependency" ? (e) => handleHasDependency(e.value) : null}
                                    />
                                    <label htmlFor={`radio_${field.name}_${keyIndex}`} className="ms-2 me-3">True</label>
                                    <RadioButton inputId={`radio_${field.name}_${keyIndex}`} {...field} value={false} checked={field.value === false}
                                        // onClick={(e) => data(e)}
                                        onClick={field.name == "hasDependency" ? (e) => handleHasDependency(e.value) : null}
                                    />
                                    <label htmlFor={`radio_${field.name}__${keyIndex}`} className="ms-2 me-3"> False </label>
                                </div>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                    />
                </div>
            </>
        )
    }

    const getCheckBoxRadioFields = (field, keyIndex) => {
        let options = field.options ? field.options : [];
        return (
            <>
                <div className='form-group col-md-6'>
                    <label className="form-label">{field.label}
                        {getTooltipIcon(field, keyIndex)}
                    </label>
                    <Controller
                        name={field.name}
                        control={control}
                        rules={field.required && { required: `${field.label} is required` }}
                        render={({ field, fieldState }) => (
                            <>
                                <div className="d-flex align-items-center my-2">
                                    {options && options.map((option) => {
                                        return <div key={option.name}>
                                            <RadioButton {...field} inputId={option.label} name={props.name} inputRef={field.ref} value={option.value}
                                                checked={field.value === option.value}
                                            />
                                            <label htmlFor={option.label} className="ms-1 me-4">
                                                {option.label}
                                            </label>
                                        </div>
                                    })
                                    }
                                </div>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                    />
                </div>
            </>
        )
    }

    const getCheckBoxFields = (field, keyIndex) => {
        let fieldItem = field;
        return (
            <>
                <div className='form-group col-md-6 mb-3 d-flex align-items-center'>
                    <Controller
                        name={fieldItem.name}
                        control={control}
                        rules={fieldItem.required && { required: `${fieldItem.label} is required` }}
                        render={({ field, fieldState }) => (
                            <>
                                <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} onChange={(e) => field.onChange(e.checked)} />
                            </>
                        )}
                    />
                    <label className="form-label ml-2" style={{ marginLeft: '9px' }}>{field.label}{getTooltipIcon(field, keyIndex)}</label>
                </div>
            </>
        );
    }


    const getTooltipIcon = (field, keyIndex) => {

        return (<>
            <FontAwesomeIcon
                className='custom-target-icon deleteIcon  ps-2'
                color='black'
                icon={faQuestion}
                style={{ width: 15, height: 14, cursor: "pointer" }}
                // data-pr-tooltip="Select Field Type"
                data-pr-tooltip={field.help}
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
            />
            <Tooltip target=".custom-target-icon" />
        </>)
    }

    const onError = (errors, e) => console.log(errors, e);
    const closetheModal = () => {
        setVisible(false);
    }

    const getHeader = () => {
        return (
            <>
                <span className='h5 bold-text text-white'>{props.type && props.type == "edit" ? "Edit Field" : 'Add Field'}</span>
                <span className='float-right'>
                    <FontAwesomeIcon
                        className='editIcon text-white modal__close-btn'
                        color='#ff00008c'
                        icon='times-circle'
                        data-toggle="tool-tip"
                        title="Close"
                        onClick={props.closeInputModal}
                    />
                </span>
            </>
        )
    }

    return (<>

        {visible &&
            <>
                <ValidationModal
                    visible={visible}
                    closetheModal={closetheModal}
                    setRegexValue={setRegexValue}
                    regexData={regexData}
                    type={regexPattern && regexPattern ? "edit" : "add"}
                />
            </>
        }
        <Dialog className='screen'
            visible={props.openInputModal}
            draggable={false}
            style={{ width: "60%" }}
            header={getHeader}
            closeOnEscape={false}
            focusOnShow={false}
            blockScroll={false}
        >
            <ModalBody className="modal__body mb-0">
                <Card className='m-0 p-0' style={{ border: "none" }}>
                    <CardBody className='m-0 p-0'>
                        <form autoComplete={'off'} onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className='row mx-1 pt-3'>
                                <div className='col-sm-12'>
                                    <ButtonToolbar style={{ justifyContent: "flex-end" }}>
                                        <Button color='primary' type="button" className='btn btn-success'
                                            style={(baseFieldType === "text" || baseFieldType === "password") ? { display: 'block' } : { display: 'none' }}
                                            onClick={() => setVisible(true)}>{regexPattern ? "Edit Validation" : "Add Validation"}
                                        </Button>
                                        < Button color='primary' type="button" className='btn btn-success'
                                            onClick={props.closeInputModal}>Cancel</Button>
                                        <Button
                                            color='primary'
                                            className='btn btn-success'
                                            // disabled={rowData && rowData.disabled ? true : false}
                                            type="submit"> {'Save'}</Button>
                                    </ButtonToolbar>
                                </div>
                            </div>
                            <section className="form_fields_section">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label className="form-label">Field Type
                                            <FontAwesomeIcon
                                                className='custom-target-icon deleteIcon  ps-2'
                                                color='black'
                                                icon={faQuestion}
                                                style={{ width: 15, height: 14, cursor: "pointer" }}
                                                data-pr-tooltip="Select Field Type"
                                                data-pr-position="right"
                                                data-pr-at="right+5 top"
                                                data-pr-my="left center-2"
                                            />
                                            <Tooltip target=".custom-target-icon" />
                                        </label>
                                        <Controller
                                            name="type"
                                            control={control}
                                            rules={{ required: 'Field Type is required.' }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown
                                                    id={field.name}
                                                    value={field.value}
                                                    optionLabel="label"
                                                    placeholder="Select Field Type"
                                                    options={fieldTypes}
                                                    focusInputRef={field.ref}
                                                    onChange={(e) => { field.onChange(e.value), updateFieldType(e.value), onChangeFieldType(e.value) }}
                                                    className={`wid_100 ${classNames({ 'p-invalid': fieldState.error })}`}
                                                />
                                            )}
                                        />
                                    </div>

                                    {(isRenderingFields && renderedFields.length) ?
                                        <>
                                            {loopingFieldTypes()}
                                        </> : null
                                    }

                                    {baseFieldType === 'uniqueID' &&
                                        <div className="form-group col-md-6 mb-3">
                                            <label className="form__form-group-label">Unique Id Length</label>
                                            <Controller
                                                name="uniqueIdLength"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <InputNumber
                                                        id={field.name} inputRef={field.ref} value={field.value}
                                                        className={`wid_100`}
                                                        placeholder={"Unique ID length"}
                                                        onBlur={field.onBlur}
                                                        onValueChange={(e) => field.onChange(e)} useGrouping={false}
                                                    />
                                                )}
                                            />
                                        </div>
                                    }
                                    {baseFieldType === 'date' &&
                                        <>
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="form__form-group-label">Date Format
                                                    <FontAwesomeIcon
                                                        className={` deleteIcon  ps-2`}
                                                        color='black'
                                                        icon={faQuestion}
                                                        style={{ width: 15, height: 14, cursor: "pointer" }}
                                                    />
                                                    {/* <Tooltip target={`.custom-target-icon2_${i}`} /> */}
                                                </label>
                                                <Controller
                                                    name="dateFormat"
                                                    control={control}
                                                    rules={{ required: 'Select a is Date Format.' }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <Dropdown
                                                                id={field.name}
                                                                value={field.value}
                                                                optionLabel="label"
                                                                placeholder="Date Format"
                                                                options={DateFormats}
                                                                focusInputRef={field.ref}
                                                                onChange={(e) => { field.onChange(e.value) }}
                                                                className={`wid_100 ${classNames({ 'p-invalid': fieldState.error })}`}
                                                            />
                                                            {getFormErrorMessage(field.name)}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </>
                                    }
                                    {(baseFieldType === 'autoComplete' || baseFieldType === 'relateAutoComplete') &&
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <div className="form-group">

                                                    </div>
                                                </div>
                                                {getAutocompleteField()}
                                            </div>
                                        </>
                                    }
                                    {
                                        baseFieldType === 'derivedFields' &&
                                        <>
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="form__form-group-label">Select Type
                                                    <FontAwesomeIcon
                                                        className='custom-target-icon2 deleteIcon  ps-2'
                                                        color='black'
                                                        icon={faQuestion}
                                                        style={{ width: 15, height: 14, cursor: "pointer" }}
                                                        id='derivedFields1'
                                                    />
                                                </label>
                                                <Controller
                                                    name="stringType"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className="d-flex align-items-center my-2">
                                                                <RadioButton inputId="f5" {...field} inputRef={field.ref} value={"String"} checked={field.value === "String"}
                                                                    // onChange={(e) => updateStringType(e.value)}
                                                                    onClick={(e) => updateStringType(e.value)}
                                                                />
                                                                <label htmlFor="f5" className="ms-2 me-3">String</label>

                                                                <RadioButton inputId="f6" {...field} value={"Number"} checked={field.value === "Number"}
                                                                    // onChange={(e) => updateStringType(e.value)}
                                                                    onClick={(e) => updateStringType(e.value)}
                                                                />
                                                                <label htmlFor="f6" className="ms-2 me-3">Number</label>
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </>
                                    }
                                    {
                                        (stringType == 'String' && actionsTypes && actionsTypes.length) &&
                                        <>
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="form__form-group-label">Select Fields
                                                    <FontAwesomeIcon
                                                        className='custom-target-icon2 deleteIcon  ps-2'
                                                        color='black'
                                                        icon={faQuestion}
                                                        style={{ width: 15, height: 14, cursor: "pointer" }}
                                                        id='selectfields'
                                                    />
                                                </label>
                                                <Controller
                                                    name="actions"
                                                    control={control}
                                                    rules={{ required: 'Select Fields is required.' }}
                                                    render={({ field }) =>
                                                        <>
                                                            <MultiSelect id={field.name}
                                                                // name="value" 
                                                                value={field.value} options={actionsTypes}
                                                                onChange={(e) => field.onChange(e.value)}
                                                                optionLabel="label" placeholder="Select fields types"
                                                            />
                                                            {getFormErrorMessage(field.name)}
                                                        </>
                                                    }
                                                />
                                            </div>
                                        </>
                                    }

                                    {
                                        (stringType == 'Number' && actionsTypeNumber && actionsTypeNumber.length) &&
                                        <>
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="form__form-group-label">Derived Field Type
                                                    <FontAwesomeIcon
                                                        className='custom-target-icon2 deleteIcon  ps-2'
                                                        color='black'
                                                        icon={faQuestion}
                                                        style={{ width: 15, height: 14, cursor: "pointer" }}
                                                        id='derivedFieldTypeId'
                                                    />
                                                </label>
                                                <Controller
                                                    name="derivedFieldType"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className="d-flex align-items-center my-2">
                                                                <RadioButton inputId="f9" {...field} inputRef={field.ref} value="default" checked={field.value == 'default'}
                                                                    onClick={(e) => { updateDF(e.value); setDerFieldType("default"); }} />
                                                                <label htmlFor="f9" className="ms-2 me-3">Default</label>

                                                                <RadioButton inputId="f10" {...field} value="custom" checked={field.value == 'custom'}
                                                                    onClick={(e) => { updateDF(e.value); setDerFieldType("custom"); }} />
                                                                <label htmlFor="f10" className="ms-2 me-3"> Custom </label>
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="form__form-group-label">Select Fields
                                                    <FontAwesomeIcon
                                                        className='custom-target-icon2 deleteIcon  ps-2'
                                                        color='black'
                                                        icon={faQuestion}
                                                        style={{ width: 15, height: 14, cursor: "pointer" }}
                                                        id='selectfields'
                                                    />
                                                </label>
                                                <Controller
                                                    name="actionsNumber"
                                                    control={control}
                                                    rules={{ required: 'Select Fields is required.' }}
                                                    render={({ field }) =>
                                                        <>
                                                            <MultiSelect id={field.name}
                                                                // name="value" 
                                                                value={field.value} options={actionsTypeNumber}
                                                                onChange={(e) => field.onChange(e.value)}
                                                                optionLabel="label" placeholder="Select fields types"
                                                            />
                                                            {getFormErrorMessage(field.name)}
                                                        </>
                                                    }
                                                />
                                            </div>
                                            {
                                                (actionsTypeNumber && actionsTypeNumber.length && derFieldType === "default") &&
                                                <>
                                                    <div className="form-group col-md-6 mb-3">
                                                        <label className="form__form-group-label">Select a Method</label>
                                                        <Controller
                                                            name='method'
                                                            control={control}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <Dropdown
                                                                        id={field.name}
                                                                        value={field.value}
                                                                        optionLabel="label"
                                                                        placeholder="Select Method"
                                                                        options={methods}
                                                                        focusInputRef={field.ref}
                                                                        onChange={(e) => field.onChange(e.value)}
                                                                        className={classNames('w-full')}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                    {/* {KEEP FOR LATER
                                        (actionsNumber && actionsNumber.length && derivedFieldType === "custom") &&
                                        <>
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="form__form-group-label">Select Fields</label>
                                                <Controller
                                                    name='derivedValue'
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <MultiSelect id={field.name} name="value" 
                                                                value={field.value} options={textAreaAutoSuggestions}
                                                                onChange={(e) => field.onChange(e.value)} 
                                                                optionLabel="name" placeholder="Select fields types"
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </>
                                    } */}

                                    {
                                        baseFieldType !== 'password' &&
                                        <div className="form-group col-md-6 mb-3">
                                            <label className="form-label">Profile Field
                                                <FontAwesomeIcon
                                                    className='custom-target-icon2 deleteIcon  ps-2'
                                                    color='black'
                                                    icon={faQuestion}
                                                    style={{ width: 15, height: 14, cursor: "pointer" }}
                                                    data-pr-tooltip="When select true this field will be shown in profile screen in deployed website."
                                                    data-pr-position="right"
                                                    data-pr-at="right+5 top"
                                                    data-pr-my="left center-2"
                                                />
                                                {/* <Tooltip target=".custom-target-icon2" /> */}
                                            </label>
                                            <Controller
                                                name="profileField"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <div className="flex flex-column align-items-center gap-2">
                                                        <ToggleButton onLabel="Show" offLabel="Hide" id={field.name} checked={field.value} onChange={field.onChange} className={classNames('w-6rem')} />
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    }
                                    {(baseFieldType === 'dropDown' || baseFieldType === 'radio' || baseFieldType === 'multiSelect') &&
                                        <>
                                            {baseFieldType === 'dropDown' &&
                                                <>
                                                    {fields.map((item, index) => {
                                                        return (
                                                            <>
                                                                <div className='d-flex align-items-center my-2'>
                                                                    <div className='flex form-group me-3'>
                                                                        <Controller
                                                                            // name={`attributeListArray[${index}].label`}
                                                                            name={`options[${index}].label`}
                                                                            control={control}
                                                                            render={({ field, fieldState }) => (
                                                                                <InputText
                                                                                    id={field.name}
                                                                                    placeholder='Option Label'
                                                                                    value={field.value}
                                                                                    className={`wid_100`}
                                                                                    onChange={(e) => field.onChange(e.target.value)}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className='flex form-group me-3'>
                                                                        <Controller
                                                                            // name={`attributeListArray[${index}].value`}
                                                                            name={`options[${index}].value`}
                                                                            rules={{ required: 'Value is required' }}
                                                                            control={control}
                                                                            render={({ field, fieldState }) => (
                                                                                <>
                                                                                    <InputText
                                                                                        id={field.name}
                                                                                        placeholder='Option Value'
                                                                                        value={field.value}
                                                                                        className={`wid_100`}
                                                                                        onChange={(e) => field.onChange(e.target.value)}
                                                                                    />
                                                                                    {fieldState.error && <span className="p-error mb-1">{fieldState.error.message}</span>}
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className='flex form-group me-3'>
                                                                        <Controller
                                                                            // name={`attributeListArray[${index}].color`}
                                                                            name={`options[${index}].color`}
                                                                            control={control}
                                                                            render={({ field, fieldState }) => (

                                                                                <Dropdown
                                                                                    id={field.name}
                                                                                    value={field.value}
                                                                                    optionLabel="value"
                                                                                    placeholder="Badge color"
                                                                                    options={badgecolors}
                                                                                    focusInputRef={field.ref}
                                                                                    className={`wid_100`}
                                                                                    onChange={(e) => field.onChange(e.target.value)}
                                                                                />

                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className='flex form-group me-3'>
                                                                        <Controller
                                                                            name={`options[${index}].dependentField`}
                                                                            control={control}
                                                                            render={({ field, fieldState }) => (
                                                                                <MultiSelect
                                                                                    id={field.name}
                                                                                    value={field.value}
                                                                                    optionLabel="label"
                                                                                    placeholder="Dependent fields"
                                                                                    options={dependentOptions}
                                                                                    onChange={(e) => field.onChange(e.value)}
                                                                                />
                                                                            )}
                                                                        />

                                                                    </div>
                                                                    <div className='icon-del'>
                                                                        <FontAwesomeIcon
                                                                            className='deleteIcon mt-2 ps-2'
                                                                            color='#ff00008c'
                                                                            icon={faTimes}
                                                                            style={{ width: 18, height: 17, }}
                                                                            onClick={() => remove(index)}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </>
                                                        )
                                                    })}
                                                </>
                                            }
                                            {baseFieldType != 'dropDown' &&
                                                <>
                                                    {fields.map((item, index) => {
                                                        return (
                                                            <>
                                                                <div className='d-flex align-items-center my-2'>
                                                                    <div className='flex form-group me-3'>
                                                                        <Controller
                                                                            //   name={`attributeListArray[${index}].label`}
                                                                            name={`options[${index}].label`}
                                                                            control={control}
                                                                            render={({ field, fieldState }) => (
                                                                                <InputText
                                                                                    id={field.name}
                                                                                    value={field.value}
                                                                                    placeholder='Option Label'
                                                                                    className={`wid_100`}
                                                                                    onChange={(e) => field.onChange(e.target.value)}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className='flex form-group me-3'>
                                                                        <Controller
                                                                            //   name={`attributeListArray[${index}].value`}
                                                                            name={`options[${index}].value`}
                                                                            rules={{ required: 'Value is required' }} control={control}
                                                                            render={({ field, fieldState }) => (
                                                                                <>
                                                                                    <InputText
                                                                                        id={field.name}
                                                                                        placeholder='Option Value'
                                                                                        value={field.value}
                                                                                        //   className={`wid_100`}
                                                                                        className={classNames({ 'p-invalid': fieldState.error }, 'w-full')}
                                                                                        onChange={(e) => field.onChange(e.target.value)}
                                                                                    />
                                                                                    {fieldState.error && <span className="p-error mb-1">{fieldState.error.message}</span>}
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className='icon-del'>
                                                                        <FontAwesomeIcon
                                                                            className='deleteIcon mt-2 ps-2'
                                                                            color='#ff00008c'
                                                                            icon={faTimes}
                                                                            style={{ width: 18, height: 17, }}
                                                                            onClick={() => remove(index)}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </>
                                                        )
                                                    })}
                                                </>
                                            }

                                            <div className='d-flex align-items-center my-2'>
                                                <div className='addOption'>
                                                    <button type='button' className='btn btn-outline btn-transparent text-primary m-0 p-0'
                                                        style={{ textDecoration: "underline", cursor: "pointer", background: 'transparent' }}
                                                        onClick={addFilterElement}>
                                                        Add Options Fields +
                                                    </button>
                                                </div>
                                                {baseFieldType === 'multiSelect' &&
                                                    <>
                                                        <div className='setting'>
                                                            <div className='settingSwitch'>
                                                                <span className='me-2'>{addToSettings ? 'Options added to settings' : "Add Options to Settings"}</span>
                                                                <Controller
                                                                    name="addToSettings"
                                                                    control={control}
                                                                    render={({ field, fieldState }) => (
                                                                        <>
                                                                            <InputSwitch inputId={field.name} checked={field.value} inputRef={field.ref} onChange={(e) => field.onChange(e.value)} />
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        </>
                                    }

                                </div>
                            </section>
                        </form>
                    </CardBody>
                </Card>
            </ModalBody>
        </Dialog>
    </>)
}

const FormArray = ({ array }) => {
    if (array && array.length) {
    }
    const [names, setNames] = useState([]);
    useEffect(() => {
        // createArray();
    }, [array]);

    const createArray = () => {
        const newNames = array.map(item => item.name);
        setNames([...names, ...newNames]);
    }
    return (<><div className=''>Jagadish</div></>)
}

export default FieldForm;
